import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Card, CardContent, Switch } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import API from 'api';
import colors from 'appcolors';
import MypageMenu from '../MypageMenu';
import Loading from 'components/Loading';
import { AppContext } from 'contexts/app.context';
import { AuthContext } from 'contexts/auth.context';


const useStyles = makeStyles((theme) => ({
    section: { marginTop: '40px' },
    wrapper: { borderTop: '1px solid #eeeeee', marginTop: '4px' },
    row: { display: 'flex', alignItems: 'center', height: '40px', borderBottom: '1px solid #eeeeee' },
    label: { flex: 1 },
}));


const AlarmSetupPage = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const [ codeInfo, setCodeInfo ] = useState();
    const [ methodInfo, setMethodInfo ] = useState();
    const [ methodsNone, setMethodsNone] = useState();

    const { showSnackbar } = useContext(AppContext);
    const { me } = useContext(AuthContext);

    useEffect(() => {
        if (me) loadData();
    }, [ me ]);

    const loadData = () => {
        API.get('/alarm/my-alarm-setup')
        .then(data => {
            const methodExceptions = data.alarmMethodExceptions;
            const codeExceptions = data.alarmCodeExceptions;

            let value;
            const methods = [];
            const methodsNone = [];
            if (me.email && me.emailAuthenticated) {
                value = !methodExceptions.find(item => item.method === 'email');
                methods.push({ key: 'email', name: '이메일', value });
            }
            else {
                methodsNone.push({ key: 'email', name: '이메일', desc: '이메일 인증이 되지 않았습니다.' });
            }
            if (me.mobile && me.mobileAuthenticated) {
                value = !methodExceptions.find(item => item.method === 'sms'); 
                methods.push({ key: 'sms', name: '문자(SMS)', value });
            }
            else {
                methodsNone.push({ key: 'sms', name: '문자(SMS)', desc: '핸드폰번호 인증이 되지 않았습니다.' });
            }
            if (me.kakaoId) {
                value = !methodExceptions.find(item => item.method === 'kakao'); 
                methods.push({ key: 'kakao', name: '카카오채널', value });
            }
            else {
                methodsNone.push({ key: 'kakao', name: '카카오채널', desc: '카카오계정이 연결되지 않았습니다.' });
            }
            setMethodInfo(methods);
            setMethodsNone(methodsNone);

            const codes = [];
            // 클레임 및 주문관련 알림은 당연히 받는게 맞으므로 컨트롤 하지 않음, day15 코드 도 당연히 받는게 맞음
            // value = !codeExceptions.find(item => item.code === 'bucket_canceled') 
            // codes.push({ key: 'bucket', name: '주문배송 관련 알림 허용', value });    // 코드 하나만 체크
            // value = !codeExceptions.find(item => item.code === 'claim_retrieved') 
            // codes.push({ key: 'claim', name: '클레임 관련 알림 허용', value });
            // value = !codeExceptions.find(item => item.code === 'code_15day_generated') 
            // codes.push({ key: 'day15', name: '프리미엄 멤버십 15% 할인 쿠폰 받을시', value });
            setCodeInfo(codes);
        });
    }

    const handleMethodChange = (method, value) => {
        if (value) {
            API.post('/alarm/alarmmethod-exception', { method })
            .then(() => { loadData(); })
        }
        else {
            API.delete(`/alarm/alarmmethod-exception/${method}`)
            .then(() => { loadData(); })   
        }
        
    }

    {/* 알림코드의 경우 현재 정책상 모두 다 허용하는 것으로 */}
    const handleCodeChange = (code, value) => {
        let codes;
        if (code === 'code_15day_generated') {
            codes = [ 'code_15day_generated' ];
        }
        codes = codes.join(',');
        if (value) {
            API.post('/alarm/alarmcode-exceptions', { codes })
            .then(() => { loadData(); })
        }
        else {
            API.delete(`/alarm/alarmcode-exceptions/${codes}`)
            .then(() => { loadData(); })   
        }
    }

	return (
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <MypageMenu densed={true} />
            
            
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '16px' }}>알림설정</Typography>

                        {codeInfo && methodInfo ? <>
                            <Box className={classes.section}>
                                <Typography variant='h6' style={{ color: colors.textSecondary }}>알림방법</Typography>
                                <div className={classes.wrapper}>
                                    {methodInfo.map(row => <Box key={row.key} className={classes.row}>
                                        <Typography className={classes.label}>{row.name}</Typography>
                                        <Switch
                                            checked={row.value}
                                            onChange={() => { handleMethodChange(row.key, row.value); }}
                                        />
                                    </Box>)}
                                    {methodsNone.map(row => <Box key={row.key} className={classes.row}>
                                        <Typography className={classes.label}>{row.name}</Typography>
                                        <Typography variant='body2' style={{ color: colors.textSecondary }}>{row.desc}</Typography>
                                    </Box>)}
                                </div>
                            </Box>

                            
                            {/* <Box className={classes.section}>
                                <Typography variant='h6' style={{ color: colors.textSecondary }}>알림허용</Typography>
                                <div className={classes.wrapper}>
                                    {codeInfo.map(row => <Box key={row.key} className={classes.row}>
                                        <Typography className={classes.label}>{row.name}</Typography>
                                        <Switch
                                            checked={row.value}
                                            onChange={() => { handleCodeChange(row.key, row.value); }}
                                        />
                                    </Box>)}
                                </div>
                            </Box> */}

                        </> : <Loading />}
                    </CardContent>
                </Card>
            </Box>
            
        </div>
	);
}

export default AlarmSetupPage;