import { Typography } from '@material-ui/core';
import React from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import colors from 'appcolors';


const NumberIndicator = ({ count, setCount }) => {
    const handleCountAdd = () => {
        setCount(val => val + 1);
    }

    const handleCountSubtract = () => {
        if (count > 1) {
            setCount(val => val - 1 );
        }
    }

    return (
        <div style={{ border: '1px solid #efefef', minWidth: '80px', display: 'flex' }}>
            <div style={{ flex: 1, backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Typography>{count}개</Typography></div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '24px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={handleCountAdd}><ArrowDropUpIcon style={{ color: colors.textPrimary }} fontSize="small" /></div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={handleCountSubtract}><ArrowDropDownIcon style={{ color: count < 2 ? colors.textHint: colors.textPrimary }} fontSize="small" /></div>
            </div>
        </div>
    );
}

export default NumberIndicator;