import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import colors from 'appcolors';
import { coupon_available_period_pipe, coupon_info_pipe, datetimePipe, coupon_state_pipe } from 'pipes';
 

const CouponItem = ({ coupon }) => {
    const couponInfo = useMemo(() => {
        return coupon_info_pipe(coupon);
    }, [ coupon ]);

    const stateColor = useMemo(() => {
        const state = coupon_state_pipe(coupon);
        if (state === 'available') return colors.primaryDark;
        else return colors.textHint;
    }, [ coupon ]); 

    const stateText = useMemo(() => {
        const state = coupon_state_pipe(coupon);
        if (state === 'available') return '사용가능';
        else if (state === 'expired') return '기한만료';
        else if (state === 'used') return '사용완료';
        else return '사용불가능';
    }, [ coupon ]);



    return (
        <div style={{ marginBottom: '8px', border: '1px solid #bdbdbd', padding: '8px 16px', backgroundColor: '#fafafa' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>{couponInfo.name}</Typography>
                <Typography variant='body1' style={{ fontWeight: 'bold', color: stateColor }}>{stateText}</Typography>
            </div>
            <div style={{ display: 'flex', marginTop: '8px' }}>
                <Typography style={{ marginRight: '16px', color: colors.textSecondary }}>할인율</Typography>
                <Typography>{coupon.discountRate + ' %'}</Typography>
            </div>
            <div style={{ display: 'flex', marginTop: '3px' }}>
                <Typography style={{ marginRight: '16px', color: colors.textSecondary }}>사용가능기간</Typography>
                <Typography>{coupon_available_period_pipe(coupon)}</Typography>
            </div>
            <div style={{ display: 'flex', marginTop: '3px' }}>
                <Typography style={{ marginRight: '16px', color: colors.textSecondary }}>사용일시</Typography>
                <Typography>{datetimePipe(coupon.usedAt)}</Typography>
            </div>
        </div>
    );
}


export default CouponItem;