import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { BagContext } from './bag.context';


const ItemPageContext = createContext({});

const ItemPageContextProvider = ({ children }) => {
	const { stuff, appendStuff } = useContext(BagContext);

	const [ item, setItem ] = useState();
	
	const [ proxy, setProxy ] = useState();

	const [ count, setCount ] = useState(1);

	// 아이템의 프록시가 하나 이상 존재함
    const hasAtLeastOneProxy = useMemo(() => { 
		if (item) return item.proxies.length > 0;
	}, [ item ]);

	// 1+1 이벤트 행사중인 프록시들
	const [ plusEvenProxies, setPlusEventProxies ] = useState();
    useEffect(() => {
		if (!item) return setPlusEventProxies([]);
        const proxies = item.proxies.filter(proxy => proxy.plusEventPrice !== null);
        setPlusEventProxies(proxies);
    }, [ item ]);

	// 현재 보는 아이템이 쇼핑백에 있는가?
	const isItemInBag = useMemo(() => {
		const result = false;
		if (item && stuff) {
			return !!stuff.find(el => el.itemId === item.id);
		}
		return result;
	}, [ item, stuff ]);

	const handleBagClick = async () => {
		await appendStuff({
			itemId: item.id,
			proxyId: proxy?.id,
			price: proxy?.price || item.price,
			count,
			eventPrice: proxy?.plusEventPrice,
		});
	}

	return (
		<ItemPageContext.Provider
			value={{
				item,
				setItem,
                
				proxy,
				setProxy,

				count,
				setCount,

				hasAtLeastOneProxy,

				isItemInBag,
				
				plusEvenProxies,

				handleBagClick,
			}}
		>
			{children}
            
		</ItemPageContext.Provider>
	);
};

export {
	ItemPageContext,
	ItemPageContextProvider
};