import React, { useContext, useMemo } from 'react';
import { Chip, Typography } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import colors from 'appcolors';
import { BagContext } from 'contexts/bag.context';
import { number_format_pipe } from 'pipes';
import { AppContext } from 'contexts/app.context';


const EachItem = ({ item }) => {
    const { showDialog } = useContext(AppContext);
    const { modifyStuffCount, dismissEvent } = useContext(BagContext);

    const handleCountAdd = () => {
        let count = item.stuff.count + 1;
        modifyStuffCount(item, count);
    }   
    
    const handleCountSubtract = () => {
        if (item.stuff.count > 0) {
            let count = item.stuff.count - 1;
            modifyStuffCount(item, count);
        }
    }

    const onClearClick = () => {
        modifyStuffCount(item, 0);
    }

    const onDissmissClick = () => {
        const dialogData = {
            title: '이벤트 해제',
            message: '이벤트 할인 적용을 해제하시겠습니까?',
            first: {
                onClick: () => {
                    dismissEvent({
                        proxyId: item.proxy?.id,
                        itemStuff: item.stuff,
                    });
                }
            },
            second: true,
        }
        showDialog(dialogData);
    }

    const isEvent = useMemo(() => {
        return !!item.stuff.eventPrice;
    }, [ item ]);

	return (
		<div style={{ display: 'flex', marginBottom: '4px', border: '1px solid #efefef' }}>
			{item.fileInfo && <img style={{ width: '100px', height: '100px', objectFit: 'cover' }} src={item.fileInfo?.thumbURL || item.fileInfo?.downloadURL} alt="상품" />}
			<div style={{ marginLeft: '16px', flex: 1, paddingTop: '8px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body2" style={{ color: colors.textNeutral, fontWeight: 'bold' }}>{item.name}: {number_format_pipe(item.stuff?.eventPrice || item.stuff?.price)}원</Typography>
				
				{item.proxy && <Typography variant="body2">{item.proxy.proxyOptions.map(option => option.option.name).join(', ')}</Typography>}

                {isEvent && <Chip label={'이벤트'} style={{ backgroundColor: 'orange', color: 'white' }} size='small' onDelete={onDissmissClick} />}
			</div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <ClearIcon onClick={onClearClick} />
                <div style={{ display: 'flex', border: '1px solid #efefef', minWidth: '80px' }}>
                    <div style={{ flex: 1, backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Typography>{item.stuff.count}개</Typography></div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '24px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={handleCountAdd}><ArrowDropUpIcon fontSize="small" /></div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={handleCountSubtract}><ArrowDropDownIcon fontSize="small" /></div>
                    </div>
                </div>
            </div>
		</div>
	);
}

export default EachItem;