import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Box, Typography, Button, TextField } from '@material-ui/core';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import Guide from 'components/Guide';
import colors from 'appcolors';
import * as yup from 'yup';
import useInterval from 'hooks/useInterval';


// 공통 컴포넌트
const MobileAuth = ({ onAuthenticated }) => {
    const { showAlert } = useContext(AppContext);

    const [ state, setState ] = useState('ready');      // ready, sended, authenticated

    const [ tel, setTel ] = useState('');
    const [ code, setCode ] = useState('');
    const [ id, setId ] = useState();
    const [ remainTime, setRemainTime ] = useState(0);

    const handleSendCodeToMobile = async () => {
        await yup.string().min(11, '핸드폰번호가 형식에 맞지 않습니다.').max(15, '핸드폰번호가 형식에 맞지 않습니다.').validate(tel).catch(error => showAlert(error.message));

        const data = { mobile: tel };
        await API.post('/auth/send-mobile-auth-code', data)
        .then((data) => {
            setState('sended');
            setId(data.id);
        });
    }

    useEffect(() => {
        if (state === 'sended') {
            setRemainTime(200);
        }
        else if (state === 'authenticated') {
            setRemainTime(-1);
            onAuthenticated({ id, mobile: tel });
        }
    }, [ state ]);

    const handleValidateMobileAuth = () => {
        if (!code) return showAlert('인증번호를 입력해주세요.');
        const data = { id, code };
        API.post('/common/verify-authentication-code', data)
        .then(() => setState('authenticated'));
    }

    useInterval(() => {
        if (remainTime > -1) {
            if (remainTime === 0) {
                return setState('ready');
            }
            setRemainTime(time => time - 1);
        }
    }, 1000);

    const remainTimeText = useMemo(() => {
        if (remainTime <= 0) return '';
        let minutes = Math.floor(remainTime / 60) + '';
        let seconds = remainTime % 60 + '';
        if (minutes.length === 1) minutes = '0' + minutes;
        if (seconds.length === 1) seconds = '0' + seconds;
        return `${minutes}:${seconds}`;
    }, [ remainTime ]);

	return (
        <Box>
            <Guide>{
                state === 'ready' ? '핸드폰번호를 입력 후에 인증번호발송 버튼을 클릭해주세요.' :
                state === 'sended' ? '문자메시지를 보냈습니다. 받은 인증번호를 입력후 인증하기 버튼을 클릭해주세요.' : '인증이 완료되었습니다.'
            }</Guide>

            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                <TextField
                    style={{ marginRight: '8px' }}
                    label={'핸드폰번호'}
                    size="small"
                    variant="outlined"
                    type="tel"
                    name="tel"
                    autoComplete='tel'
                    disabled={state !== 'ready'}
                    autoFocus
                    onChange={(event) => { setTel(event.target.value); }}
                    value={tel}
                />
                <Button variant='outlined' color="secondary" onClick={handleSendCodeToMobile}>인증번호발송</Button>
            </Box>
            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                <TextField
                    style={{ marginRight: '8px' }}
                    label={'인증번호'}
                    size="small"
                    variant="outlined"
                    disabled={state !== 'sended'}
                    autoComplete='off'
                    onChange={(event) => { setCode(event.target.value); }}
                    value={code}
                />
                <Button variant='outlined' color="primary" onClick={handleValidateMobileAuth}>인증하기</Button>
            </Box>
            <Typography variant='body2' style={{ marginLeft: '3px', color: colors.error }}>{remainTimeText}</Typography>
        </Box>
	);
}

export default MobileAuth;