import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Card, CardContent } from '@material-ui/core';
import colors from 'appcolors';
import MypageMenu from '../MypageMenu';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import useGetMyCoupons from './useGetMyCoupons';
import CouponItem from './CouponItem';
 


const MyCouponsPage = () => {
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { rows, loading } = useGetMyCoupons();

	return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <MypageMenu densed={true} />
            
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingBottom={'8px'} ml={isHandset ? 0 : 2}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '16px' }}>쿠폰함</Typography>

                        {loading || !rows ? <Loading /> : <>
                            {rows.length === 0 ? <Nodata>쿠폰이 없습니다.</Nodata> : <>
                                {rows.map(coupon => <CouponItem key={coupon.id} coupon={coupon} />)}
                            </>}
                        </>}

                    </CardContent>
                </Card>
            </Box>
        </div>
	);
}

export default MyCouponsPage;