import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from 'contexts/app.context';
import { AuthContext } from 'contexts/auth.context';
import axios from 'axios';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Link, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from 'api';
import colors from 'appcolors';
import CircularButton from 'components/CircularButton';
import MobileAuth from 'components/MobileAuth';



const Cafe24SignupPage = () => {	
    const { showSnackbar } = useContext(AppContext);
    const { fetchMyinfo, gotoLoginRedirectURL } = useContext(AuthContext);

	const history = useHistory();
    
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const [ state, setState ] = useState('auth');   // auth, join

    const { search } = useLocation();
    const [ memberId, setMemberId ] = useState('');
	useEffect(() => {
		const query = new URLSearchParams(search);
        setMemberId(query.get('memberId'));
	}, [ search ]);

    const [ authenticationId, setAuthenticationId ] = useState();
    const [ authError, setAuthError ] = useState('');
    const onAuthenticated = ({ id, mobile }) => {
        setAuthenticationId(id);

        const data = { memberId, mobile };
        API.post('/auth/cafe24-authenticate', data)
        .then(() => {
            showSnackbar('인증이 완료되었습니다.');
            setState('join');
        })
        .catch(() => { 
            setAuthError(`핸드폰인증은 되었지만, 이 핸드폰번호는 아이디(${memberId}) 와 관계없는 핸드폰번호입니다.`);
        });
    }

	// 이용약관, 개인정보처리방침
	const [ appusageModalOpen, setAppusageModalOpen ] = useState(false);
	const [ privacyModalOpen, setPrivacyModalOpen ] = useState(false); 
	const [ appusage, setAppusage ] = useState();
	const [ privacy, setPrivacy ] = useState();
	const handleAppUsageClick = () => {
		axios({
			method: 'get',
			url: `${process.env.REACT_APP_HOST}/resource/policy/app_policy`,
		})
		.then((data) => {
			setAppusage({__html: data.data});
			setAppusageModalOpen(true);
		})
	}
	const handlePrivacyClick = () => {
		axios({
			method: 'get',
			url: `${process.env.REACT_APP_HOST}/resource/policy/privacy_policy`,	
		})
		.then((data) => {
			setPrivacy({__html: data.data});
			setPrivacyModalOpen(true);
		})
	}
	// end: 이용약관, 개인정보처리방침

	return(
		<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            {state === 'auth' ? <Box style={{ width: '100%', padding: '40px 16px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='h6' style={{ marginBottom: '24px' }}>{`${memberId}님, 가입하셨던 핸드폰번호를 입력해주세요.`}</Typography>
                <MobileAuth onAuthenticated={onAuthenticated} />
                {authError && <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant='body1' style={{ marginTop: '24px', color: colors.error }}>{authError}</Typography>
                    <Button variant='contained' color={'primary'} onClick={() => { window.location.reload(); }} style={{ marginTop: '8px', color: 'white' }}>다시 시도하기</Button>
                </Box>}
            </Box> : <Formik
                enableReinitialize={true}
				initialValues={{
					memberId: memberId,
                    nickname: '',
					password: '',
                    passwordConfirm: '',
					appUsage: false,
					privacy: false,
				}}
				validationSchema={Yup.object({
					memberId: Yup.string().required('필수입력입니다.'),
                    nickname: Yup.string().required('필수입력입니다.'),
					password: Yup.string().required('비밀번호를 입력해주세요.').max(50, '비밀번호가 너무 깁니다.').matches(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/, '8자이상, 숫자/영문자 최소 1자'),
                    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], '비밀번호가 일치하지 않습니다.'),
					appUsage: Yup.boolean().oneOf([true], '필수로 동의해야합니다.'),
					privacy: Yup.boolean().oneOf([true], '필수로 동의해야합니다.'),
				})}
				onSubmit={(values, { setSubmitting }) => {
                    console.log('path1');
                    values.id = authenticationId;
					API.post('/auth/cafe24-signup', values)
					.then(() => {
						fetchMyinfo();
						gotoLoginRedirectURL();
					})
                    .finally(() => { setSubmitting(false); })
				}}
			>
				{({ values, errors, dirty, touched, isSubmitting, isValid, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
					<form onSubmit={handleSubmit} style={{ width: '100%', padding: '40px 16px', display: 'flex', justifyContent: 'center' }}>
						<Box width={{ xs: '100%', sm: '500px', md: '600px' }} display="flex" flexDirection="column" >
							<Typography variant="h2" style={{ color: colors.textNeutral, fontWeight: 'bold' }}>필수정보입력</Typography>
                            <Typography variant='h6' style={{ marginTop: '8px', marginBottom: '16px' }}>{`${memberId}님, 필수 입력정보를 입력해주세요.`}</Typography>
							
							<Typography variant="h6" style={{ marginTop: '24px', marginBottom: '8px', color: colors.textHint }}>아이디</Typography>
							<TextField
								label={'아이디'}
								variant="outlined"
                                size="small"
								type="text"
								name="memberId"
								required={true}
								value={values.memberId}
                                disabled
							/>
							
							<Typography variant="h6" style={{ marginTop: '24px', marginBottom: '8px', color: colors.textHint }}>닉네임</Typography>
							<TextField
								label={'닉네임'}
								variant="outlined"
								type="text"
								name="nickname"
								autoComplete='nickname'
                                size="small"
								required={true}
								onChange={handleChange('nickname')}
								onBlur={handleBlur('nickname')}
								helperText={(touched.nickname && errors.nickname) || '닉네임을 입력하세요.'}
								error={!!(touched.nickname && errors.nickname)}
								value={values.nickname}
							/>

                            <Typography variant="h6" style={{ marginTop: '24px', marginBottom: '8px', color: colors.textHint }}>비밀번호</Typography>
							<TextField
								label={'비밀번호'}
								variant="outlined"
								type="password"
								name="password"
								autoComplete='new-password'
                                size="small"
								required={true}
								onChange={handleChange('password')}
								onBlur={handleBlur('password')}
								helperText={(touched.password && errors.password) || '비밀번호를 입력하세요.'}
								error={!!(touched.password && errors.password)}
								value={values.password}
							/>

                            <Typography variant="h6" style={{ marginTop: '24px', marginBottom: '8px', color: colors.textHint }}>비밀번호 재입력</Typography>
							<TextField
								label={'비밀번호 재입력'}
								variant="outlined"
								type="password"
                                size="small"
								required={true}
								onChange={handleChange('passwordConfirm')}
								onBlur={handleBlur('passwordConfirm')}
								helperText={(touched.passwordConfirm && errors.passwordConfirm) || '비밀번호를 한번더 입력하세요.'}
								error={!!(touched.passwordConfirm && errors.passwordConfirm)}
								value={values.passwordConfirm}
							/>
							
							<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={3}>
								<FormControlLabel
									control={<Checkbox checked={values.appUsage} onChange={(event) => { setFieldValue('appUsage', event.target.checked); setFieldTouched('appUsage', true); }}  />}
									label="[필수] mnx15 이용약관 동의"
								/>
								<Typography onClick={handleAppUsageClick} variant='body1' style={{ cursor: 'pointer', color: colors.textSecondary, textDecorationLine: 'underline' }}>자세히보기</Typography>
							</Box>
							<Typography variant='body2' style={{ color: colors.error }}>{touched.appUsage && errors.appUsage}</Typography>
							
							<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
								<FormControlLabel
									control={<Checkbox checked={values.privacy} onChange={(event) => { setFieldValue('privacy', event.target.checked); setFieldTouched('privacy', true); }} />}
									label="[필수] mnx15 개인정보 수집 및 이용 동의"
								/>
								<Typography onClick={handlePrivacyClick} style={{ cursor: 'pointer', color: colors.textSecondary, textDecorationLine: 'underline' }}>자세히보기</Typography>
							</Box>
							<Typography variant='body2' style={{ color: colors.error }}>{touched.privacy && errors.privacy}</Typography>
			
							<Box display={'flex'} flexDirection={{xs: 'column', sm: 'row'}} alignItems={{ xs: 'center', sm: 'flex-end' }} justifyContent={'space-between'} marginTop={'16px'} >
								<CircularButton size="large" variant="contained" type="submit" color="primary" loading={isSubmitting} disabled={isSubmitting} style={{ alignSelf: isHandset ? 'stretch' : 'default' }} buttonStyle={{ color: 'white' }}>확인</CircularButton>
							</Box>
						</Box>

						<Dialog
							open={appusageModalOpen}
							onClose={() => { setAppusageModalOpen(false); }}
							scroll={'paper'}
						>
							<DialogContent>
								<DialogContentText tabIndex={-1}>
									<div dangerouslySetInnerHTML={appusage} />
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => { setAppusageModalOpen(false); }}>닫기</Button>
								<Button color="primary" onClick={() => { setFieldValue('appUsage', true); setAppusageModalOpen(false); }}>동의하기</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={privacyModalOpen}
							onClose={() => { setPrivacyModalOpen(false); }}
							scroll={'paper'}
						>
							<DialogContent>
								<DialogContentText tabIndex={-1}>
									<div dangerouslySetInnerHTML={privacy} />
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => { setPrivacyModalOpen(false); }}>닫기</Button>
								<Button color="primary" onClick={() => { setFieldValue('privacy', true); setPrivacyModalOpen(false); }}>동의하기</Button>
							</DialogActions>
						</Dialog>
					</form>
				)}
			</Formik>}
		</div>
	)
}


export default Cafe24SignupPage;
