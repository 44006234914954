import { Box, makeStyles, Typography, Link } from '@material-ui/core';
import React, { useCallback, useMemo, useRef, useState, Fragment, useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import colors from 'appcolors';

const useStyles = makeStyles((theme) => ({
    eachSlide: {
        height: '100%',
        '& div': {
            backgroundSize: 'cover',
        }
    }
}));

// 슬라이드쇼이기 때문에 높이를 지정해야 한다.
const styles = {
    slideBoxHeight: { xs: '200px', sm: '350px', md: '400px', lg: '500px', xl: '600px' },
}


const ProductPreview = ({ productCode, items, item, setItem }) => {
    const classes = useStyles();

    const slideRef = useRef();
    const [ autoplay, setAutoplay ] = useState(true);

    const handleThumbClick = useCallback((item, index) => () => {
        slideRef.current.goTo(parseInt(index));
        setAutoplay(false);
        setItem(item);
    }, [ setItem ]);
    
    // 보여주기식이니, 이미지가 있는것만, 최대 5개 => 추후에는 그냥 서버에서 5개 정해서 처리하는 것도 괜찮을 듯
    const elements = useMemo(() => {
        if (items) return items.filter(item => item.fileInfos.length > 0).slice(0,5);
    }, [ items ]);

    // thumb 이미지의 높이 구하기
    const rootRef = useRef();
    const [ thumbHeight, setThumbHeight ] = useState(80);
    useEffect(() => {
        const rootWidth = rootRef.current?.offsetWidth;
        if (elements && elements.length > 0) {
            const elementWidth = (rootWidth - 8 * (elements.length - 1)) / elements.length;
            setThumbHeight(elementWidth);
        }
    }, [ elements ]);

    return (
        <>
            {elements && <div ref={rootRef}>
                <Slide 
                    ref={slideRef}
                    autoplay={autoplay}
                    prevArrow={<div></div>}
                    nextArrow={<div></div>}
                >
                    {elements.map((item, index) => <div key={item.id} className={classes.eachSlide}>
                        <Box height={styles.slideBoxHeight} style={{'backgroundImage': `url(${item.fileInfos[0].downloadURL})`}} /> 
                    </div>)}
                </Slide>
                
                <div style={{ display: 'flex' }}>
                    {elements.map((element, index) => <Fragment key={element.id}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px', flex: 1, marginLeft: index > 0 ? '8px' : 0, border: element.id === item?.id ? '3px solid #4caf50' : '2px solid #cccccc' }} onClick={handleThumbClick(element, index)}>
                            <div style={{ height: thumbHeight }}>
                                <img src={element.fileInfos[0].downloadURL} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="alt" />
                            </div>
                            
                            <Typography component={Link} href={`/item?productCode=${productCode}&itemCode=${element.code}`} style={{ margin: '2px 0', alignSelf: 'center', color: colors.secondary }} variant="body2">({element.name})</Typography>
                            {/* <Link href={`/item?productCode=${productCode}&itemCode=${element.code}`}>({element.name})</Link> */}
                        </div>
                        
                    </Fragment>)}
                </div>

                {/* <Link variant="contained" color="secondary" style={{ marginTop: '8px', width: '100%', color: 'white' }} onClick={() => { history.push('/item'); }}>상품 자세히 보기</Link> */}
                {/* <Link href="/item" >상품 자세히 보기</Link> */}
            </div>}
        </>
    )
}



export default ProductPreview;