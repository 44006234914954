import React from 'react';

import colors from 'appcolors';

const Asterisk = () => {
    return (
        <span style={{ color: colors.primary }}>*</span>
    );
}

export default Asterisk;