import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import Routes from 'Routes';
import GlobalStyles from 'components/GlobalStyles';
import { AppContextProvider } from 'contexts/app.context';
import { AuthContextProvider } from 'contexts/auth.context';
import { MemoryContextProvider } from 'contexts/memory.context';
import { BagContextProvider } from 'contexts/bag.context';
import useKakaoInit from 'hooks/useKakaoInit';


function App() {
    useKakaoInit();

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <BrowserRouter basename={'/app'}>
                <AppContextProvider>
                    <AuthContextProvider>
                        <MemoryContextProvider>
                            <BagContextProvider>
                                <Routes />
                            </BagContextProvider>
                        </MemoryContextProvider>
                    </AuthContextProvider>
                </AppContextProvider>
            </BrowserRouter>	
        </ThemeProvider>
	);
}

export default App;
