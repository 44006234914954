import React, { useContext, useEffect, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { Box, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from 'api';
import colors from 'appcolors';
import { AppContext } from 'contexts/app.context';
import TextField from 'components/TextField';
import Button from 'components/Button';
import { useLocation } from 'react-router-dom';
const qs = require('qs');


// 진혜가 로그인페이지를 영어로 해달라고 해서 영어로 변경함
const SigninPage = (props) => {
	const { showSnackbar, showAlert } = useContext(AppContext);
    const { getLoginRedirectURL, gotoLoginRedirectURL, fetchMyinfo } = useContext(AuthContext);

	const passwordRef = useRef();

	const handleEmailInputKeydown = (event) => {
		if(event.key === 'Tab') {
			event.preventDefault();
			passwordRef.current.focus();
		}
	}

	const history = useHistory();
    
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

	// kakao rest api login
	const handleKakaoLogin = () => {
		// 카카오에서 리다이렉트 되어 여기로 오는데 loginRedirectURL 이 초기화 되어서 redirectURL 을 지정할 수 있는 방법이 없어서 localStorage 에 일시 저장
		localStorage.setItem('loginRedirectURL', getLoginRedirectURL());

		const client_id = process.env.REACT_APP_KAKAO_RESTAPI_KEY;
		const redirect_uri = process.env.NODE_ENV === 'development' ? `http://localhost:4201/auth/signin` : `${process.env.REACT_APP_URL}/auth/signin`;
		const response_type = 'code';
		const url = `https://kauth.kakao.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}`;
		window.open(url, "_self");
	}

	const { search } = useLocation();
    const code = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('code');
    }, [search]);
	useEffect(() => {
		if (!code) return;

		const data = { 
			grant_type: 'authorization_code',
			client_id: process.env.REACT_APP_KAKAO_RESTAPI_KEY,
			redirect_uri: process.env.NODE_ENV === 'development' ? 'http://localhost:4201/auth/signin' : `${process.env.REACT_APP_URL}/auth/signin`,
			code,
		};

		axios({
			method: 'post',
			url: `https://kauth.kakao.com/oauth/token`,
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data: qs.stringify(data),
		})
		.then((data) => {
			let { access_token } = data.data;

			// 카카오에서 이후 사용자 정보 호출은 브라우저에서 호출 못하도록 cors 정책으로 막아놓음, 백엔드에서 해결해야 함
			const body = { access_token };
			return API.post('/auth/kakao-login', body);
		})
		.then(() => {
			showSnackbar('You logined.');
			return fetchMyinfo();
		})
		.then(() => {
			let url = localStorage.getItem('loginRedirectURL');
			history.push(url || '/');
		})
	}, [ code ]);

	// end: kakao login

	return(
		<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: isHandset ? 'stretch' : 'center', justifyContent: 'center', paddingBottom: '40px', }}>
			<Formik
				initialValues={{
					email: '',
					password: '',
				}}
				validationSchema={Yup.object({
					email: Yup.string().email('Invalid email format.').required('Required.'),
					password: Yup.string().required('Required.'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					const params = values;
					API.get('/auth/signin', { params })
					.then(() => {
						fetchMyinfo();
						gotoLoginRedirectURL();
					})
					.finally(() => { setSubmitting(false); });
				}}
			>
				{({ values, errors, dirty, touched, isSubmitting, isValid, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit} style={{ padding: '40px 16px', display: 'flex', justifyContent: 'center' }}>
						<Box width={{ xs: '88%', sm: '400px' }} display="flex" flexDirection="column" >
							<Typography variant="h6" style={{ color: colors.gray600, marginBottom: '5px' }}>LOGIN</Typography>
							
							<Link component={RouterLink} to={'/auth/find-email'} variant="body2" style={{ marginTop: '24px', marginBottom: '4px', alignSelf: 'flex-end' }}>Forgot email?</Link>
							<TextField
								label={'Email'}
								variant="outlined"
								type="email"
								size='small'
								autoFocus
								required={true}
								onKeyDown={handleEmailInputKeydown}
								onChange={handleChange('email')}
								onBlur={handleBlur('email')}
								helperText={(touched.email && errors.email) || 'Input email.'}
								error={!!(touched.email && errors.email)}
								value={values.email}
								inputProps={{ enterKeyHint: 'next' }}
							/>
							
							<Link component={RouterLink} to={'/auth/find-password'} variant="body2" style={{ marginTop: '24px', marginBottom: '4px', alignSelf: 'flex-end' }}>Forgot password?</Link>
							<TextField
								inputRef={passwordRef}
								label={'Password'}
								variant="outlined"
								type="password"
								size='small'
								required={true}
								onChange={handleChange('password')}
								onBlur={handleBlur('password')}
								helperText={(touched.password && errors.password) || 'Input password.'}
								error={!!(touched.password && errors.password)}
								value={values.password}
								inputProps={{ enterKeyHint: 'go' }}
							/>
			
							<Box display={'flex'} flexDirection={{xs: 'column-reverse', sm: 'row'}} alignItems={{ xs: 'center', sm: 'flex-end' }} marginTop={'24px'} >
								<Typography variant="h6" style={{ color: colors.gray600, marginTop: isHandset ? '24px' : 0 }}>
									<Link component={RouterLink} to={'/auth/signup'} style={{ fontWeight: 'bold' }}>JOIN</Link>
								</Typography>

								<Button variant="contained" type="button" 
									style={{ 
										color: '#3b3c24',
										backgroundColor: '#ffe812', alignSelf: isHandset ? 'stretch' : 'default',
										marginLeft: isHandset ? 0 : 'auto',
										marginRight: isHandset ? 0 : '8px',
										marginTop: isHandset ? '8px' : 0
									}} 
									onClick={handleKakaoLogin}
								>Kakao</Button>
								<Button variant="contained" type="submit" color="primary" disabled={!dirty || !isValid || isSubmitting} style={{ color: 'white', alignSelf: isHandset ? 'stretch' : 'default' }}>Login</Button>
							</Box>

							{/* <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
								<Link variant='body1' component={RouterLink} to={'/auth/cafe24-signin'} style={{ marginTop: '24px', marginLeft: '8px', textDecorationLine: 'underline' }}>다른 MNX15 사이트에 가입하셨었나요?</Link>
							</Box> */}
						</Box>
					</form>
				)}
			</Formik>
		</div>
	)
}


export default SigninPage;
