import React, { useContext } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { bucket_image_pipe, bucket_name_pipe, bucket_state_pipe, number_format_pipe } from 'pipes';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import Button from 'components/Button';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import colors from 'appcolors';


const claimable_state = [ 'sended', 'received' ];
const reviewable_state = [ 'sended', 'received', 'done' ];
const BucketItem = ({ bucket }) => {
    const { showAlert, showDialog } = useContext(AppContext);

    const history = useHistory();
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const handleCancelBucket = () => {
        const dialogData = {
            title: '주문취소',
            message: '취소 및 환불하시겠습니까?',
            first: {
                onClick: () => {
                    API.delete(`/bucket/cancel-bucket/${bucket.id}`)
                    .then(() => {
                        showAlert('취소 및 환불되었습니다.');
                        window.location.reload();
                    });
                }
            },
            second: true,
        }
        showDialog(dialogData);
    }

    const handleReturn = () => {
        history.push(`/mypage/claim/form/${bucket.id}?division=return`);
    }

    const handleReplace = () => {
        history.push(`/mypage/claim/form/${bucket.id}?division=replace`);
    }

    const handleReview = () => {
        history.push(`/mypage/review/form/${bucket.id}`);
    }

    return (
        <Box border={'1px solid #dddddd'} display="flex" marginBottom={'8px'} >
            <img src={bucket_image_pipe(bucket)} alt={bucket_name_pipe(bucket)} style={{ height: isHandset ? '100px' : '180px' }} />
            <Box display={'flex'} flexDirection='column' padding={'8px'} flex={1}>
                <Typography variant='body2' style={{ color: colors.textSecondary }}>{ moment(bucket.createdAt).format('YYYY-MM-DD') }</Typography>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>{bucket_state_pipe(bucket.state)}</Typography>
                
                
                <Typography variant='body2' style={{ marginTop: '8px' }}>{bucket_name_pipe(bucket)}</Typography>
                <Typography variant='body2'>{number_format_pipe(bucket.stuff.price)} 원 &bull; {bucket.stuff.count}개</Typography>
                <Typography component={Link} to={`/mypage/bucket/${bucket.id}`} variant="body1" style={{ alignSelf: 'flex-start' }}>자세히보기</Typography>
                <Box display={'flex'} marginTop={isHandset ? '8px' : 'auto'}>
                    {bucket.state === 'waiting' && <Button variant='outlined' size='small' style={{ marginRight: '8px' }} onClick={handleCancelBucket}>주문&bull;배송 취소</Button>}
                    {claimable_state.indexOf(bucket.state) > -1  && <Button variant='outlined' size='small' style={{ marginRight: '3px' }} onClick={handleReturn}>반품 요청</Button>}
                    {claimable_state.indexOf(bucket.state) > -1  && <Button variant='outlined' size='small' style={{ marginRight: '3px' }} onClick={handleReplace}>교환 요청</Button>}
                    {reviewable_state.indexOf(bucket.state) > -1 && <Button variant='outlined' size='small' style={{ marginRight: '3px' }} onClick={handleReview}>리뷰 작성</Button>}
                </Box>
            </Box>
        </Box>
    );
}


export default BucketItem;