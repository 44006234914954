import React from 'react';
import { Typography, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import colors from 'appcolors';



const delivery_info = [
    { title: '배송 방법', content: 'CJ대한통운' },
    { title: '배송 지역', content: '전국지역' },
    { title: '배송 비용', content: '10만원 이상 구매시 무료, 골프화는 전상품 무료배송' },
    { title: '배송 기간', content: '1일 ~ 2일' },
    { title: '배송 안내', content: '제주: 5000원, 도서산간지역: 7000원 추가배송비' },
]


const useStyles = makeStyles((theme) => ({
    container: { padding: '24px 0' },
    title: { color: colors.textSecondary, marginBottom: '16px' },
}));



const DeliveryInfo = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

	return (
		<div className={classes.container}>
			<Typography variant="body1" className={classes.title}>배송정보</Typography>
            {delivery_info.map((item, index) => <div key={index} style={{ display: 'flex' }}>
                <Typography variant="body1" style={{ marginRight: '8px', fontWeight: 'bold' }}>{item.title}:</Typography>
                <Typography variant="body1">{item.content}</Typography>
            </div>)}
            <Typography variant="body1" style={{ marginTop: '8px' }}>※ 고객님께서 주문하신 상품은 입금 확인후 배송해 드립니다. 다만, 상품종류에 따라서 상품의 배송이 다소 지연될 수 있습니다.</Typography>
		</div>
	);
}

export default DeliveryInfo;