import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import colors from 'appcolors';
import { useMediaQuery, useTheme } from '@material-ui/core';

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    // snackbar 
	const [ snackbarOpen, setSnackbarOpend ] = useState(false);
    const [ snackbarMessage, setSnackbarMessage ] = useState();
	
    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpend(true);
    };
    
    const cloaseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpend(false);
    };
    // end: snackbar

    // alert
    const [ alertOpen, setAlertOpen ] = useState(false);
    const [ alertContent, setAlertContent ] = useState();

    const showAlert = (message) => {
        setAlertContent(message);
        setAlertOpen(true);
    };
    
    // dialog
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogData, setDialogData ] = useState(false);
    const showDialog = (data) => {
        setDialogData(data);
        setDialogOpen(true);
    }

    // foot portal
    const footContainer = useRef(null);
    const [ takeupFooter, setTakeupFooter ] = useState(0);

	return (
		<AppContext.Provider
			value={{
				showSnackbar,

                showAlert,

                showDialog,

                footContainer,
                setTakeupFooter,
                takeupFooter,
			}}
		>
			{children}

            {/* snackbar */}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: isHandset ? 'center' : 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={cloaseSnackbar}
                message={snackbarMessage}
            />

            {/* alert */}
            <Dialog
                open={alertOpen}
                onClose={() => { setAlertOpen(false); }}
            >
                <DialogContent style={{ minWidth: '240px' }}>
                    <DialogContentText>{alertContent}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setAlertOpen(false) }} color="primary">확인</Button>
                </DialogActions>
            </Dialog>

            {/* dialog */}
            {dialogData && <Dialog
                open={dialogOpen}
                onClose={() => { setDialogOpen(false); }}
            >
                {dialogData.title && <DialogTitle>{dialogData.title}</DialogTitle>}
                <DialogContent style={{ minWidth: '240px' }}>
                    {dialogData.message && <DialogContentText style={{ whiteSpace: 'pre-line' }}>{dialogData.message}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setDialogOpen(false);
                        if (dialogData.second?.onClick) dialogData.second.onClick();
                    }} style={{ color: colors.textSecondary }}>{dialogData.second?.text || '취소'}</Button>

                    <Button onClick={() => {
                        setDialogOpen(false);
                        dialogData.first.onClick();
                    }} color="primary" autoFocus>{dialogData.first.text || '확인'}</Button>
                </DialogActions>
            </Dialog>}

		</AppContext.Provider>
	);
};

export {
	AppContext,
	AppContextProvider
};