import appcolors from 'appcolors';

const palette = {
  // type: 'dark',
  primary: {
    main: appcolors.primary,
  },
  secondary: {
    main: appcolors.secondary,
  },
  error: {
    main: appcolors.error,
  },
  warning: {
    main: appcolors.warn,
  },
  info: {
    main: appcolors.info,
  },
  success: {
    main: appcolors.success
  },
};

export default palette;