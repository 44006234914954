import { Box, Card, CardContent, Divider, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import colors from 'appcolors';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';


const Page404 = () => {
    const history = useHistory();
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Typography variant='h3' style={{ marginBottom: '8px', color: colors.primary, fontWeight: 'bold' }}>MNX15</Typography>
                <Card>
                    <CardContent style={{ paddingTop: '32px', paddingBottom: '24px' }}>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <SearchIcon fontSize='large' color='primary' />
                            <Typography variant='h4' style={{ marginLeft: '8px' }}>페이지를 찾을 수 없습니다.</Typography>
                        </Box>

                        <Typography variant='body2' style={{ margin: '24px 16px 0 16px' }}>MNX15를 찾아주신 고객님,<br />입력하신 페이지의 주소가 잘못 입력되었거나, 변경 또는 삭제되어 요청하신 페이지를 찾을 수 없습니다.<br />입력하신 주소가 정확한지 다시 한번 확인해주시기 바랍니다.</Typography>

                        <Divider style={{ margin: '24px 0 16px 0' }} />
                        <Box>
                            <Button variant='contained' onClick={() => { history.goBack(); }}>이전 페이지로</Button>
                            <Button variant='contained' color='primary' style={{ marginLeft: '8px', color: 'white' }} onClick={() => { window.location.href = process.env.REACT_APP_HOST }}>홈으로</Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default Page404;