module.exports = {
    primaryDark: '#212121',
    primary: '#424242',
    primaryLight: '#616161',
    primaryTint: '#e0e0e0',
    secondaryDark: '#357a38',
    secondary: '#4caf50',
    secondaryLight: '#6fbf73',
    error: '#f44336',
    warn: '#ff9800',
    info: '#2196f3',
    success: '#4caf50',
    textPrimary: 'rgba(0,0,0,0.87)',
    textSecondary: 'rgba(0,0,0,0.54)',
    textDisabled: 'rgba(0,0,0,0.38)',
    textHint: 'rgba(0,0,0,0.38)',
    textNeutral: 'rgb(45,55,72)',
    divider: 'rgba(0,0,0,0.12)',
    money: '#ae0000',
    gray50: '#FAFAFA',
    gray100: '#F5F5F5',
    gray200: '#EEEEEE',
    gray300: '#E0E0E0',
    gray400: '#BDBDBD',
    gray500: '#9E9E9E',
    gray600: '#757575',
    gray700: '#616161',
    gray800: '#424242',
    gray900: '#212121',

    background: '#f5f5f5',
    pageGray: '#f5f5f5',
    borderColor: '#eeeeee',
};