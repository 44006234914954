import React, { useContext } from 'react';
import { Box, Typography, Divider, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { AppContext } from 'contexts/app.context';
import MobileAuth from 'components/MobileAuth';
import API from 'api';


const MobileAuthentication = ({ setMethodState, handleComplete }) => {
	const history = useHistory();

    const { showSnackbar } = useContext(AppContext);
    const { fetchMyinfo } = useContext(AuthContext);

    const onAuthenticated = ({ id, mobile }) => {
        API.put('/auth/update-mobile-with-authentication-code', { id })
        .then(() => {
            showSnackbar('인증이 완료되었습니다.');
            return fetchMyinfo();
        })
        .then(() => { 
            if (handleComplete) {
                handleComplete();
            }
            else {
                window.location.href = '/';
            }
        });
    }

	return (
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>핸드폰번호 인증하기</Typography>
            </div>
            <Divider style={{ marginBottom: '16px' }} />

            <MobileAuth onAuthenticated={onAuthenticated} />

            {setMethodState && <Link style={{ marginTop: '40px', cursor: 'pointer' }} variant='body2' onClick={() => { setMethodState('email'); }}>핸드폰번호로 인증하지 않고 이메일로 인증하기</Link>}
        </Box>
	);
}

export default MobileAuthentication;