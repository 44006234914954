import colors from 'appcolors';

const typography = {
	fontSize: 14,
	h1: {
		fontSize: '28px',
		color: colors.textPrimary,
		// '@media (min-width:600px)': {
		// 	fontSize: 40,
		// },
	},
	h2: {
		fontSize: 24,
		color: colors.textPrimary,
		// '@media (min-width:600px)': {
		// 	fontSize: 34,
		// },
	},
	h3: {
		fontSize: 22,
		color: colors.textPrimary,
		// '@media (min-width:600px)': {
		// 	fontSize: 29,
		// },
	},
	h4: {
		fontSize: 20,
		color: colors.textPrimary,
		// '@media (min-width:600px)': {
		// 	fontSize: 24,
		// },
	},
	h5: {
		fontSize: '18px',
		color: colors.textPrimary,
		// '@media (min-width:600px)': {
		// 	fontSize: '19px',
		// },
	},
	h6: {
		fontSize: '16px',
		color: colors.textPrimary,
		// '@media (min-width:600px)': {
		// 	fontSize: '14px',
		// },
	},
	subtitle1: {
		fontSize: 14,
		color: colors.textNeutral,
	},
	subtitle2: {
		fontSize: 12,
		color: colors.textPrimary,
	},
	body1: {
		fontSize: 14,
		color: colors.textNeutral,
	},
	body2: {
		fontSize: 12,
		color: colors.textPrimary,
	},
};


export default typography;