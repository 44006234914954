import React, { useContext, useEffect } from 'react';
import { Container, Divider } from '@material-ui/core';
import API from 'api';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import Proxies from './Proxies';
import PayInfo from './PayInfo';
import DeliveryInfo from './DeliveryInfo';
import ReturnInfo from './ReturnInfo';
import RefundInfo from './RefundInfo';
import Inquiry from './Inquiry';
import Review from './Review';
import FooterStatus from './FooterStatus';
import { ItemPageContext } from 'contexts/item-page.context';


const styleTag = `<style>img { max-width: 100%; }</style>`;

export default function ItemPage() {
    const { code } = useParams();

    const { item, setItem, count, proxy, hasAtLeastOneProxy, handleBagClick } = useContext(ItemPageContext);
    useEffect(() => {
        API.get('/item/item/' + code)
        .then(setItem);
    }, [ code ]);

    if (!item) return (<Loading />);

	return (
		<Container>
            {/* 상품품 갤러리 및 선택 */}
            <Proxies item={item} handleBagClick={handleBagClick} />

            {/* 상품정보 */}
            <Divider />
            {item.content ? <div style={{ textAlign: 'center' }}><div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: styleTag + item.content }} /></div> : <Nodata>상품설명 정보가 없습니다.</Nodata>}

            {/* 상품평 */}
            <Divider />
            <Review item={item} />

            {/* 상품문의 */}
            <Divider />
            <Inquiry />

            {/* 결제정보 */}
            <Divider />
            <PayInfo />

            {/* 배송정보 */}
            <Divider />
            <DeliveryInfo />

            {/* 교환/반품 정보 */}
            <Divider />
            <ReturnInfo />

            {/* 환불정보 */}
            <Divider />
            <RefundInfo />

            {/* 상품 구매 고정 바 */}
            <FooterStatus />
        </Container>
	);
}