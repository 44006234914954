import React, { createContext, useState, useEffect, useContext } from 'react';
import API from '../api';
import { AppContext } from './app.context';
import { AuthContext } from './auth.context';

const BagContext = createContext({});


const BagContextProvider = ({ children }) => {
    const { me } = useContext(AuthContext);
    const { showSnackbar } = useContext(AppContext);

    // 노출 쇼핑백 상품목록
    const [ stuff, setStuff ] = useState([]);

    useEffect(() => {
        getMyBag();
    }, [ me ]);

    const getMyBag = async () => {
        await API.get('/bag/my-bag')
        .then(setStuff);
    }

    // 단일 상품 추가 (쇼핑백 담기를 클릭했을시 동작)
    const appendStuff = async (stuff) => {
        const data = [ stuff ];
        await API.post('/bag/append-stuff-to-bag', data);
        showSnackbar('쇼핑백에 담았습니다.');
        await getMyBag();
    }

    const modifyStuffCount = async (item, count) => {
        try {
            const body = {
                itemId: item.id,
                proxyId: item.proxy?.id,
                price: item.stuff.price,
                eventPrice: item.stuff.eventPrice,
                count,
            }

            await API.put('/bag/update-bag-count', body);
            await getMyBag();
        }
        catch(error) {
            console.error(error);
        }
    }

    const clearStuff = async () => {
        try {
            await API.delete('/bag/clear-bag');
            await getMyBag();
        }
        catch(error) { console.error(error); }
    }

    // 이벤트상태를 해제한다.
    const dismissEvent = async ({ proxyId, itemStuff }) => {
        try {
            const body = { proxyId, eventPrice: itemStuff.eventPrice };
            await API.post('/bag/dismiss-event', body);
            await getMyBag();
        }
        catch(error) { console.error(error); }
    }
    

	return (
		<BagContext.Provider
			value={{
                stuff,

                appendStuff,
                
                modifyStuffCount,

                clearStuff,

                dismissEvent,
			}}
		>
			{children}
            
		</BagContext.Provider>
	);
};

export {
	BagContext,
	BagContextProvider
};