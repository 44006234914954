import { Box, Card, CardContent, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import colors from 'appcolors';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { useHistory } from 'react-router-dom';


const OnRepairPage = () => {
    const history = useHistory();
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2} marginY={5}>
                <Typography variant='h3' style={{ marginBottom: '8px', color: colors.primary, fontWeight: 'bold' }}>MNX15</Typography>
                <Card>
                    <CardContent style={{ paddingTop: '32px', paddingBottom: '24px' }}>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <ErrorOutlineOutlinedIcon fontSize='large' color='primary' />
                            <Typography variant='h4' style={{ marginLeft: '8px' }}>현재 일시적 서버점검중입니다.</Typography>
                        </Box>

                        <Typography variant='body1' style={{ margin: '24px 16px 0 16px' }}>MNX15를 찾아주신 고객님,<br />서버점검이 빠르게 끝날 수 있도록 하겠습니다.<br />감사합니다.</Typography>
                    </CardContent>
                </Card>
            </Box>
        </div>
    );
}

export default OnRepairPage;