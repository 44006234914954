import React from 'react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import colors from 'appcolors';
import MypageMenu from './MypageMenu';


const Mypage = () => {
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

	return (
        <div style={{ backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'}>
                <MypageMenu />
            </Box>
        </div>
	);
}

export default Mypage;