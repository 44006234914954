import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from '@material-ui/core';
import colors from 'appcolors';
import AddressItem from 'views/mypage/address/AddressItem';
import Nodata from 'components/Nodata';


const MyAddressesModal = ({ addressModalOpen, setAddressModalOpen, addresses, onAddressSelected }) => {
    if (!addresses) return null;

	return (
		<Dialog
            open={addressModalOpen}
            onClose={() => { setAddressModalOpen(false); }}
        >
            <DialogTitle>배송지 선택</DialogTitle>
            <DialogContent style={{ minWidth: '240px' }}>
                {addresses.length > 0 ? (addresses.map((address) => <div key={address.id} style={{ marginBottom: '8px' }}><AddressItem address={address} intend={'select'} onSelected={onAddressSelected} /></div>)
                ) : <Nodata style={{ whiteSpace: 'pre-line' }}>{'선택가능한 배송지가 없습니다.\n배송지를 추가해주세요.'}</Nodata>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setAddressModalOpen(false);
                }} style={{ color: colors.textSecondary }}>{'닫기'}</Button>
            </DialogActions>
        </Dialog>
	);
}

export default MyAddressesModal;