import React, { useState, useMemo, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';

import API from 'api';
import ProductForm from './ProductForm';
import ProductPreview from './ProductPreview';
import useQuery from 'hooks/useQuery';



const ProductPage = () => {
    const query = useQuery(); 

    const productCode = useMemo(() => {
        if (query) {
            return query.get('productCode') || 'basic';
        }
    }, [ query ]);

    // 상품에 속하는 상품들
    const [ items, setItems ] = useState();
    
    useEffect(() => { 
        const params = {
            page: 1,
            productCode
        };
        API.get('/item/items', { params })
        .then(data => {
            setItems(data.rows);
        });
    }, [ productCode ]);
    // end: 상품에 속하는 상품들

    // 선택된 상품
    const [ item, setItem ] = useState();

	return (
		<Container style={{ padding: '20px 16px' }}>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={5}>
					<ProductPreview productCode={productCode} items={items} item={item} setItem={setItem} />
				</Grid>
				<Grid item xs={12} sm={7}>
					<ProductForm productCode={productCode} items={items} item={item} setItem={setItem} />
				</Grid>

			</Grid>
		</Container>
	);
}

export default ProductPage;