import React, { useState } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Card, Button, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import * as Yup from 'yup';
import API from 'api';
import colors from 'appcolors';
import TextField from 'components/TextField';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        margin: '16px 0 8px 0',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
    subButton: {
        display: 'block',
        marginTop: '8px',
        fontSize: '14px',
    },
}));

const FindPasswordPage = () => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [ passwordInitDialogOpen, setPasswordInitDialogOpen ] = useState(false);

	return <>
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isMobile ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Card style={{ marginTop: '24px' }}>
                    <CardContent>
                        <Formik
                            initialValues={{
                                email: ''
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string().required("필수입력입니다."),
                            })}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                API.post('/auth/init-my-password', values)
                                .then((data) => {
                                    setSubmitting(false);
                                    setPasswordInitDialogOpen(true);
                                })
                                .catch(error => {
                                    setSubmitting(false);
                                });
                            }}
                        >
                            {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className={classes.section}>
                                        <Typography variant="subtitle1">비밀번호 찾기</Typography>
                                        <Typography variant="body2" style={{ color: colors.textSecondary, whiteSpace: 'pre-line' }}>{'입력하신 이메일로 초기화된 비밀번호를 보내드립니다.\n로그인 후 내 페이지에서 비밀번호를 변경하시길 권해드립니다.'}</Typography>
                                        
                                        <Typography variant="subtitle2" className={classes.label}>이메일</Typography>
                                        <TextField
                                            variant="outlined"
                                            className={classes.input}
                                            size="small"
                                            required={true}
                                            autoFocus
                                            name={'email'}
                                            autoComplete={'email'}
                                            onChange={handleChange('email')}
                                            onBlur={handleBlur('email')}
                                            helperText={(touched.email && errors.email) || ' '}
                                            error={!!(touched.email && errors.email)}
                                            value={values.email}
                                        />

                                        <Button type="submit" size="small" variant="outlined" color="primary" disableElevation className={classes.subButton} style={{ color: colors.primary, borderRadius: 0 }}>초기화</Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>

                <Button component={Link} to="/auth/signin" disableElevation style={{ borderRadius: 0, alignSelf: 'flex-start', color: 'white', marginTop: '16px' }} variant='contained' color="primary" >로그인 페이지로 이동</Button>
            </Box>
		</div>

        <Dialog
            open={passwordInitDialogOpen}
            onClose={() => { setPasswordInitDialogOpen(false) }}
        >
            <DialogTitle>{'이메일 찾기 결과'}</DialogTitle>
            <DialogContent style={{ minWidth: '300px'}}>
                <DialogContentText>입력하신 이메일로 초기화된 비밀번호를 보냈습니다. 이메일에서 확인하세요.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ color: colors.primary }} onClick={() => { setPasswordInitDialogOpen(false)}} color="primary" autoFocus>확인</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default FindPasswordPage;