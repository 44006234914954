import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import colors from 'appcolors';
import ProxySearch from './ProxySearch';
import Filter from 'views/item/search/Filter';


export default function ProxySearchPage () {
    const theme = useTheme();


    // query-params (파라미터를 입력으로 화면을 그리지만, 반대로 파라미터를 다시쓸때는 query-params 을 쓰지 않는다. react-router v4 에서는 조금 귀찮음)
    const [ params, setParams ] = useState({});
    const { search } = useLocation();
    
    useEffect(() => {
        const query = new URLSearchParams(search);
        const keyword = query.get('keyword');
        const category = query.get('category');
        const spike = query.get('spike');
        const lace = query.get('lace');
        const gender = query.get('gender');
        const size = query.get('size');

        setParams({
            keyword,
            category,
            spike,
            lace,
            gender,
            plusEvent: true,
            optionName: size,
            keyword,
        });
    }, [ search ]);
    // end: query params
   

	return (
        <div style={{ backgroundColor: colors.background }}>
            <div style={{ marginTop: '24px', }}>
                <Filter params={params} setParams={setParams} withSize={true} />
            </div>

            <ProxySearch params={params} />
        </div>
	);
}