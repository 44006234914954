import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from './app.context';
import { AuthContext } from './auth.context';
import API, { setupInterceptor } from 'api';
import useGetMyCoupons from 'views/mypage/mycoupons/useGetMyCoupons';
import Loading from 'components/Loading';
import OnRepairPage from 'components/OnRepairPage';


const MemoryContext = createContext({});

const MemoryContextProvider = ({ children }) => {

	// setup interceptor
    const history = useHistory();
    const { me, clearAuthInfo, setLoginRedirectURL, fetchMyinfo } = useContext(AuthContext);
	const { showAlert, showDialog } = useContext(AppContext);

    const [ canApi, setCanApi ] = useState(false);
    useEffect(() => {
		if (!canApi) {
			// 현재 개발환경에서 두번 설정되는 버그가 있음(에러가 난 후 수정 후에 저장하면 인터셉터 설정함수가 중복 설정되어 fetchMyinfo 에서 에러가 발생하는 것으로 생각됨)
			setupInterceptor(
				history, 
				clearAuthInfo, 
				setLoginRedirectURL,
				showAlert,
				showDialog,
			);
		}
        setCanApi(true);
    }, [ canApi ]);
    // end: setup interceptor

	useEffect(() => {
		if (canApi) {
			fetchMyinfo();
		}
	}, [ canApi ]);

    const logDB = ({ division, code, message, meta }) => {
		if (!canApi) {
			console.error('api is yet initiated.');
			return;
		}
		API.post('/common/app-log', {
			division, code, message, meta
		});
	}

	// app common info
	const [ commonInfo, setCommonInfo ] = useState();
	useEffect(() => {
		if (canApi) {
			API.get('/common/app-common-info')
			.then(setCommonInfo);
		}
	}, [ canApi ]);
	// end: app common info

	// my available coupons & day15coupon
	const [ availableCouponsTrigger, setAvailableCouponsTrigger ] = useState();
	const { rows: availableCoupons } = useGetMyCoupons(true, availableCouponsTrigger);

	// coupon from URL query
	const [ urlCoupon, setUrlCoupon ] = useState();
	const { search } = useLocation();
	useEffect(() => {
		if (!canApi) return;

		const query = new URLSearchParams(search);
        const couponToken = query.get('coupon_token');
		if (couponToken) {
			const params = { token: couponToken };
			API.get('/coupon/from-coupon-token', { params })
			.then(coupon => {
				if (!coupon.usedAt) setUrlCoupon(coupon);
			});
		}
	}, [ canApi ]);
	// end: url coupon

	// 우측상단 가이드 visibility
	const [ guideClose, setGuideClose ] = useState(false);

	if (!canApi || !commonInfo) return (<Loading />);
	if (commonInfo.onRepair === 'Y' && !me?.tester) {
		return (<OnRepairPage />);
	}

	return (
		<MemoryContext.Provider
			value={{
				canApi,
				
				logDB,

				commonInfo,

				availableCoupons,
				setAvailableCouponsTrigger,

				urlCoupon,

				guideClose,
				setGuideClose,
			}}
		>
			{children}
		</MemoryContext.Provider>
	);
};

export {
	MemoryContext,
	MemoryContextProvider
};