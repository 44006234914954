import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SigninPage from 'views/signin/SigninPage';
// import HomePage from 'views/home/HomePage';
import TestPage from 'views/test/TestPage';
import TestPage2 from 'views/test/TestPage2';
import ViewTestPage from 'views/test/ViewTestPage';
import TestPage3 from 'views/test/TestPage3';
import ProductPage from 'views/product/ProductPage';
import SignupPage from 'views/signup/SignupPage';
import Mypage from 'views/mypage/Mypage';
import TicketHomePage from 'views/ticket/TicketHomePage';
import WithLayout from 'WithLayout';
import MainLayout from 'layouts/MainLayout';
import TicketWritePage from 'views/ticket/TicketWritePage';
import Page404 from 'components/Page404';
import PayTest from 'views/test/PayTest';
import BillKeyTest from 'views/test/BillKeyTest';
import OrderFormPage from 'views/order/form/OrderFormPage';
import ItemSearchPage from 'views/item/search/ItemSearchPage';
import ItemPage from 'views/item/detail/ItemPage'
import Loading from 'components/Loading';
import TestPage4 from 'views/test/TestPage4';
import { ItemPageContextProvider } from 'contexts/item-page.context';
import InquiryHome from 'views/inquiry/InquiryHome';
import Inquiry from 'views/inquiry/Inquiry';
import AddressFormPage from 'views/mypage/address/AddressFormPage';
import OrderSuccessPage from 'views/order/OrderSuccessPage';
import MyOrdersPage from 'views/mypage/myorders/MyOrdersPage';
import MyinfoPage from 'views/mypage/myinfo/MyinfoPage';
import MyAddressPage from 'views/mypage/address/MyAddress';
import MyReviewsPage from 'views/mypage/reviews/MyReviewsPage';
import ReviewFormPage from 'views/mypage/reviews/ReviewFormPage';
import MyClaimPage from 'views/mypage/myclaims/MyClaimsPage';
import ClaimFormPage from 'views/mypage/myclaims/ClaimFormPage';
import AlarmSetupPage from 'views/mypage/alarm/AlarmSetupPage';
import BucketPage from 'views/mypage/bucket/BucketPage';
import AuthPendingPage from 'views/signup/AuthPendingPage';
import MembershipJoinPage from 'views/membership/join/MembershipJoinPage';
import MyCouponsPage from 'views/mypage/mycoupons/MyCouponsPage';
import FindEmailPage from 'views/signin/FindEmailPage';
import FindPasswordPage from 'views/signin/FindPasswordPage';
import EmailAuthenticationPage from 'views/mypage/EmailAuthenticationPage';
import MobileAuthenticationPage from 'views/mypage/MobileAuthenticationPage';
import Cafe24SigninPage from 'views/signin/Cafe24SigninPage';
import Cafe24SignupPage from 'views/signup/Cafe24SignupPage';
import ProxySearchPage from 'views/proxy/search/ProxySearchPage';


const Membership = lazy(() => import('views/membership/Membership'));

const Routes = () => {
    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Route path="/inquiry-home"><WithLayout component={InquiryHome} layout={MainLayout}/></Route>
                <Route path="/ticket" exact={true}><WithLayout component={TicketHomePage} layout={MainLayout}/></Route>
                <Route path="/ticket/write"><WithLayout component={TicketWritePage} layout={MainLayout}/></Route>
                <Route path="/inquiry"><WithLayout component={Inquiry}layout={MainLayout}/></Route>
                <Route path="/mypage/myinfo" ><WithLayout component={MyinfoPage} layout={MainLayout}/></Route>
                <Route path="/mypage/myorders"><WithLayout component={MyOrdersPage} layout={MainLayout}/></Route>
                <Route path="/mypage/bucket/:bucketId"><WithLayout component={BucketPage} layout={MainLayout}/></Route>
                <Route path="/bucket/:bucketId"><WithLayout component={BucketPage} layout={MainLayout}/></Route>
                <Route path="/mypage/myreviews" ><WithLayout component={MyReviewsPage} layout={MainLayout}/></Route>
                <Route path="/mypage/review/form/:bucketId" ><WithLayout component={ReviewFormPage} layout={MainLayout}/></Route>
                <Route path="/mypage/myclaims" ><WithLayout component={MyClaimPage} layout={MainLayout}/></Route>
                <Route path="/mypage/claim/form/:bucketId"><WithLayout  component={ClaimFormPage} layout={MainLayout}/></Route>
                <Route path="/mypage/myaddresses" ><WithLayout component={MyAddressPage} layout={MainLayout}/></Route>
                <Route path="/mypage/address-form/:addressId"><WithLayout  component={AddressFormPage} layout={MainLayout}/></Route>
                <Route path="/mypage/address-form" exact={true}><WithLayout  component={AddressFormPage} layout={MainLayout}/></Route>
                <Route path="/mypage/mycoupons" ><WithLayout component={MyCouponsPage} layout={MainLayout}/></Route>
                <Route path="/mypage/alarm-setup" ><WithLayout component={AlarmSetupPage} layout={MainLayout}/></Route>
                <Route path="/mypage" exact={true}><WithLayout component={Mypage} layout={MainLayout} /></Route>
                <Route path="/auth/find-email"><WithLayout component={FindEmailPage} layout={MainLayout}/></Route>
                <Route path="/auth/find-password"><WithLayout component={FindPasswordPage} layout={MainLayout}/></Route>
                <Route path="/auth/user-pending"><WithLayout component={AuthPendingPage} layout={MainLayout}/></Route>
                <Route path="/auth/authenticate-email"><WithLayout component={EmailAuthenticationPage} layout={MainLayout}/></Route>
                <Route path="/auth/authenticate-mobile"><WithLayout component={MobileAuthenticationPage} layout={MainLayout}/></Route>
                <Route path="/auth/signin"><WithLayout component={SigninPage} layout={MainLayout}/></Route>
                <Route path="/auth/signup"><WithLayout component={SignupPage} layout={MainLayout}/></Route>
                <Route path="/auth/cafe24-signin"><WithLayout component={Cafe24SigninPage} layout={MainLayout}/></Route>
                <Route path="/auth/cafe24-signup"><WithLayout component={Cafe24SignupPage} layout={MainLayout}/></Route>
                <Route path="/item/:code"><ItemPageContextProvider><WithLayout component={ItemPage} layout={MainLayout} /></ItemPageContextProvider></Route>
                <Route path="/item" exact={true}><WithLayout component={ItemSearchPage} layout={MainLayout} /></Route>
                <Route path="/proxy" exact={true}><WithLayout component={ProxySearchPage} layout={MainLayout} /></Route>
                <Route path="/product"><WithLayout component={ProductPage} layout={MainLayout} /></Route>
                <Route path="/order/form"><WithLayout component={OrderFormPage} layout={MainLayout} /></Route>
                <Route path="/order/success/:merchantId"><WithLayout component={OrderSuccessPage} layout={MainLayout} /></Route>
                
                {/* client app 의 홈을 item 으로 리다이렉트 */}
                <Route path="/" exact><Redirect to="/item" /></Route>
                <Route path="/membership/join"><WithLayout component={MembershipJoinPage} layout={MainLayout} /></Route>
                <Route path="/membership" exact={true} component={Membership} />

                <Route path="/test"><TestPage /></Route>
                <Route path="/test2"><TestPage2 /></Route>
                <Route path="/test3"><TestPage3 /></Route>  {/* api error test */}
                <Route path="/test4"><TestPage4 /></Route>  {/* app.context test */}
                <Route path="/view-test"><ViewTestPage /></Route>
                <Route path="/pay-test"><PayTest /></Route>
                <Route path="/bill-key-test"><BillKeyTest /></Route>

                <Route path="404" component={Page404} />
                <Route path="*" component={Page404} />
            </Switch>
        </Suspense>
    );
}

export default Routes;