import React, { useState, useContext } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Card, Button, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import * as Yup from 'yup';
import API from 'api';
import colors from 'appcolors';
import { Formik } from 'formik';
import { AppContext } from 'contexts/app.context';
import { Link } from 'react-router-dom';
import TextField from 'components/TextField';


const useStyles = makeStyles((theme) => ({
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        margin: '16px 0 8px 0',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
    subButton: {
        display: 'block',
        marginTop: '8px',
        fontSize: '14px',
    },
}));

const FindEmailPage = () => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { showSnackbar } = useContext(AppContext);

    const [ emailResultDialogOpen, setEmailResultDiealogOpen ] = useState(false);
    const [ emails, setEmails ] = useState();

	return <>
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isMobile ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Card>
                    <CardContent>
                        <Formik
                            initialValues={{
                                nickname: '',
                                mobile: '',
                            }}
                            validationSchema={Yup.object({
                                nickname: Yup.string().min(2, "두 자 이상 입력하세요."),
                                mobile: Yup.string(),
                            })}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                if (!values.mobile && !values.nickname) return showSnackbar('닉네임과 핸드폰 번호 중 하나 이상 입력하세요.');
                                API.post('/auth/find-my-email', values)
                                .then((data) => {
                                    setEmails(data);
                                    setEmailResultDiealogOpen(true);
                                })
                                .finally(() => { setSubmitting(false); });
                            }}
                        >
                            {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className={classes.section}>
                                        <Typography variant="h5">이메일 찾기</Typography>
                                        <Typography variant="body2" style={{ marginTop: '16px', color: colors.textSecondary }} >닉네임 또는 핸드폰번호 중 하나 이상 입력해주세요.</Typography>
                                        
                                        <Typography variant="subtitle2" className={classes.label}>닉네임</Typography>
                                        <TextField
                                            variant="outlined"
                                            className={classes.input}
                                            name="nickname"
                                            autoComplete="nickname"
                                            autoFocus
                                            size="small"
                                            onChange={handleChange('nickname')}
                                            onBlur={handleBlur('nickname')}
                                            helperText={(touched.nickname && errors.nickname) || ' '}
                                            error={!!(touched.nickname && errors.nickname)}
                                            value={values.nickname}
                                        />

                                        <Typography variant="subtitle2" className={classes.label}>핸드폰 번호 ('-' 없이 숫자만 입력하세요.)</Typography>
                                        <TextField
                                            variant="outlined"
                                            className={classes.input}
                                            size="small"
                                            type="tel"
                                            autoComplete='tel'
                                            onChange={handleChange('mobile')}
                                            onBlur={handleBlur('mobile')}
                                            helperText={(touched.mobile && errors.mobile) || ' '}
                                            error={!!(touched.mobile && errors.mobile)}
                                            value={values.mobile}
                                        />

                                        <Button type="submit" size="small" variant="outlined" color="primary" disableElevation className={classes.subButton} style={{ borderRadius: 0, color: colors.primary }}>검색</Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
                
                <Button component={Link} to="/auth/signin" style={{ alignSelf: 'flex-start', color: 'white', marginTop: '16px', borderRadius: 0 }} variant='contained' color="primary" disableElevation>로그인 페이지로 이동</Button>
            </Box>
		</div>

        <Dialog
            open={emailResultDialogOpen}
            onClose={() => { setEmailResultDiealogOpen(false) }}
        >
            <DialogTitle>{'이메일 찾기 결과'}</DialogTitle>
            <DialogContent style={{ minWidth: '300px'}}>
                <DialogContentText>
                    {emails && emails.length > 0 ? <>
                        {emails.map((email, index) => <div key={index}>{email}</div>)}
                    </> : <>
                        조건에 만족하는 이메일이 없습니다.
                    </>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ color: colors.primary }} onClick={() => { setEmailResultDiealogOpen(false)}} color="primary" autoFocus>확인</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default FindEmailPage;