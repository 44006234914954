import { TextField as MuiTextField,  styled } from '@material-ui/core';

const TextField = styled(MuiTextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: 0,
    }
});




export default TextField;