import React, { useState, useEffect, useContext, useMemo, useRef, useCallback } from 'react';
import { Container, Grid, Typography, useTheme, useMediaQuery, CardContent, Card, CardActionArea, CardMedia, Portal } from '@material-ui/core';
import Button from 'components/Button';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
import colors from 'appcolors';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import { number_format_pipe, item_category_pipe } from 'pipes';
import { BagContext } from 'contexts/bag.context';
import { AppContext } from 'contexts/app.context';
import useSearchProxy from './useSearchProxy';


const ProxySearch = ({ params }) => {
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const [ page, setPage ] = useState(0);
    const { 
        rows,
        loading,
        mayMore,
    } = useSearchProxy(params, page);

    useEffect(() => {
        setPage(0);
    }, [ params ]);

     // 하단 쇼핑백 내역 노출
     const { footContainer, setTakeupFooter } = useContext(AppContext);
     const { stuff } = useContext(BagContext);
     useEffect(() => {
         if (stuff?.length > 0) {
             setTakeupFooter('120px');
             return () => { setTakeupFooter(0); }
         }
     }, [ stuff ]);
     const bagPrice = useMemo(() => {
         if (stuff) {
             let sum = 0;
             stuff.forEach(item => sum += item.price * item.count);
             return sum;
         }
     }, [ stuff ]);
     const handleGoOrderForm = () => {
         history.push('/order/form');
     }
     // end

     // pagination
    const observer = useRef();
    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && mayMore) {
                // console.log('visible');
                setPage(val => val + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [ loading, mayMore ]);
    // end: pagination

    const itemMainImage = (fileInfos) => {
        if (fileInfos?.length > 0) {
            const main = fileInfos.find(el => !!el.main);
            if (main) return main?.downloadURL;
            else return fileInfos[0].downloadURL;
        }
    } 

    const categoryBackgroundColor = (category) => {
        switch(category) {
            case 'height': return '#3f51b5aa';
            case 'sneakers': return '#f44336aa';
            default: return colors.primary + 'cc';
        }
    }

    const optionNamePipe = (proxy) => {
        const options = proxy.options;
        if (options?.length > 0) {
            return options.map(el => el.option.name).join(', ');
        }
        else return '-';
    }

	return (
        <>
            <Container style={{ paddingBottom: '24px'}}>
                {params.optionName 
                    ? <Typography style={{ marginTop: '16px' }} variant={isHandset ? 'body1' : 'h6'}>사이즈가 <span style={{ fontWeight: 'bold', color: colors.primaryDark }}>{`${params.optionName}`}</span>인 1+1 할인상품입니다.</Typography>
                    : <Typography style={{ marginTop: '16px' }} variant={isHandset ? 'body1' : 'h6'}>1+1 할인상품입니다.</Typography>
                }
                <Typography variant={isHandset ? 'body1' : 'h6'}><span style={{ fontWeight: 'bold', color: colors.primaryDark }}>종류와 관계없이,</span> 2개이상 주문시 할인가격으로 구매됩니다.</Typography>

                {rows.length > 0 ? <>
                    <Grid container style={{ marginTop: isHandset ? '16px' : '20px' }} spacing={2}>
                        {rows.map((proxy, index) => <Grid item key={proxy.id} xs={6} sm={4} md={3}>
                            <Card component={RouterLink} to={`/item/${proxy.code}?proxyId=${proxy.id}`} style={{ textDecoration: 'none' }} ref={(index + 1 < rows.length) ? null : lastElementRef}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        alt={proxy.name}
                                        style={{ width: '100%', aspectRatio: '1 / 1' }}     // image shifting 방지
                                        image={itemMainImage(proxy.images)}
                                    />
                                    <CardContent style={{ backgroundColor: 'white' }}>
                                        <Typography variant="h6" component="h2" style={{ marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{proxy.name}</Typography>
                                        <Typography gutterBottom variant="body1" style={{ color: colors.secondary }}>{optionNamePipe(proxy)}</Typography>
                                        <Typography variant="body1" style={{ color: colors.textSecondary }}>{number_format_pipe(proxy.normalPrice) + '원'}</Typography>
                                        <Typography variant="body1">1+1 할인가 <span style={{ fontSize: '1.22em', color: 'orange', fontWeight: 'bold' }}>{number_format_pipe(proxy.plusEventPrice) + '원'}</span></Typography>
                                    </CardContent>
                                    <div style={{ position: 'absolute', top: 0, left: 0, padding: '2px 8px', backgroundColor: categoryBackgroundColor(proxy.category) }}>
                                        <Typography variant='body2' style={{ color: 'white' }}>{item_category_pipe(proxy.category)}</Typography>
                                    </div>
                                </CardActionArea>
                            </Card>
                        </Grid>)}
                    </Grid>
                </> : <Nodata style={{ marginTop: '24px' }}>조건을 만족하는 상품이 없습니다.</Nodata>}
                {loading && <Loading />}
            </Container>

            {/* 쇼핑백 고정 바 */}
            {stuff?.length > 0 && <Portal container={footContainer.current}>
                <div style={{ backgroundColor: 'rgb(248,248,248)', borderTop: '1px solid #dddddd', maxHeight: '120px', padding: '16px 0' }}>
                    <Container style={{ height: '100%', display: 'flex', flexDirection: isHandset ? 'column' : 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LocalMallIcon fontSize='small' style={{ color: colors.textPrimary }} />
                            <Typography variant={isHandset ? 'body2' : 'h6'} style={{ marginLeft: '8px' }}>쇼핑백에 상품이 있습니다.</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', alignSelf: isHandset ? 'flex-end' : 'center' }}>
                            <Typography variant="h3" style={{ color: colors.textNeutral, fontWeight: 'bold', marginRight: '16px' }}>{number_format_pipe(bagPrice)} 원</Typography>
                            <Button color="primary" variant="contained" size={isHandset ? 'medium' : 'large'} style={{ color: 'white' }} onClick={handleGoOrderForm}>보러가기</Button>
                        </div>
                    </Container>
                </div>
            </Portal>}
        </>
	);
}

export default ProxySearch;