import React from 'react';

import globals from 'appglobals';



const PayTest = () => {

    const handleButton = () => {
        const data = {
            pg: "html5_inicis",
            pay_method: "card",
            merchant_uid: "ORD20180131-0000011",
            name: "노르웨이 회전 의자",
            amount: 100,
            buyer_email: "gildong@gmail.com",
            buyer_name: "홍길동",
            buyer_tel: "010-4258-9310",
            buyer_addr: "서울특별시 강남구 신사동",
            buyer_postcode: "01181"
        }

        const { IMP } = window;
        IMP.init(globals.iamport.id);
        IMP.request_pay(data, (rsp) => {
            console.log(rsp);
        });

    
    }

	return (
        <div>
            <button onClick={handleButton}>button</button>
        </div>
	);
}

export default PayTest;