import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Card, CardContent, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import API from 'api';
import colors from 'appcolors';
import { AppContext } from 'contexts/app.context';
import { AuthContext } from 'contexts/auth.context';
import CircularButton from 'components/CircularButton';
import Button from 'components/Button';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
const qs = require('qs');


const useStyles = makeStyles((theme) => ({
    emphasize: {
        color: colors.primary, fontWeight: 'bold',
    },
    methodWrapper: {
        flex: 1,
        borderRadius: '4px',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
    },
}));

const MembershipJoinPage = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { showSnackbar } = useContext(AppContext);
    const { me, fetchMyinfo } = useContext(AuthContext);

    const [ channelAdded, setChannelAdded ] = useState(false);
    useEffect(() => {
        if (me?.kakaoId) {
            API.get('/auth/check-channel-added')
            .then(added => {
                setChannelAdded(added);
            });
        }
        else {
            setChannelAdded(false);
        }
    }, [ me ]);
    useEffect(() => {
        if (channelAdded) setUseKakao(true);
    }, [ channelAdded ]);

    const [ useKakao, setUseKakao ] = useState(false);
    const [ useEmail, setUseEmail ] = useState(false);
    const [ useSms, setUseSms] = useState(false);

    const handleJoinMembership = () => {
        const data = {
            kakao: useKakao,
            sms: useSms,
            email: useEmail,
        };
        API.post('/membership/join-membership', data)
        .then(() => {
            showSnackbar('MNX15 Premium 멤버십에 가입되었습니다.');
            fetchMyinfo();      // user.auths 업데이트
            history.push('/item');
        });
    }

    // ----- kakao connect -----
    const handleKakaoChannelAdd = async () => {
        // 카카오 앱 아이디가 없으면 먼저 카카오 아이디 연결 
        await handleKakaoConnect();

        console.log(window.Kakao.isInitialized());
        window.Kakao.Channel.addChannel({
            channelPublicId: process.env.REACT_APP_KAKAO_CHANNEL_ID,
        });
    }

    const handleKakaoConnect = async () => {
        if (me.kakaoId) return;
        const client_id = process.env.REACT_APP_KAKAO_RESTAPI_KEY;
		const redirect_uri = process.env.NODE_ENV === 'development' ? 'http://localhost:4201/membership/join' : `${process.env.REACT_APP_URL}/membership/join`;
		const response_type = 'code';
		const url = `https://kauth.kakao.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}`;
		window.open(url, "_self");
    }

	const { search } = useLocation();
    const code = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('code');
    }, [search]);
	useEffect(() => {
		if (!code) return;

		const data = { 
			grant_type: 'authorization_code',
			client_id: process.env.REACT_APP_KAKAO_RESTAPI_KEY,
			redirect_uri: process.env.NODE_ENV === 'development' ? 'http://localhost:4201/membership/join' : `${process.env.REACT_APP_URL}/membership/join`,
			code,
		};

		axios({
			method: 'post',
			url: `https://kauth.kakao.com/oauth/token`,
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data: qs.stringify(data),
		})
		.then((data) => {
			let { access_token } = data.data;

			// 카카오에서 이후 사용자 정보 호출은 브라우저에서 호출 못하도록 cors 정책으로 막아놓음, 백엔드에서 해결해야 함
			const body = { access_token };
			return API.post('/auth/connect-kakao-account', body);
		})
		.then((data) => {
            fetchMyinfo();
		});
	}, [ code ]);
    // ----- end: kakao connect -----
    
    if (!me) return null;
    
	return (
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                <Box width={{ xs: '400px', sm: '600px', md: '800px' }} display="flex" paddingX={2}>
                    <Card style={{ width: '100%' }}>
                        <CardContent>
                            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>MNX15 Preminum</Typography>
                                {/* <Button color='primary' onClick={handleTicketClick}>바로가기</Button> */}
                            </div>
                            
                            <Divider style={{ margin: '8px 0 24px 0' }} />
                            <Typography variant='h5' style={{ marginBottom: '16px', color: colors.textSecondary }}>가입조건</Typography>
                            <Typography variant='body1'>MNX15 Premium 멤버십에 가입하면, 가입즉시 그리고 매월 15일에 15%할인 쿠폰을 받습니다. <span className={classes.emphasize}>카카오채널, 이메일, SMS문자</span> 중 1개 이상을 통해서 지급받으실 수 있습니다.</Typography>

                            <Divider style={{ margin: '24px 0' }} />
                            <Typography variant='h5' style={{ marginBottom: '16px', color: colors.textSecondary }}>할인쿠폰 지급받을 방법 선택 (택1)</Typography>
                            <div style={{ display: 'flex', flexDirection: isHandset ? 'column' : 'row' }}>
                                {/* kakao channel */}
                                <div className={classes.methodWrapper} style={{ marginRight: isHandset ? 0 : '16px', marginBottom: isHandset ? '16px' : 0, backgroundColor: '#fffae1' }}>
                                    <Typography variant='body1' style={{ marginBottom: '8px', fontWeight: 'bold' }}>카카오채널로 지급받기</Typography>
                                    <Typography variant='body2'>카카오아이디 연결여부: {me.kakaoId ? 'O' : 'X'}</Typography>
                                    <Typography variant='body2'>MNX15 카카오채널 추가여부: {channelAdded ? 'O' : 'X'}</Typography>
                                    <div style={{ marginTop: '8px' }}>
                                        {me.kakaoId && channelAdded ? 
                                            <FormControlLabel
                                                control={<Checkbox checked={useKakao} onChange={() => { setUseKakao(!useKakao) }} name="checkedA" />}
                                                label="카카오채널"
                                            /> : <div>
                                                <Typography variant='body2' style={{ flex: 1, marginBottom: '4px', color: colors.primary, fontWeight: 'bold' }}>*MNX15 카카오 채널을 추가후 창을 "새로고침" 해주세요.</Typography>
                                                <Button onClick={handleKakaoChannelAdd} color="secondary" variant='contained' size="small" style={{ color: 'white' }}>채널추가하기</Button>
                                            </div>
                                        }
                                    </div>
                                </div>

                                {/* sms */}
                                <div className={classes.methodWrapper} style={{ marginRight: isHandset ? 0 : '16px', marginBottom: isHandset ? '16px' : 0, backgroundColor: '#e3f2fd' }}>
                                    <Typography variant='body1' style={{ marginBottom: '8px', fontWeight: 'bold' }}>문자(SMS)로 지급받기</Typography>
                                    <Typography variant='body2' style={{ flex: 1 }}>핸드폰번호 인증여부: {me.mobileAuthenticated ? 'O' : 'X'}</Typography>
                                    <div style={{ marginTop: '8px' }}>
                                        {me.mobileAuthenticated ? 
                                            <FormControlLabel
                                            control={<Checkbox checked={useSms} onChange={() => { setUseSms(!useSms) }} name="checkedA" />}
                                            label={`핸드폰번호 (${me.mobile})`}
                                        /> : <div>
                                                <Typography variant='body2' style={{ flex: 1, marginBottom: '4px', color: colors.primary, fontWeight: 'bold' }}>*핸드폰번호를 인증하려면 아래버튼을 누르세요.</Typography>
                                                <Button 
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: '/auth/authenticate-mobile',
                                                            search: `?from=membership_join`,
                                                        }) 
                                                    }} 
                                                    color="secondary" variant='contained' size="small" style={{ color: 'white' }}
                                                >인증하기</Button>
                                            </div>
                                        }
                                    </div>
                                </div>

                                {/* email */}
                                <div className={classes.methodWrapper} style={{ backgroundColor: colors.primaryTint }}>
                                    <Typography variant='body1' style={{ marginBottom: '8px', fontWeight: 'bold' }}>이메일로 지급받기</Typography>
                                    <Typography variant='body2' style={{ flex: 1 }}>이메일 인증여부: {me.emailAuthenticated ? 'O' : 'X'}</Typography>
                                    <div style={{ marginTop: '8px' }}>
                                        {me.emailAuthenticated ? 
                                            <FormControlLabel
                                            control={<Checkbox checked={useEmail} onChange={() => { setUseEmail(!useEmail) }} name="checkedA" />}
                                            label={`이메일 (${me.email})`}
                                        /> : <div>
                                                <Typography variant='body2' style={{ flex: 1, marginBottom: '4px', color: colors.primary, fontWeight: 'bold' }}>*이메일 인증하려면 아래버튼을 누르세요.</Typography>
                                                <Button 
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: '/auth/authenticate-email',
                                                            search: `?from=membership_join`,
                                                        });
                                                    }}
                                                    color="secondary" variant='contained' size="small" style={{ color: 'white' }}
                                                >인증하기</Button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <Divider style={{ margin: '24px 0' }} />
                            <div>
                                {!(useKakao || useEmail || useSms) && <Typography variant='body2' style={{ marginLeft: '8px', marginBottom: '4px', color: colors.textSecondary }}>*지급받을 방법중 하나이상 선택하시면 멤버십 가입이 가능합니다.</Typography>}
                                <CircularButton onClick={handleJoinMembership} variant='contained' color="primary" size="large" disabled={!(useKakao || useEmail || useSms)} buttonStyle={{ color: 'white' }}>멤버십 가입하기</CircularButton>
                            </div>
                            
                            
                        </CardContent>
                    </Card>
                </Box>
        </div>
	);
}

export default MembershipJoinPage;