import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Box, Grid, Typography, makeStyles, Button, Divider, MenuItem, TextField, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'components/NumberFormat';
import DatePicker from "react-datepicker";
import moment from 'moment';
import API from 'api';
import colors from 'appcolors';
import globals from 'appglobals';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Asterisk from 'components/Asterisk';
import Loading from 'components/Loading';
import { MemoryContext } from 'contexts/memory.context';
import { getNextWeekday } from 'services/util.service';
import "react-datepicker/dist/react-datepicker.css";
import { AppContext } from 'contexts/app.context';
import { AuthContext } from 'contexts/auth.context';


const useStyles = makeStyles((theme) => ({
    label: {
        color: colors.textSecondary,
    },
    numberButton: {
        backgroundColor: '#f0f0f0',
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));


// items: 선택가능한 상품 목록
// item: 선택한 상품
const ProductForm = ({ productCode, items, item, setItem }) => {
	const classes = useStyles();
	const history = useHistory();

    // -------------- 정보 받아오기 --------------

    const { products } = useContext(MemoryContext);
    const { showSnackbar } = useContext(AppContext);
    const { me, setLoginRedirectURL } = useContext(AuthContext);

    // 상품
    const product = useMemo(() => {
        if (products && productCode) {
            return products.find(item => item.code === productCode);
        }
    }, [ products, productCode ])
    // end: 상품

    // 다음 배송상품
    // 배송일(yearMonth)에 따라 다음 배송상품을 다른게 선택될 수 있음
    const [ defaultSelected, setDefaultSelected ] = useState(false);    // 최초에 한번만 수행하기 위한 플래그
    useEffect(() => {
        if (product && items && !defaultSelected) {
            const params = {
                yearMonth: moment().format('YYYY-MM'),
                product: product.id
            };
            API.get('/monthly-item/search-monthly-item', { params })
            .then(monthlyItems => {
                // console.log(monthlyItems);
                const defaultItem = items.find(item => {
                    return !!monthlyItems.find(monthlyItem => {
                        return monthlyItem.itemId === item.id;
                    });
                });
                if (defaultItem) setItem(defaultItem);
                setDefaultSelected(true);
            });
        }
    }, [ product, items, defaultSelected, setItem ]);
    // end: 다음 배송상품

    // 가격정보: 구독기간, 상품에 따라 가격정보가 달라짐
    const [ priceInfo, setPriceInfo ] = useState();
    const handleOptionChange = (payOption, deliveryTerm) => {
        if (payOption && deliveryTerm) {
            const params = {
                productCode,
                payOption: payOption.key,
                deliveryTerm: deliveryTerm.key,
            }
            API.get('/product/price-info', { params })
            .then(setPriceInfo);
        }
    }
    // end: 가격정보
    

    // -------------- end: 정보 받아오기 --------------


    const [ loginDialogOpen, setLoginDialogOpen ] = useState(false);
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            setSubmitting(false);
            if (!item) return showSnackbar('처음 배송받을 상품을 선택해 주세요.');
            if (!values.nextDeliveryDate) return showSnackbar('최초 배송일을 선택해주세요.');

            const data = {
                productCode,
                deliveryTerm: values.deliveryTerm.key,
                payOption: values.payOption.key,
                nextDeliveryDate: moment(values.nextDeliveryDate).format('YYYY-MM-DD'), 
                itemId: item.id
            };
            localStorage.setItem('order', JSON.stringify(data));

            if (!me) {
                setLoginDialogOpen(true);
            }
            else {
                history.push('/order/form');
            }
        }
        catch(error) {
            console.error(error);
            showSnackbar(error.message);
        }
    }


	return (
        <>
            {product && items ? <Formik
                initialValues={{
                    payOption: null,
                    deliveryTerm: null,
                    nextDeliveryDate: getNextWeekday().toDate(),
                }}
                validationSchema={Yup.object({
                    payOption: Yup.object().nullable().required('필수입력입니다.'),
                    deliveryTerm: Yup.object().nullable().required('필수입력입니다.'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => <>
                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <Box pb={2}>
                            <Typography variant="body1" color="textSecondary">{product.summary}</Typography>
                            <Typography variant="h5" style={{ fontWeight: 'bold', marginTop: '8px' }}>{product.name}</Typography>
                            <NumberFormat value={product.price} render={value => <Typography variant="h6" style={{ marginTop: '16px', fontWeight: 'bold' }}>{value}원 / 월</Typography>} />
                        </Box>
                        
                        <Divider />

                        <Box py={2}>
                            <Typography variant="body2" style={{ whiteSpace: 'pre-line'}}>
                                {'정기구독 전상품, 무료배송!\n 서울/경기/인천 일부지역은 새벽배송으로 신선하게 배송됩니다.'}
                            </Typography>
                        </Box>
                        
                        <Divider />

                        {/* delivery term */}
                        <Box py={2}>
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <Typography className={classes.label}>배송주기<Asterisk /></Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        select
                                        size="small"
                                        style={{ width: '100%' }}
                                        variant="outlined"
                                        label={'선택: 배송주기'}
                                        required={true}
                                        onChange={event => {
                                            const option = event.target.value;
                                            setFieldValue('deliveryTerm', option);
                                            handleOptionChange(values.payOption, option);
                                        }}
                                        onBlur={handleBlur('deliveryTerm')}
                                        error={!!(touched.deliveryTerm && errors.deliveryTerm)}
                                        value={values.deliveryTerm?.name}
                                    >
                                        {globals.deliveryTerms.map(option => <MenuItem key={option.key} value={option}>{option.name}</MenuItem>)}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Box>

                        {/* pay option */}
                        <Box py={2}>
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <Typography className={classes.label}>결제구분<Asterisk /></Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        select
                                        size="small"
                                        style={{ width: '100%' }}
                                        variant="outlined"
                                        label={'선택: 일시불 / 정기결제'}
                                        required={true}
                                        onChange={event => {
                                            const option = event.target.value;
                                            setFieldValue('payOption', option);
                                            handleOptionChange(option, values.deliveryTerm);
                                        }}
                                        onBlur={handleBlur('payOption')}
                                        error={!!(touched.payOption && errors.payOption)}
                                        value={values.payOption?.name}
                                    >
                                        {globals.payOptions.map(option => <MenuItem key={option.key} value={option}>{option.name}</MenuItem>)}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Box>

                        {/* 선택상품 */}
                        <Grid container alignItems="center" style={{ marginTop: '16px' }}>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>처음 배송받을 상품 선택</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    select
                                    size="small"
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    label={'상품 선택'}
                                    required={true}
                                    onChange={event => { setItem(event.target.value); }}
                                    value={item?.name}
                                >
                                    {items.map((element) => (
                                        <MenuItem key={element.id} value={element}>{element.name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        
                        {/* 최초 배송일 */}
                        <Grid container alignItems="center" style={{ marginTop: '24px'}}>
                            <Grid item xs={4}>
                                <Typography className={classes.label}>최초 배송일</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <DatePicker selected={values.nextDeliveryDate} onChange={(date) => setFieldValue('nextDeliveryDate', date)} />
                            </Grid>
                        </Grid>
                        
                        {/* 가격정보 */}
                        <div style={{ marginTop: '16px', padding: '16px', backgroundColor: colors.gray100 }}>
                            <Typography className={classes.label} style={{ marginBottom: '16px' }}>가격정보</Typography>
                            {priceInfo ? <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="body1">결제구분</Typography>
                                    <Typography variant="body1">{priceInfo.payOption === 'onetime' ? '일시불' : '정기결제'}</Typography>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <Typography variant="body1">가격</Typography>
                                    {priceInfo.payOption === 'onetime' ? 
                                        <NumberFormat value={priceInfo.price} render={value => <Typography variant="body1" style={{ fontWeight: 'bold' }}>{value}원</Typography>} /> : 
                                        <NumberFormat value={priceInfo.price} render={value => <Typography variant="body1" style={{ fontWeight: 'bold' }}>{value}원 / {priceInfo.deliveryTerm}월</Typography>} />
                                    }
                                </div>
                                {priceInfo.discount > 0 && <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                                    <Typography variant="body1">할인</Typography>
                                    <NumberFormat value={priceInfo.payOption === 'onetime' ? priceInfo.discount * priceInfo.numberOfDelivery : priceInfo.discount } render={value => <Typography variant="body2" color="textSecondary">{value} 원 ({priceInfo.discountRate}%)</Typography>} />
                                </div>}
                                <div style={{ marginTop: '8px'}}>
                                    {priceInfo.payOption === 'onetime' ?
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>최초배송일을 시작으로 {priceInfo.deliveryTerm}달 긴격으로 총 {priceInfo.numberOfDelivery}회 배송받습니다.</Typography> :
                                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>최초배송일을 시작으로 {priceInfo.deliveryTerm}달 긴격으로 배송받습니다.</Typography>
                                    }
                                </div>
                            </> : <>
                                <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>배송주기 및 결제구분을 선택해주세요.</Typography>
                            </>}
                        </div>

                        <div style={{ alignSelf: 'flex-end', marginTop: '16px', height: '20px', borderRadius: '10px', paddingLeft: '8px', paddingRight: '8px', backgroundColor: colors.gray200 }}>
                            <Typography variant="caption">무료배송</Typography>
                        </div>

                        <div style={{ display: 'flex', marginTop: '8px', alignItems: 'baseline', justifyContent: 'flex-end' }}>
                            <Typography variant="body1">총 주문금액</Typography>
                            <NumberFormat value={priceInfo ? priceInfo.price : '-'} render={value => <Typography variant="h5" style={{ marginLeft: '16px', fontWeight: 'bold' }}>{value}원</Typography>} /> 
                        </div>

                        <div style={{ display: 'flex', marginTop: '24px' }}>
                            <Button variant="contained" color="primary" style={{ flex: 1, color: 'white' }} onClick={handleSubmit}>구독 신청</Button>
                        </div>
                    </div>
                </>}
                
            </Formik> : <Loading />}
            <Dialog onClose={() => setLoginDialogOpen(false)} open={loginDialogOpen}>
                <DialogTitle>{'선택옵션을 저장했습니다.'}<br/>{'로그인이 필요한 기능입니다. 로그인 화면으로 이동하시겠습니까?'}</DialogTitle>
                <DialogActions>
                    <Button color="primary" autoFocus onClick={() => {
                        setLoginDialogOpen(false);
                        setLoginRedirectURL('/order/form');
                        history.push('/auth/signin');
                    }}>예</Button>
                    <Button onClick={() => { setLoginDialogOpen(false)}} >아니오</Button>
                </DialogActions>
            </Dialog>
        </>
	);
}

export default ProductForm;