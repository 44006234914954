import React, { useState, useContext, useMemo } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Card, CardContent, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import API from 'api';
import colors from 'appcolors';
import Divider from 'components/Divider';
import Button from 'components/Button';
import TextField from 'components/TextField';
import { Formik } from 'formik';
import { AuthContext } from 'contexts/auth.context';
import { AppContext } from 'contexts/app.context';
import MypageMenu from '../MypageMenu';


const useStyles = makeStyles((theme) => ({
    section: {
        padding: '16px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        margin: '16px 0 8px 0',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
    subButton: {
        marginTop: '16px',
        fontSize: '14px',
    },
}));

const MyinfoPage = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { me, fetchMyinfo, clearAuthInfo } = useContext(AuthContext);
    const { showSnackbar } = useContext(AppContext);

    const handleLogout = () => {
        API.post('/auth/signout')
        .then(() => {
            clearAuthInfo();
            window.location.href = '/';
        })
    }

    // 비밀번호 변경
    const [ passwordChangeDialogOpen, setPasswordChangeDialogOpen ] = useState(false);
    // end: 비밀번호 변경
    
    // 회원탈퇴
    const [ leaveAppDialogOpen ,setLeaveAppDialogOpen ] = useState(false);
    const handleLeaveApp = () => {
        API.delete('/auth/leave-app')
        .then(() => {
            showSnackbar('회원탈퇴 했습니다.');
            clearAuthInfo();
            window.location.href = '/';
        })
        .finally(() => { setLeaveAppDialogOpen(false); })
    }
    // end: 회원탈퇴

    // membership
    const haveMembership = useMemo(() => {
        return !!me?.auths.find(auth => auth.code === 'membership');
    }, [ me ]);
    
    const handleJoinMembership = () => {
        history.push('/membership/join');
    }
    
    const [ leaveMembershipModalOpen, setLeaveMembershipModalOpen ] = useState(false);
    const handleLeaveMembership = () => {
        API.delete('/membership/leave-membership')
        .then(() => {
            showSnackbar('멤버십을 탈퇴했습니다.');
            fetchMyinfo();
        })
        .finally(() => { setLeaveMembershipModalOpen(false); })
    }
    // end: membership

	return <>
		{me && <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <MypageMenu densed={true} />

            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>계정</Typography>
                        <Divider />

                        <Formik
                            initialValues={{
                                nickname: me.nickname,
                                gender: me.gender || '',
                                shoesSize: me.shoesSize || '',
                            }}
                            validationSchema={yup.object({
                                nickname: yup.string().required('필수입력입니다.'),
                                gender: yup.string().typeError('잘못된 입력입니다.').oneOf(['male', 'female']),
                                shoesSize: yup.number().typeError('숫자로 입력해주세요.').min(200, '신발사이즈는 200보다 커야 합니다.').max(300, '신발사이즈는 300보다 작아야 합니다.'),
                            })}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                const data = {
                                    nickname: values.nickname,
                                    gender: values.gender || null,
                                    shoesSize: values.shoesSize || null,
                                };

                                API.put('/auth/myinfo', data)
                                .then(() => {
                                    setSubmitting(false);
                                    showSnackbar('업데이트 했습니다.');
                                    fetchMyinfo();
                                })
                                .catch(error => {
                                    setSubmitting(false);
                                });
                            }}
                        >
                            {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    {/* personal info */}
                                    <div className={classes.section}>
                                        <Typography variant="subtitle1">내정보</Typography>

                                        <Typography variant="subtitle2" className={classes.label}>이메일</Typography>
                                        <TextField
                                            variant="outlined"
                                            className={classes.input}
                                            size="small"
                                            disabled={true}
                                            value={me.email || ''}
                                            placeholder='현재 이메일이 지정되지 않았습니다.'
                                        />

                                        <Typography variant="subtitle2" className={classes.label}>닉네임</Typography>
                                        <TextField
                                            variant="outlined"
                                            className={classes.input}
                                            size="small"
                                            onChange={handleChange('nickname')}
                                            onBlur={handleBlur('nickname')}
                                            helperText={(touched.nickname && errors.nickname) || ' '}
                                            error={!!(touched.nickname && errors.nickname)}
                                            value={values.nickname}
                                        />

                                        <Typography variant="subtitle2" className={classes.label}>성별</Typography>
                                        <TextField
                                            select
                                            variant="outlined"
                                            className={classes.input}
                                            size="small"
                                            onChange={handleChange('gender')}
                                            onBlur={handleBlur('gender')}
                                            helperText={(touched.gender && errors.gender) || ' '}
                                            error={!!(touched.gender && errors.gender)}
                                            value={values.gender}
                                        >
                                            <MenuItem value={'male'}>남자</MenuItem>
                                            <MenuItem value={'female'}>여자</MenuItem>
                                        </TextField>

                                        <Typography variant="subtitle2" className={classes.label}>신발 사이즈</Typography>
                                        <TextField
                                            variant="outlined"
                                            className={classes.input}
                                            size="small"
                                            onChange={handleChange('shoesSize')}
                                            onBlur={handleBlur('shoesSize')}
                                            helperText={(touched.shoesSize && errors.shoesSize) || ' '}
                                            error={!!(touched.shoesSize && errors.shoesSize)}
                                            value={values.shoesSize}
                                        />
                                        <Button type="submit" size="small" variant="outlined" color="primary" className={classes.subButton} style={{ color: colors.primary }}>업데이트</Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                        <Divider />
                        
                        {/* password */}
                        {!!me.password && <>
                            <div className={classes.section}>
                                <Typography variant="subtitle1">비밀번호</Typography>
                                <Typography variant="subtitle2" className={classes.guideText}>비밀번호를 재설정하려면 버튼을 누르세요.</Typography>
                                <Button variant="outlined" size="small" color="primary" className={classes.subButton} style={{ color: colors.primary }} onClick={() => { setPasswordChangeDialogOpen(true) }}>비밀번호 재설정</Button>
                            </div>
                            <Divider />
                        </>}

                        {/* membership */}
                        <div className={classes.section}>
                            <Typography variant="subtitle1">MNX15 Premium Membership</Typography>
                            {haveMembership ? <>
                                <Typography variant="subtitle2" className={classes.guideText}>{'귀하는 멤버십에 가입되어 있습니다.'}</Typography>
                                <Button variant="outlined" size="small" className={classes.subButton} style={{ borderColor: colors.error, color: colors.error }} onClick={() => { setLeaveMembershipModalOpen(true); }}>멤버십 탈퇴</Button>
                            </> : <>
                                <Typography variant="subtitle2" className={classes.guideText}>{'멤버십에 가입하시면 매월 1회 15% 할인을 받으실 수 있습니다.'}</Typography>
                                <Button variant="outlined" size="small" className={classes.subButton} style={{ borderColor: colors.info, color: colors.info }} onClick={handleJoinMembership}>멤버십 가입</Button>
                            </>}
                        </div>
                        <Divider />

                        {/* logout */}
                        <div className={classes.section}>
                            <Typography variant="subtitle1">로그아웃</Typography>
                            <Typography variant="subtitle2" className={classes.guideText}>브라우저내 인증관련 앱데이터를 삭제합니다. 계정에 접근하기 위해서 다시 로그인이 필요합니다.</Typography>
                            <Button variant="outlined" size="small" className={classes.subButton} style={{ borderColor: colors.warn, color: colors.warn }} onClick={handleLogout}>로그아웃</Button>
                        </div>
                        <Divider />
                        
                        {/* leave app */}
                        <div className={classes.section}>
                            <Typography variant="subtitle1">회원탈퇴</Typography>
                            <Typography variant="subtitle2" className={classes.guideText}>앱을 영구적으로 탈퇴합니다. 나와 관련된 모든 데이터는 삭제됩니다. 또한 구독중인 서비스는 중단됩니다.</Typography>
                            <Button variant="outlined" size="small" className={classes.subButton} style={{ borderColor: colors.error, color: colors.error }} onClick={() => { setLeaveAppDialogOpen(true) }}>회원탈퇴</Button>    
                        </div>
                        
                    </CardContent>
                </Card>
            </Box>
		</div>}
        
        {/* 비밀번호 변경 다이얼로그 */}
        <Formik
            initialValues={{
                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            }}
            validationSchema={yup.object({
                oldPassword: yup.string().required('필수입력입니다.'),
                newPassword: yup.string().required('필수입력입니다.'),
                newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), null], '새 비밀번호가 일치하지 않습니다.'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                API.put('/auth/password', values)
                .then(() => {
                    setSubmitting(false);
                    showSnackbar('비밀번호를 변경했습니다.');
                    setPasswordChangeDialogOpen(false);
                })
                .catch(error => {
                    setSubmitting(false);
                });
            }}
        >
            {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <Dialog open={passwordChangeDialogOpen} onClose={() => { setPasswordChangeDialogOpen(false) }}>
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>비밀번호 변경</DialogTitle>
                        <DialogContent>
                            
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <DialogContentText>
                                    비밀번호 변경을 위해 다음의 양식을 작성해주세요.
                                </DialogContentText>
                                <Typography variant="subtitle2" className={classes.label}>기존 비밀번호*</Typography>
                                <TextField
                                    variant="outlined"
                                    className={classes.input}
                                    size="small"
                                    type="password"
                                    required={true}
                                    onChange={handleChange('oldPassword')}
                                    onBlur={handleBlur('oldPassword')}
                                    helperText={(touched.oldPassword && errors.oldPassword) || '기존 비밀번호를 입력하세요.'}
                                    error={!!(touched.oldPassword && errors.oldPassword)}
                                    value={values.oldPassword}
                                />

                                <Typography variant="subtitle2" className={classes.label}>새 비밀번호*</Typography>
                                <TextField
                                    variant="outlined"
                                    className={classes.input}
                                    size="small"
                                    required={true}
                                    type="password"
                                    onChange={handleChange('newPassword')}
                                    onBlur={handleBlur('newPassword')}
                                    helperText={(touched.newPassword && errors.newPassword) || '새 비밀번호를 입력하세요.'}
                                    error={!!(touched.newPassword && errors.newPassword)}
                                    value={values.newPassword}
                                />

                                <Typography variant="subtitle2" className={classes.label}>새 비밀번호 확인*</Typography>
                                <TextField
                                    variant="outlined"
                                    className={classes.input}
                                    size="small"
                                    required={true}
                                    type="password"
                                    onChange={handleChange('newPasswordConfirm')}
                                    onBlur={handleBlur('newPasswordConfirm')}
                                    helperText={(touched.newPasswordConfirm && errors.newPasswordConfirm) || '새 비밀번호를 재입력하세요.'}
                                    error={!!(touched.newPasswordConfirm && errors.newPasswordConfirm)}
                                    value={values.newPasswordConfirm}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button type="button" style={{ color: colors.gray500 }} onClick={() => { setPasswordChangeDialogOpen(false) }}>취소</Button>
                            <Button type="submit" style={{ color: colors.primary }}>확인</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        </Formik>
        
        
        {/* 회원탈퇴 확인 다이얼로그 */}
        <Dialog
            open={leaveAppDialogOpen}
            onClose={() => { setLeaveAppDialogOpen(false) }}
        >
            <DialogTitle>{'회원탈퇴'}</DialogTitle>
            <DialogContent>
                <DialogContentText>{'회원탈퇴시 사용자 정보가 삭제되며 복구가 되지 않습니다.\n 계속 진행하시겠습니까?'}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ color: colors.error }} onClick={handleLeaveApp} color="primary">예</Button>
                <Button style={{ color: colors.primary }} onClick={() => { setLeaveAppDialogOpen(false)}} color="primary" autoFocus>아니오</Button>
            </DialogActions>
        </Dialog>

        {/* 멤버십탈퇴 확인 다이얼로그 */}
        <Dialog
            open={leaveMembershipModalOpen}
            onClose={() => { setLeaveMembershipModalOpen(false) }}
        >
            <DialogTitle>{'멤버십 탈퇴'}</DialogTitle>
            <DialogContent>
                <DialogContentText>{'정말 멤버십을 탈퇴하시겠습니까?'}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ color: colors.error }} onClick={handleLeaveMembership} color="primary">예</Button>
                <Button style={{ color: colors.primary }} onClick={() => { setLeaveMembershipModalOpen(false)}} color="primary" autoFocus>아니오</Button>
            </DialogActions>
        </Dialog>
	</>
}

export default MyinfoPage;