import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Card, CardContent, Divider, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import API from 'api';
import colors from 'appcolors';
import { useParams } from 'react-router-dom';
import useApi from 'hooks/useApi';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { mobile_pipe, number_format_pipe, stuff_summary_pipe } from 'pipes';
import { BagContext } from 'contexts/bag.context';
import { AuthContext } from 'contexts/auth.context';
import { MemoryContext } from 'contexts/memory.context';
import moment from 'moment';


// 이 페이지에서 노출될 정보:
// 받는사람: 이름 / 전화번호
// 받는주소 (변경할 수 있도록 링크)
// 배송요청사항 (변경할 수 있도록 링크)
// 총 상품가격
// 배송비
// 총 결제금액
// 주문 상세보기, 쇼핑 계속하기 버튼

const useStyles = makeStyles((theme) => ({
	row: { display: 'flex', alignItems: 'center', marginBottom: '8px' },
	label: { width: '96px', fontSize: 14, color: colors.textPrimary },
	value: { fontSize: 14, color: colors.textPrimary },
}));

const OrderSuccessPage = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

	const { clearStuff } = useContext(BagContext);
	const { me } = useContext(AuthContext);
	const { setAvailableCouponsTrigger } = useContext(MemoryContext);
	const { merchantId } = useParams();
	
	const [ order, setOrder ] = useState();
	useApi(() => {
		if (merchantId) {
			const params = { merchantId };
			API.get('/order/order', { params })
			.then(setOrder);
			
			setAvailableCouponsTrigger(new Date().getTime());
		}
	}, [ merchantId ]);

	useEffect(() => {
		setTimeout(() => {
			clearStuff(); 	// iamport hook 보다 늦게 처리되어야 하므로 시간차를 둠 (2 초로 해봤는데, iamport 에서 더 늦게 hook 이 오는 것을 확인함)
		}, 5000);
	}, []);

	const handleOrderDetailClick = () => {
		history.push('/mypage/myorders');
	}

	const handleGoonShoppingClick = () => {
		window.location.href = '/items';
	}

	if (!order) return (<Loading />);

	return (
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
				<Card>
                    <CardContent style={{ padding: '32px 16px' }}>
						<Box style={{ textAlign: 'center', marginBottom: '32px' }}>
							{order.method === 'transfer'
								? <Typography variant='h3'>입금이 완료되면 배송진행을 도와드립니다!</Typography>
								: <Typography variant='h3'><span style={{ fontWeight: 'bold' }}>주문이 완료</span>되었습니다!</Typography>
							}
						</Box>

						<Box>
							{me && <div style={{ marginBottom: '24px', backgroundColor: colors.primaryDark, padding: '4px 16px' }}>
								<Link variant="body1" component={'a'} href="/app/mypage/mycoupons" style={{ color: 'white', textDecoration: 'none' }}>땡큐쿠폰(5% 할인)이 지급되었습니다.</Link>
							</div>}

							{order.method === 'transfer' && <Box className={classes.row}>
								<Typography className={classes.label}>입금계좌</Typography>
								<Typography className={classes.value} style={{ backgroundColor: colors.primaryTint }}>{'국민 985601-00-005466 명문스포츠'}</Typography>
							</Box>}
							{order.method === 'transfer' && <Box className={classes.row}>
								<Typography className={classes.label}>이체만기일</Typography>
								<Typography className={classes.value} style={{ backgroundColor: colors.primaryTint }}>{moment().add('7', 'days').format('YYYY-MM-DD')}</Typography>
							</Box>}
							<Box className={classes.row}>
								<Typography className={classes.label}>주문번호</Typography>
								<Typography className={classes.value}>{order.merchantId}</Typography>
							</Box>
							<Box className={classes.row}>
								<Typography className={classes.label}>상품</Typography>
								<Typography className={classes.value}>{stuff_summary_pipe(order.stuff)}</Typography>
							</Box>
							<Box className={classes.row}>
								<Typography className={classes.label}>받는사람</Typography>
								<Typography className={classes.value}>{`${order.destination.name} / ${mobile_pipe(order.destination.mobile)}`}</Typography>
							</Box>
							<Box className={classes.row}>
								<Typography className={classes.label}>받는주소</Typography>
								<Typography className={classes.value}>{`${order.destination.address}, ${order.destination.addressDetail}`}</Typography>
							</Box>
							<Box className={classes.row}>
								<Typography className={classes.label}>배송요청사항</Typography>
								<Typography className={classes.value}>{order.destination.memo || '-'}</Typography>
							</Box>
							<Divider style={{ margin: '16px 0' }} />

							<Box className={classes.row}>
								<Typography className={classes.label}>총 상품가격</Typography>
								<Typography className={classes.value} style={{ fontWeight: 'bold' }}>{number_format_pipe(order.price)} 원</Typography>
							</Box>
							<Box className={classes.row}>
								<Typography className={classes.label}>배송비</Typography>
								<Typography className={classes.value} style={{ fontWeight: 'bold' }}>{number_format_pipe(order.deliveryPrice)} 원</Typography>
							</Box>
							<Divider style={{ margin: '16px 0' }} />

							<Box className={classes.row}>
								<Typography variant='h4' className={classes.label}>총 결제금액</Typography>
								<Typography variant='h4' className={classes.value} style={{ color: colors.money, fontWeight: 'bold' }}>{number_format_pipe(order.price + order.deliveryPrice)} 원</Typography>
							</Box>
						</Box>
					</CardContent>
				</Card>

				<Box display='flex' marginTop={2}>
					{me && <Button variant='contained' size='large' style={{ flex: isHandset ? 1 : 'none', marginRight: '8px' }} onClick={handleOrderDetailClick}>내주문목록</Button>}
					<Button variant='contained' color="primary" size="large" style={{ color: 'white', flex: isHandset ? 1 : 'none' }} onClick={handleGoonShoppingClick}>쇼핑 계속하기</Button>
				</Box>
			</Box>
		</div>
	);
}

export default OrderSuccessPage;