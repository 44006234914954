import React, { useMemo } from 'react';
import useQuery from 'hooks/useQuery';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MobileAuthentication from 'views/signup/MobileAuthentication';


const MobileAuthenticationPage = () => {
    const history = useHistory();
    const query = useQuery(); 
    const from = useMemo(() => {
        if (query) {
            return query.get('from');
        }
    }, [ query ]);

    const handleComplete = () => {
        if (from === 'membership_join') history.push('/membership/join');
    }

	return (
        <div style={{ flex: 1, display: 'flex', padding: '40px 16px', justifyContent: 'center' }}>
            <Box width={{ xs: '100%', sm: '500px', md: '600px' }} display="flex" flexDirection="column">
                <MobileAuthentication handleComplete={handleComplete} />
            </Box>
		</div>
	);
}

export default MobileAuthenticationPage;