import React, { useContext, useMemo } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import colors from 'appcolors';
import { AppContext } from 'contexts/app.context';
import { datetimePipe } from 'pipes';
import { Rating } from '@material-ui/lab';


const ReviewItem = ({ review }) => {
    const { showAlert } = useContext(AppContext);
    const history = useHistory();
    const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const image = useMemo(() => {
        return review.bucket.stuff.item.fileInfos[0]?.downloadURL;
    }, [ review ]);

    const name = useMemo(() => {
        return review.bucket.stuff.item.name;
    }, [ review ]);

    const handleModify = () => {
        history.push(`/mypage/review/form/${review.bucket.id}?reviewId=${review.id}`);
    }

    return (
        <Box border={'1px solid #dddddd'} display="flex" marginBottom={'8px'} >
            <img src={image} alt={review.bucket.stuff.item.name} style={{ height: isHandset ? '100px' : '180px' }} />
            <Box display={'flex'} flexDirection='column' marginX={2} paddingY={1} flex={1}>
                <Typography variant='body2' style={{ color: colors.textSecondary, marginBottom: '4px' }}>{datetimePipe(review.createdAt)}</Typography>
                <Typography variant='h6'>{name}</Typography>
                
                <Typography variant='body1' style={{ marginTop: '16px', flex: 1, whiteSpace: 'pre-line' }}>{review.content}</Typography>
                
                {/* 별점 */}
                <Rating
                    value={review.stars}
                    readOnly
                />

                <Box display={'flex'} marginTop="16px" justifyContent={'space-between'}>
                    <Button color='primary' variant='contained' size='small' disableElevation style={{ color: 'white', borderRadius: 0 }} onClick={handleModify}>수정</Button>
                </Box>
            </Box>
        </Box>
    );
}

export default ReviewItem;