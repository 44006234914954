import moment from 'moment';

// 오늘 이후의 평일을 가져온다.
const getNextWeekday = () => {
    const date = moment();
    const day = date.day();

    // 금요일 또는 토요일이면 다음 월요일, 그렇지 않으면 내일 
    if (day === 5 || day === 6) {
        date.day(8);
    }
    else {
        date.day(day + 1);
    }
    return date;
}


export { getNextWeekday };

// exports.getNextWeekday = () => {
//     const date = moment();
//     const day = date.day();

//     // 금요일 또는 토요일이면 다음 월요일, 그렇지 않으면 내일 
//     if (day == 5 || day == 6) {
//         date.day(1);
//     }
//     else {
//         date.day(day + 1);
//     }
//     return date;
// }