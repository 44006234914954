import React, { useState, useEffect } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Button, Card, CardContent } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import API from 'api';
import colors from 'appcolors';
import Divider from 'components/Divider';
import useOrderFormStyles from 'views/order/form/useOrderFormStyles';
import BucketInfo from './BucketInfo';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import OrderInfo from './OrderInfo';


const useStyles = makeStyles((theme) => ({

}));

const BucketPage = () => {
	const classes = useOrderFormStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { bucketId } = useParams();

    const [ bucket, setBucket ] = useState();
    useEffect(() => {
        API.get(`/bucket/bucket/${bucketId}`)
        .then(setBucket);
    }, [bucketId]);

    if (!bucket) return <Loading />

	return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column">
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>상품배송정보</Typography>
                        <Divider />

                        <BucketInfo bucket={bucket} />
                    </CardContent>
                </Card>

                <Card style={{ marginTop: '16px' }}>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>주문정보</Typography>
                        <Divider />

                        <OrderInfo bucket={bucket} />
                    </CardContent>
                </Card>
                
                <Box style={{ margin: isHandset ? '16px' : '16px 0' }} display={'flex'}>
                    <Button component={Link} variant='contained' color="primary" disableElevation style={{ borderRadius: 0, color: 'white' }} to={'/mypage/myorders'}>목록</Button>
                </Box>
            </Box>
        </div>
	);
}

export default BucketPage;