import React from 'react';
import ReactNumberFormat from 'react-number-format';

const NumberFormat = ({value, render}) => {
    return (
        <ReactNumberFormat
            value={value}
            displayType={'text'}
            thousandSeparator={true}							
            renderText={render}
        />
    );
}

export default NumberFormat;