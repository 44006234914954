import React, { useState, useContext, Fragment, useMemo } from 'react';
import { Typography, Link, Card, CardContent, Button } from '@material-ui/core';
import API from 'api';
import colors from 'appcolors';
import globals from 'appglobals';
import Divider from 'components/Divider';
import TextField from 'components/TextField';
import { AppContext } from 'contexts/app.context';
import { AuthContext } from 'contexts/auth.context';
import { datetimePipe } from 'pipes';


const Ticket = ({ ticket, loadData, categories }) => {

    const { me } = useContext(AuthContext);
    const { showSnackbar, showDialog } = useContext(AppContext);

    const [ content, setContent ] = useState('');
    const [ files, setFiles ] = useState([]);
    const onSubmit = async (ticketId) => {
        if (!content) return showSnackbar('문의내용을 입력하세요.');

        const data = new FormData();
        data.append('ticketId', ticketId);
        data.append('content', content);

        for (let file of files) {
            data.append('files', file);
        }
        
        await API.post('/ticket/letter', data);
        
        showSnackbar('글을 작성했습니다.');
        setContent('');
        setFiles([]);
        loadData();
    }

    const onCloseClick = (ticketId) => {
        const dialogData = {
            title: '문의 종료',
            message: '문의를 종료하시겠습니까?',
            first: {
                onClick: () => {
                    API.delete('/ticket/close-ticket/' + ticketId)
                    .then(() => {
                        showSnackbar('종료했습니다.');
                        loadData();
                    });
                }
            },
            second: true,
        }
        showDialog(dialogData);
    }

    const categoryName = useMemo(() => {
        let name = '';
        Object.entries(categories).forEach(([ key, value ]) => {
            if (key === ticket.category) name = value;
        })
        return name;
    }, [ categories, ticket ]);

	return (
        <Card style={{ flex: 1, marginTop: '16px' }}>
            <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3px' }}>
                    <Typography variant="h5" style={{ fontWeight: 'bold', color: colors.primary }}>{categoryName}</Typography>
                    <Typography variant="body1" style={{ color: colors.secondaryDark }}>{globals.ticketStates.find(item => item.key === ticket.state).name}</Typography>
                </div>

                <Divider />
                {ticket.letters.map(letter => <div key={letter.id} style={{ margin: '8px 0', backgroundColor: letter.userId === me.id ? '#e8f5e9' : '#e1f5fe', padding: '8px 12px', borderRadius: '4px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                            <Typography variant="body2" style={{ color: colors.textHint }}>{letter.userId === me.id ? me.nickname : 'mnx15 지원팀'}</Typography>
                            <Typography variant="body2" style={{ color: colors.textHint }}>{datetimePipe(letter.createdAt)}</Typography>
                        </div>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-wrap'}}>{letter.content}</Typography>

                        {letter.fileInfos.length > 0 && <div style={{ marginTop: '16px' }}>
                            <Typography variant="subtitle2" style={{ color: colors.textSecondary }}>첨부파일</Typography>
                            {letter.fileInfos.map(fileInfo => <Fragment key={fileInfo.id}>
                                <Link style={{ fontSize: '12px', marginRight: '8px' }} href={fileInfo.downloadURL} target="_blank">{fileInfo.meta?.originalname}</Link>
                            </Fragment>)}
                        </div>}
                    </div>
                </div>)}

                {ticket.state === 'opened' && ticket.letters.length > 0 && ticket.letters[ticket.letters.length - 1].userId === me.id && <>
                    <div style={{ margin: '8px 0', backgroundColor: '#e1f5fe', padding: '8px 12px', borderRadius: '4px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                                <Typography variant="body2" style={{ color: colors.textHint }}>{'MNX15 지원팀'}</Typography>
                            </div>
                            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap', color: colors.textSecondary }}>{'근무시간 24시간 이내에 답변드리겠습니다.'}</Typography>
                        </div>
                    </div>
                </>}

                {ticket.state === 'opened' && <>
                    <Divider />
                    <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: '8px' }}>
                        <TextField
                            style={{ flex: 1, marginRight: '8px' }}
                            multiline
                            rows={4}
                            variant="outlined"
                            required={true}
                            placeholder={'답글 및 이 이슈와 관련된 추가문의사항을 입력하세요.'}
                            onChange={(event) => setContent(event.target.value)}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: '8px' }}>
                                {files.length === 0 ? <>
                                    <input multiple type="file" style={{ display: 'none' }} id={`attachFile_${ticket.id}`} onChange={event => { setFiles(event.target.files) }} />
                                    <label htmlFor={`attachFile_${ticket.id}`}>
                                        <Button type="button" component="span" size="small" variant="outlined" disableElevation style={{ borderRadius: 0, color: colors.textSecondary }}>파일첨부</Button>
                                    </label>
                                </> : <>
                                    <Button type="button" component="span" size="small" variant="outlined" disableElevation style={{ borderRadius: 0, color: colors.textSecondary }} onClick={() => { setFiles([]); }}>{`첨부삭제(${files.length})`}</Button>
                                </>}
                            </div>
                            <Button type="button" size="small" variant="outlined" color="primary" disableElevation style={{ color: colors.primary, borderRadius: 0 }} onClick={() => onSubmit(ticket.id)}>글쓰기</Button>
                        </div>
                        
                    </div>

                    <Button type="button" size="small" variant="outlined" disableElevation style={{ marginTop: '8px', color: colors.error, borderColor: colors.error, borderRadius: 0 }} onClick={() => onCloseClick(ticket.id)}>문의 종료</Button>
                </>}
            </CardContent>
        </Card>
	);
}

export default Ticket;