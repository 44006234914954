import React, { useMemo, useState } from 'react';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Link as RouteLink } from 'react-router-dom';
import colors from 'appcolors';
import useOrderFormStyles from 'views/order/form/useOrderFormStyles';
import { bucket_state_pipe, claimStatePipe, datetimePipe } from 'pipes';
import Nodata from 'components/Nodata';
import Guide from 'components/Guide';
import DeliveryTracker from './DeliveryTracker';


const BucketInfo = ({ bucket }) => {
	const classes = useOrderFormStyles();

    const [ trackerDialogOpen, setTrackerDialogOpen ] = useState(false);
    const handleChaseDelivery = () => {
        setTrackerDialogOpen(true);
    }

    const itemSummary = useMemo(() => {
        const item = bucket?.stuff?.item;
        if (item) {
            return `${item.name}: ${bucket.stuff.count}개`;
        }
    }, [ bucket ]);

    const optionSummary = useMemo(() => {
        const options = bucket.stuff?.proxy?.proxyOptions;
        if (options?.length > 0) {
            return options.map(el => el.option.name).join(',');
        }
    }, [ bucket ]);

	return (
		<>
            <div className={classes.section} style={{ paddingBottom: '8px' }}>
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>배송정보</Typography>
                
                <div className={classes.table}>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>상품</Typography>
                        </div>
                        <div className={classes.content}>
                            <RouteLink to={`/item/${bucket.stuff?.item?.code}`}><Typography variant="body1" style={{ }}>{itemSummary}</Typography></RouteLink>
                        </div>
                    </div>
                    {optionSummary && <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>옵션</Typography>
                        </div>
                        <div className={classes.content}>
                        <Typography variant="body1">{optionSummary}</Typography>
                        </div>
                    </div>}
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>상태</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{bucket_state_pipe(bucket.state)}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>송장번호</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{bucket.invoiceNumber || '-'}</Typography>
                        </div>
                    </div>
                </div>
            </div>
            {!!bucket.deliveryService && !!bucket.invoiceNumber && <Button variant='outlined' color='primary' size="small" style={{ marginBottom: '8px' }} onClick={handleChaseDelivery}>배송추적</Button>}

            <div className={classes.section}>
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>타임라인</Typography>

                <div className={classes.table}>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>주문일시</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{datetimePipe(bucket.createdAt)}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>주문확인일시</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{datetimePipe(bucket.readyAt)}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>배송일시</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{datetimePipe(bucket.sendedAt)}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>배송완료일시</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{datetimePipe(bucket.receivedAt)}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>구매완료일시</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{datetimePipe(bucket.doneAt)}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>취소완료일시</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{datetimePipe(bucket.canceledAt)}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>반품완료일시</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{datetimePipe(bucket.returnedAt)}</Typography>
                        </div>
                    </div>
                </div>
                <Guide>{'배송이 시작된 후 15일에 지나면 배송완료 처리됩니다.\n배송완료 후 10일 지나면 구매완료 처리됩니다.'}</Guide>
            </div>

            <div className={classes.section}>
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>클레임</Typography>

                {bucket.claims.length > 0 ? <>
                    <div className={classes.table}>
                        {bucket.claims.map(claim => <div key={claim.id} className={classes.row}>
                            <div className={classes.content}>
                                <Typography variant="body1">요청번호: {claim.id} [{claimStatePipe(claim)}]</Typography>
                            </div>
                        </div>)}
                    </div>
                </> : <Nodata><Typography variant='body1' style={{ color: colors.textSecondary }}>클레임 요청이 없습니다.</Typography></Nodata>}
            </div>

            {/* 배송추적 */}
            <Dialog
                open={trackerDialogOpen}
                onClose={() => { setTrackerDialogOpen(false); }}
            >
                <DialogTitle>배송추적</DialogTitle>
                <DialogContent style={{ minWidth: '240px' }}>
                    <DeliveryTracker carrierId={bucket.deliveryService} invoiceNumber={bucket.invoiceNumber} />
                    {/* {dialogData.message && <DialogContentText>{dialogData.message}</DialogContentText>} */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setTrackerDialogOpen(false) }} color="primary" autoFocus>확인</Button>
                </DialogActions>
            </Dialog>
		</>
	);
}

export default BucketInfo;