import React, { useState, useEffect, useContext, useMemo, useCallback, useRef, Fragment } from 'react';
import { Container, Box, Grid, Typography, makeStyles, useTheme, useMediaQuery, Button, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import API from 'api';
import colors from 'appcolors';
import Loding from 'components/Loading';

const useStyles = makeStyles((theme) => ({
	container: { padding: '24px 0' },
	title: { color: colors.textSecondary, marginBottom: '16px' },
}));

const RefundInfo = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));


	return (
		<div className={classes.container}>
			<Typography variant="body1" className={classes.title}>환불정보</Typography>
			<Typography variant="body1">환불시 반품 확인여부를 확인한 후 3영업일 이내에 결제 금액을 환불해 드립니다.</Typography>
			<Typography variant="body1">신용카드로 결제하신 경우는 신용카드 승인을 취소하여 결제 대금이 청구되지 않게 합니다.</Typography>
			<Typography variant="body1">(단, 신용카드 결제일자에 맞추어 대금이 청구 될수 있으면 이경우 익월 신용카드 대금청구시 카드사에서 환급처리 됩니다.)</Typography>
		</div>
	);
}

export default RefundInfo;