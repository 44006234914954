import { makeStyles } from '@material-ui/core';

import colors from 'appcolors';

const useOrderFormStyles = makeStyles((theme) => ({
    section: {
        padding: '16px 0',
        display: 'flex',
        flexDirection: 'column',
    },
    table: {
        borderTop: '3px solid #cccccc',
    },
    row: {
        display: 'flex',
        borderBottom: '1px solid #cccccc',
    },
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        width: '60px',
        backgroundColor: '#f0f0f0',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('sm')]: {
            width: '100px',
        },
        [theme.breakpoints.up('md')]: {
            width: '140px',
        },
    },
    labelText: {
        color: colors.textPrimary,
    },
    content: {
        padding: '8px 16px',
        flex: 1,
    },
    inputRow: {
        display: 'flex',
        marginBottom: '4px',
    },
    inputContent: {
        margin: '0 8px',
        flex: 1,
    },
    subButton: {
        marginTop: '16px',
        fontSize: '14px',
    },
}));


export default useOrderFormStyles;