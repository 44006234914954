import React from 'react';
import { Rating } from '@material-ui/lab';
import { Typography, makeStyles, useTheme, Link } from '@material-ui/core';
import colors from 'appcolors';
import { platform_pipe, proxy_options_pipe } from 'pipes';


const useStyles = makeStyles((theme) => ({
	container: { padding: '24px 0' },
	title: { color: colors.textSecondary, marginBottom: '16px' },
}));

const Review = ({ item }) => {
	const classes = useStyles();
	const theme = useTheme();

	const nickname_mask = (nickname) => {
		if (!nickname || nickname?.length < 2) return '***';
		else return nickname.substring(0, 1) + "*" + nickname.substring(2);
	}

	const proxyname_pipe = (review) => {
		const proxyId = review.proxyId;
		if (!proxyId) return item.name;
		else {
			const proxy = item.proxies.find(el => el.id === proxyId);
			return proxy_options_pipe(proxy);
		}
	}

	if (item.links.length === 0 && item.reviews.length === 0) return null;

	return (
		<>
			<Typography variant="body1" className={classes.title}>상품평</Typography>
			
			{item.links.length === 0 && item.reviews.length === 0 && <Typography variant='body1'>현재 상품평이 없습니다.</Typography>}

			{item.reviews.map(review => <div key={review.id} style={{ marginBottom: '24px' }}>
				<Typography variant="body1">{nickname_mask(review.nickname)}</Typography>
				<Rating size='small' value={review.stars} readOnly={true} />
				{review.proxyId && <Typography variant="body2" style={{ color: colors.textSecondary }}>{proxyname_pipe(review)}</Typography>}
				<Typography variant="body2" style={{ marginTop: '6px', whiteSpace: 'pre-line' }}>{review.content}</Typography>
			</div>)}

			{item.links.length > 0 && <>
				<Typography variant="body1">아래 플랫폼 링크에서 상품평을 확인하실 수 있습니다.</Typography>

				{item.links.map(link => <Link variant='body1' target="_blank" key={link.id} href={link.url}>{`${platform_pipe(link.platform)} 상세페이지`}</Link>)}
			</>}
		</>
	);
}

export default Review;