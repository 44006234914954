import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import axios from 'axios';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from 'api';
import colors from 'appcolors';
import CircularButton from 'components/CircularButton';
import Button from 'components/Button';
import TextField from 'components/TextField';


const SignupPage = () => {	
    const { fetchMyinfo } = useContext(AuthContext);

	const history = useHistory();
    
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

	// 이용약관, 개인정보처리방침
	const [ appusageModalOpen, setAppusageModalOpen ] = useState(false);
	const [ privacyModalOpen, setPrivacyModalOpen ] = useState(false); 
	const [ appusage, setAppusage ] = useState();
	const [ privacy, setPrivacy ] = useState();
	const handleAppUsageClick = () => {
		axios({
			method: 'get',
			url: `${process.env.REACT_APP_HOST}/resource/policy/app_policy`,
		})
		.then((data) => {
			setAppusage({__html: data.data});
			setAppusageModalOpen(true);
		})
	}
	const handlePrivacyClick = () => {
		axios({
			method: 'get',
			url: `${process.env.REACT_APP_HOST}/resource/policy/privacy_policy`,	
		})
		.then((data) => {
			setPrivacy({__html: data.data});
			setPrivacyModalOpen(true);
		})
	}
	// end: 이용약관, 개인정보처리방침

	return(
		<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
			<Formik
				initialValues={{
					email: '',
                    nickname: '',
					password: '',
                    passwordConfirm: '',
					appUsage: false,
					privacy: false,
				}}
				validationSchema={Yup.object({
					email: Yup.string().required('Required').email('This is not an email format.'),
                    nickname: Yup.string().required('Required'),
					password: Yup.string().required('Please enter a password.').max(50, 'Your password is too long.').matches(/^(?=.*[A-Za-z])(?=.*\d).{8,}$/, 'At least 8 characters, at least 1 number/letter.'),
                    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match.'),
					appUsage: Yup.boolean().oneOf([true], 'Agree required'),
					privacy: Yup.boolean().oneOf([true], 'Agree required'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					API.post('/auth/signup', values)
					.then((data) => {
						setSubmitting(false);
						return fetchMyinfo();
					})
					.then(() => { history.push('/auth/user-pending?fromSignup=Y'); })
					.catch(error => {
						setSubmitting(false);
					})
				}}
			>
				{({ values, errors, dirty, touched, isSubmitting, isValid, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
					<form onSubmit={handleSubmit} style={{ width: '100%', padding: '40px 16px', display: 'flex', justifyContent: 'center' }}>
						<Box width={{ xs: '100%', sm: '500px', md: '600px' }} display="flex" flexDirection="column" >
							<Typography variant="h6" style={{ color: colors.gray600, marginBottom: '5px' }}>JOIN</Typography>
							
							<Typography variant="h6" style={{ marginTop: '24px', marginBottom: '8px', color: colors.textHint }}>Email</Typography>
							<TextField
								label={'Email'}
								variant="outlined"
								type="email"
								name="email"
								size="small"
								autoComplete='email'
								autoFocus
								required={true}
								onChange={handleChange('email')}
								onBlur={handleBlur('email')}
								helperText={(touched.email && errors.email) || 'There may be an email verification process, so please enter it accurately.'}
								error={!!(touched.email && errors.email)}
								value={values.email}
							/>
							
							<Typography variant="h6" style={{ marginTop: '24px', marginBottom: '8px', color: colors.textHint }}>Nickname</Typography>
							<TextField
								label={'Nickname'}
								variant="outlined"
								type="text"
								name="nickname"
								autoComplete='nickname'
								size="small"
								required={true}
								onChange={handleChange('nickname')}
								onBlur={handleBlur('nickname')}
								helperText={(touched.nickname && errors.nickname) || 'Please enter your nickname.'}
								error={!!(touched.nickname && errors.nickname)}
								value={values.nickname}
							/>

                            <Typography variant="h6" style={{ marginTop: '24px', marginBottom: '8px', color: colors.textHint }}>Password</Typography>
							<TextField
								label={'Password'}
								variant="outlined"
								type="password"
								name="password"
								autoComplete='new-password'
								size="small"
								required={true}
								onChange={handleChange('password')}
								onBlur={handleBlur('password')}
								helperText={(touched.password && errors.password) || 'Please enter a password.'}
								error={!!(touched.password && errors.password)}
								value={values.password}
							/>

                            <Typography variant="h6" style={{ marginTop: '24px', marginBottom: '8px', color: colors.textHint }}>Password Reinput</Typography>
							<TextField
								label={'Password Reinput'}
								variant="outlined"
								type="password"
								size="small"
								required={true}
								onChange={handleChange('passwordConfirm')}
								onBlur={handleBlur('passwordConfirm')}
								helperText={(touched.passwordConfirm && errors.passwordConfirm) || 'Please enter your password again.'}
								error={!!(touched.passwordConfirm && errors.passwordConfirm)}
								value={values.passwordConfirm}
							/>
							
							<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={3}>
								<FormControlLabel
									control={<Checkbox checked={values.appUsage} onChange={(event) => { setFieldValue('appUsage', event.target.checked); setFieldTouched('appUsage', true); }}  />}
									label="Terms of Use Agreement"
								/>
								<Typography onClick={handleAppUsageClick} variant='body1' style={{ cursor: 'pointer', color: colors.textSecondary, textDecorationLine: 'underline' }}>Read more</Typography>
							</Box>
							<Typography variant='body2' style={{ color: colors.error }}>{touched.appUsage && errors.appUsage}</Typography>
							
							<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
								<FormControlLabel
									control={<Checkbox checked={values.privacy} onChange={(event) => { setFieldValue('privacy', event.target.checked); setFieldTouched('privacy', true); }} />}
									label="Personal Information Collection and Usage Agreement"
								/>
								<Typography onClick={handlePrivacyClick} variant='body1' style={{ cursor: 'pointer', color: colors.textSecondary, textDecorationLine: 'underline' }}>Read more</Typography>
							</Box>
							<Typography variant='body2' style={{ color: colors.error }}>{touched.privacy && errors.privacy}</Typography>
			
							<Box display={'flex'} flexDirection={{xs: 'column', sm: 'row'}} alignItems={{ xs: 'center', sm: 'flex-end' }} justifyContent={'space-between'} marginTop={'16px'} >
								<CircularButton size="large" variant="contained" type="submit" color="primary" loading={isSubmitting} disabled={isSubmitting} style={{ alignSelf: isHandset ? 'stretch' : 'default' }} buttonStyle={{ color: 'white' }}>Join</CircularButton>
								<Typography variant="h6" style={{ color: colors.gray600, marginTop: isHandset ? '24px' : 0 }}>
									Already joined?
									<Link component={RouterLink} to={'/auth/signin'} style={{ marginLeft: '8px', fontWeight: 'bold' }}>Login</Link>
								</Typography>
							</Box>
						</Box>

						<Dialog
							open={appusageModalOpen}
							onClose={() => { setAppusageModalOpen(false); }}
							scroll={'paper'}
						>
							<DialogContent>
								<DialogContentText tabIndex={-1}>
									<div dangerouslySetInnerHTML={appusage} />
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => { setAppusageModalOpen(false); }}>Close</Button>
								<Button color="primary" onClick={() => { setFieldValue('appUsage', true); setAppusageModalOpen(false); }}>Agree</Button>
							</DialogActions>
						</Dialog>

						<Dialog
							open={privacyModalOpen}
							onClose={() => { setPrivacyModalOpen(false); }}
							scroll={'paper'}
						>
							<DialogContent>
								<DialogContentText tabIndex={-1}>
									<div dangerouslySetInnerHTML={privacy} />
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => { setPrivacyModalOpen(false); }}>Close</Button>
								<Button color="primary" onClick={() => { setFieldValue('privacy', true); setPrivacyModalOpen(false); }}>Agree</Button>
							</DialogActions>
						</Dialog>
					</form>
				)}
			</Formik>
		</div>
	)
}


export default SignupPage;
