import React from 'react';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import colors from 'appcolors';

const useStyles = makeStyles((theme) => ({
	container: { padding: '24px 0' },
	title: { color: colors.textSecondary, marginBottom: '16px' },
}));

const Inquiry = () => {
	const classes = useStyles();
	const history = useHistory();

	const handleInquiryClick = () => {
		history.push('/ticket');
	}

	return (
		<div className={classes.container}>
			<Typography variant="body1" className={classes.title}>상품문의</Typography>
			<Typography variant="body1">구매한 상품의 취소/반품은 마이페이지 구매내역에서 신청 가능합니다.</Typography>
			<Typography variant="body1">상품문의 및 후기게시판을 통해 취소나 환불, 반품 등은 처리되지 않습니다.</Typography>
			<Typography variant="body1">가격, 판매자, 교환/환불 및 배송 등 해당 상품 자체와 관련 없는 문의는 고객센터 내 1:1 문의하기를 이용해주세요.</Typography>
			<Typography variant="body1">"해당 상품 자체"와 관계없는 글, 양도, 광고성, 욕설, 비방, 도배 등의 글은 예고 없이 이동, 노출제한, 삭제 등의 조치가 취해질 수 있습니다.</Typography>
			<Typography variant="body1">공개 게시판이므로 전화번호, 메일 주소 등 고객님의 소중한 개인정보는 절대 남기지 말아주세요.</Typography>
			<Button onClick={handleInquiryClick} variant="outlined" size="small" color="primary" style={{ marginTop: '8px' }}>문의하기</Button>
		</div>
	);
}

export default Inquiry;