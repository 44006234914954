import { Button as MuiButton,  styled } from '@material-ui/core';

const Button = styled(MuiButton)({
    boxShadow: 'none',
    borderRadius: 0,
    '&:hover': {
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
    },
});




export default Button;