import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, makeStyles, useTheme, useMediaQuery, Divider, MenuItem } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { item_gender_pipe, spike_pipe, lace_pipe, number_format_pipe, item_category_pipe, proxy_options_event_pipe } from 'pipes';
import colors from 'appcolors';
import Gallery from './Gallery';
import { ItemPageContext } from 'contexts/item-page.context';
import NumberIndicator from 'components/NumberIndicator';
import Button from 'components/Button';
import TextField from 'components/TextField';
import Review from './Review';


const info = [
	{ key: 'matter', name: '상품의 주소재' },
	{ key: 'country', name: '제조국', value: '한국' },
	{ key: 'manufaturer', name: '제조자', value: '명문스포츠' },
	{ key: 'warranty', name: '품질보증기준' },
	{ key: 'precautions', name: '취급시 주의사항' },
	{ key: 'manager', name: 'A/S 이메일', value: 'mm500501@hanmail.net' },
];


const ADvidier = () => <Divider style={{ margin: '16px 0'}} />

const useStyles = makeStyles((theme) => ({
    container: { padding: '24px 0' },
    label: { color: colors.textSecondary },
    listValue: { fontWeight: 'bold' },
}));

const Proxies = () => {
	const classes = useStyles();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { item, proxy, setProxy, count, setCount, hasAtLeastOneProxy, plusEvenProxies, handleBagClick } = useContext(ItemPageContext);

    const { search } = useLocation();

    // option 의 ViewModal
    const [ selectOptions, setSelectOptions ] = useState();
    useEffect(() => {
        const query = new URLSearchParams(search);
        const proxyId = query.get('proxyId');
        const result = [];
        if (proxyId) {
            const proxy = item.proxies.find(el => el.id == proxyId);
            if (proxy) {
                for (let select of proxy.proxyOptions) {
                    result.push({ selectId: select.id, optionId: select.option.id });
                }
            }
        }
        else {
            for (let select of item.selects) {
                if (select.options.length > 0) result.push({ selectId: select.id, optionId: select.options[0].id });
            }
        }
        setSelectOptions(result);
    }, [ item, search ]);

    const handleOptionChange = (select) => (event) => {
        const selectId = select.id;
        const optionId = event.target.value;

        setSelectOptions((rows) => {
            const result = [ ...rows ];
            const target = result.find(row => row.selectId === selectId)
            if (target) target.optionId = optionId;
            return result;
        });
    }

    // view model 이 변경함에 따라 proxy 를 지정한다. 만약 proxy 가 없으면 상품이 없다는 메시지를 남겨야함
    useEffect(() => {
        if (!selectOptions) return;
        const availables = item.proxies.filter(el => el.expose);
        for (let proxy of availables) {
            let same = true;
            for (let option of proxy.proxyOptions) {
                let finded = false;
                for (let row of selectOptions) {
                    if (option.optionId === row.optionId) {
                        finded = true;
                        break;
                    }
                }
                if (!finded) same = false;
            }
            if (same) {
                setProxy(proxy);
                return;
            }
        }
        setProxy(null);
    }, [ selectOptions ]);

    // plus이벤트중인 프록시들
    const [ selectByProxy, setSelectByProxy ] = useState(false);
    useEffect(() => {
        setSelectByProxy(plusEvenProxies.length > 0 );
    }, [ plusEvenProxies ]);
    const handleSelectProxy = (event) => {
        const proxyId = event.target.value;
        setProxy(item.proxies.find(el => el.id === proxyId));
    }

    if (!selectOptions) return null;

	return (
		<div className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Gallery fileInfos={item.fileInfos} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h4" style={{ fontWeight: 'bold' }}>{item.name}</Typography>
                    {plusEvenProxies.length > 0 && <Typography variant='body1' style={{ color: 'orange', fontWeight: 'bold' }}>1+1 이벤트 행사중</Typography>}
                    
                    <ADvidier />
                    {item.normalPrice && <Typography>{Math.round((item.price - item.normalPrice) / item.normalPrice * 100)}% <span style={{ textDecorationLine: 'line-through', color: colors.textDisabled }}>{number_format_pipe(item.normalPrice)}원</span></Typography>}
                    <Typography variant="h5" style={{ color: '#ae0000' }}><span style={{ fontWeight: 'bold' }}>{number_format_pipe(item.price)}</span>원</Typography>

                    <ADvidier />
                    <Typography>{'구분: '}<span className={classes.listValue}>{item_category_pipe(item.category)}</span></Typography>
                    <Typography>{'상품코드: '}<span className={classes.listValue}>{item.code}</span></Typography>
                    <Typography>{'스파이크 유무: '}<span className={classes.listValue}>{spike_pipe(item.spike)}</span></Typography>
                    <Typography>{'레이스 / 다이얼: '}<span className={classes.listValue}>{lace_pipe(item.lace)}</span></Typography>
                    {item.gender && <Typography>{'성별전용: '}<span className={classes.listValue}>{item_gender_pipe(item.gender)}</span></Typography>}
                    {item.forCaddy && <Typography className={classes.listValue}>{'캐디추천'}</Typography>}
                    
                    <ADvidier />
                    {info.map(notation => <div key={notation.key} style={{ display: 'flex' }}>
                        <Typography>{notation.name}: <span>{item.notations[notation.key] || notation.value}</span></Typography>
                    </div>)}

                    {/* proxies */}
                    {(selectByProxy) ? <>
                        <ADvidier />
                        <TextField
                            select
                            name={'proxyId'}
                            style={{ width: '100%' }}
                            size="small"
                            variant="outlined"
                            onChange={handleSelectProxy}
                            value={proxy?.id || -1}
                        >
                            <MenuItem value={-1}>상품을 선택하세요.</MenuItem>
                            {item.proxies.filter(proxy => proxy.expose).map(proxy => <MenuItem key={proxy.id} value={proxy.id}>{proxy_options_event_pipe(proxy)}</MenuItem>)}
                        </TextField>
                    </> : <>
                        {/* options */}
                        {item.selects.length > 0 && <>
                            <ADvidier />
                            <div>
                                {item.selects.map(select => <div key={select.id} style={{ marginBottom: '8px' }}>
                                    <Typography variant="subtitle2" className={classes.label}>{select.name}</Typography>
                                    <TextField
                                        select
                                        name={`optionId_${select.id}`}
                                        style={{ width: '100%' }}
                                        size="small"
                                        variant="outlined"
                                        onChange={handleOptionChange(select)}
                                        value={selectOptions.find(item => item.selectId === select.id)?.optionId}
                                    >
                                        {select.options.map(option => <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>)}
                                    </TextField>
                                </div>)}
                                {hasAtLeastOneProxy && !proxy && <Typography style={{ color: colors.error }}>상품이 없습니다. 다른 옵션을 선택하세요.</Typography>}
                            </div>
                        </>}
                    </>}

                    {/* 상품평 */}
                    <ADvidier />
                    <Review item={item} />

                    {/* actions */}
                    <ADvidier />
                    <div style={{ display: 'flex', flexDirection: isHandset ? 'row-reverse' : 'row' }}>
                        <Button size="large" color="primary" variant="contained" style={{ color: 'white', marginRight: isHandset ? 0 : '8px', marginLeft: isHandset ? '8px' : 0 }} onClick={handleBagClick} disabled={hasAtLeastOneProxy && !proxy}>쇼핑백 담기</Button>
                        <NumberIndicator count={count} setCount={setCount} />
                        
                    </div>
                </Grid>

            </Grid>
		</div>
	);
}

export default Proxies;