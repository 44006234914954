import React from 'react';
import { Box, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ClassIcon from '@material-ui/icons/Class';
import NotificationsIcon from '@material-ui/icons/Notifications';
import colors from 'appcolors';
import theme from 'theme';


const menus = [
    { name: '내정보', icon: <AccountCircleIcon fontSize="small" />, href: "/mypage/myinfo" },
    { name: '주문내역', icon: <ChromeReaderModeIcon fontSize="small" />, href: "/mypage/myorders" },
    { name: '반품∙교환', icon: <RotateLeftIcon fontSize="small" />, href: "/mypage/myclaims" },
    { name: '리뷰 관리', icon: <RateReviewIcon fontSize="small" />, href: "/mypage/myreviews" },
    { name: '배송지 관리', icon: <ImportContactsIcon fontSize="small" />, href: "/mypage/myaddresses" },
    { name: '쿠폰함', icon: <ClassIcon fontSize="small" />, href: "/mypage/mycoupons" },
    { name: '알림설정', icon: <NotificationsIcon fontSize="small" />, href: "/mypage/alarm-setup" },
    { name: '문의하기', icon: <QuestionAnswerIcon fontSize="small" />, href: "/inquiry-home" },
];

const useStyles = makeStyles((theme) => ({

}));

const MenuItem = ({ menu }) => {
    const history = useHistory();

    return (
        <Box display='flex' alignItems="center'" style={{ borderBottom: '1px solid #bdbdbd', padding: '24px 18px', cursor: 'pointer' }} onClick={() => { history.push(menu.href); }}>
            {menu.icon}
            <Typography variant='h5' style={{ flex: 1, marginLeft: '8px' }}>{menu.name}</Typography>
            <KeyboardArrowRightIcon style={{ color: colors.gray700 }} fontSize="small" />
        </Box>
    );
}

const DensedMenuItem = ({ menu }) => {
    const history = useHistory();
    const match = useRouteMatch(menu.href);

    return (
        <Box display='flex' alignItems="center'" style={{ borderBottom: '1px solid #dddddd', padding: '12px 16px', cursor: 'pointer' }} onClick={() => { history.push(menu.href); }}>
            <Typography variant='body1' style={{ flex: 1, color: match ? colors.primary : colors.textPrimary, fontWeight: match ? 'bold' : 'normal' }}>{menu.name}</Typography>
        </Box>
    );
}

const MypageMenu = ({ densed }) => {
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    if (densed) {
        if (isHandset) return null;
        else return (
            <Box display='flex' flexDirection="column" bgcolor={'white'}>
                <Typography variant='body1' style={{ backgroundColor: colors.gray400, color: 'white', padding: '12px 16px', fontWeight: 'bold' }}>내페이지</Typography>
                <Box display='flex' flexDirection="column" style={{ border: '1px solid #dddddd'}}>
                    {menus.map((menu, index) => <DensedMenuItem key={index} menu={menu} />)}
                </Box>
            </Box>
        )
    } 
    else return (
		<Box display='flex' flexDirection="column" style={{ borderTop: '1px solid #bdbdbd' }} bgcolor={'white'}>
            {menus.map((menu, index) => <MenuItem key={index} menu={menu} densed={densed} />)}
		</Box>
	);
}

export default MypageMenu;