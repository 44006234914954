import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from 'components/Button';


const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function CircularButton({children, loading, disabled, style, buttonStyle, ...props}) {
    const classes = useStyles();
    const timer = React.useRef();

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    return (
        <div style={style}>
            <span style={{ position: 'relative' }}>
                <Button
                        {...props}
                        style={buttonStyle}
                        disabled={loading || disabled}
                    >
                        {children}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </span>
        </div>
    );
}