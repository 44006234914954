import React, { useState, useEffect, useContext, useMemo, useCallback, useRef, Fragment } from 'react';
import DaumPostcode from 'react-daum-postcode';
import Dialog from '@material-ui/core/Dialog';


const DaumPostcodeModal = ({open, setOpen, onComplete}) => {

	return (
        <Dialog onClose={() => setOpen(false)} open={open}>
			<DaumPostcode
                onComplete={onComplete}
            />
        </Dialog>
	);
}

export default DaumPostcodeModal;