import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Container, Typography, useTheme, useMediaQuery, Portal } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import { useHistory } from 'react-router-dom'
import colors from 'appcolors';
import { number_format_pipe, proxy_options_pipe } from 'pipes';
import { ItemPageContext } from 'contexts/item-page.context';
import { BagContext } from 'contexts/bag.context';
import { AppContext } from 'contexts/app.context';
import Button from 'components/Button';



export default function FooterStatus() {
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const { footContainer, setTakeupFooter } = useContext(AppContext);
    const { item, count, proxy, hasAtLeastOneProxy, isItemInBag, plusEvenProxies, handleBagClick } = useContext(ItemPageContext);
    const { stuff } = useContext(BagContext);

    // 사용자가 쇼핑백에 담을때, 그 상품과 같은 사이즈의 이벤트 상품이 있는지 있는지 확인하기
    // 1+1 특가상품을 구지 노출안함
    // const [ sizeProxies, setSizeProxies ] = useState();
    // const [ size, setSize ] = useState();
    // useEffect(() => {
    //     if (stuff.length > 0 && stuff.filter(el => !!el.eventPrice).length === 0) {
    //         const targetItem = stuff[stuff.length - 1];
    //         if (targetItem.proxyId) {
    //             const params = { proxyId: targetItem.proxyId, exceptionItemId: item.id };
    //             API.get('/option/check-samesize-event-proxy', { params })
    //             .then((data) => {

    //                 setSize(data.size)
    //                 setSizeProxies(data.rows);
    //             })
    //         }
    //         else {
    //             setSizeProxies(null);
    //         }
    //     }
    //     else {
    //         setSizeProxies(null);
    //     }
    // }, [ stuff ]);

    const handleOrderClick = () => {
        history.push('/order/form');
    }

    // const handlePlusEventClick = () => {
    //     history.push(`/proxy?size=${size}`)
    // }

    useEffect(() => {
        setTakeupFooter('120px');
        return () => { setTakeupFooter(0); }
    }, []);

	return (
		<Portal container={footContainer.current}>
            <div style={{ backgroundColor: 'rgb(248,248,248)', borderTop: '1px solid #dddddd', maxHeight: '120px', padding: '8px 0' }}>
                <Container style={{ height: '100%' }}>
                    <div style={{ display: 'flex', height: '100%', flexDirection: isHandset ? 'column-reverse' : 'row-reverse', alignItems: isHandset ? 'flex-start' : 'center', justifyContent: isHandset ? 'center' : 'space-between' }}>
                        {stuff.length === 0 && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: isHandset ? 'flex-end' : 'flex-start' }}>
                            {hasAtLeastOneProxy && 
                                <Typography variant="body2" style={{ flex: 1, color: colors.warn, marginRight: '16px', whiteSpace: isHandset ? 'pre-line' : 'normal' }}>{proxy ? proxy_options_pipe(proxy) : '상품을 선택해주세요.'}</Typography>
                            }
                            <Typography variant="h3" style={{ color: colors.textNeutral, fontWeight: 'bold', marginRight: '16px' }}>{number_format_pipe((proxy?.plusEventPrice || proxy?.price || item.price) * count)} 원</Typography>
                            <Button color="primary" variant="contained" size={isHandset ? 'small' : 'medium'} style={{ color: 'white' }} onClick={handleBagClick} disabled={hasAtLeastOneProxy && !proxy}>쇼핑백 담기</Button>
                        </div>}

                        <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                            {stuff.length > 0 ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                {/* {(sizeProxies?.length > 0) && <div style={{ margin: '8px 0', display: 'flex', alignItems: 'center' }}>
                                    <Typography variant={isHandset ? 'body1' : 'h6'} style={{ marginRight: '16px'}}><span style={{ fontWeight: 'bold' }}>1+1 이벤트</span> 중인 <span style={{ fontWeight: 'bold' }}>{size}</span> 사이즈 상품이 있습니다.</Typography>
                                    <Button color="secondary" variant="contained" size={isHandset ? 'small' : 'medium'} style={{ color: 'white' }} onClick={handlePlusEventClick} >확인하기</Button>
                                </div>} */}
                                <div style={{ margin: '8px 0', display: 'flex', alignItems: 'center' }}>
                                    <Typography variant={isHandset ? 'body1' : 'h6'} style={{ marginRight: '16px' }}>쇼핑백에 상품이 있습니다.</Typography>
                                    <Button color="primary" variant="contained" size={isHandset ? 'small' : 'medium'} style={{ color: 'white' }} onClick={handleOrderClick}>주문하기</Button>
                                </div>
                            </div> : <div style={{ marginBottom: isHandset ? '10px' : 0, display: 'flex' }}>
                                <LocalShippingIcon fontSize={isHandset ? 'small' : 'medium'} />
                                <div style={{ marginLeft: '8px', display: 'flex', flexDirection: isHandset ? 'row' : 'column', alignItems: isHandset ? 'flex-end' : 'flex-start' }}>
                                    <Typography variant={isHandset ? 'body2' : 'h6'} style={{ fontWeight: 'bold', marginRight: '8px' }}>배송:</Typography>
                                    <Typography variant={isHandset ? 'body2' : 'h6'} style={{ marginRight: '6px' }}>영업일기준 1일 ~ 2일</Typography>
                                    <Typography variant={isHandset ? 'body2' : 'h6'}>무료배송</Typography>
                                </div>
                            </div>}
                        </div>
                    </div>
                </Container>
            </div>
        </Portal>
	);
}