import React, { useState, useContext } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Button, Card, CardContent, Divider, TextField, MenuItem, Dialog, DialogTitle, FormControlLabel, Checkbox, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import API from 'api';
import colors from 'appcolors';
import { Formik } from 'formik';
import * as yup from 'yup';
import useApi from 'hooks/useApi';
import Loading from 'components/Loading';
import CircularButton from 'components/CircularButton';
import { AuthContext } from 'contexts/auth.context';


const useStyles = makeStyles((theme) => ({
    section: { width: '100%' }
}));

const Inquiry = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { me } = useContext(AuthContext);

    const [categories, setCategories] = useState();
    useApi(() => {
        API.get('/closed/inquiry-categories')
        .then(setCategories); 
    }, []);

    const [inquirySendedDialogOpen, setInquirySendedDialogOpen] = useState(false);

    // 개인정보처리방침
	const [ privacyModalOpen, setPrivacyModalOpen ] = useState(false); 
	const [ privacy, setPrivacy ] = useState();
	const handlePrivacyClick = () => {
		axios({
			method: 'get',
			url: `${process.env.REACT_APP_HOST}/resource/policy/privacy_policy`,	
		})
		.then((data) => {
			setPrivacy({__html: data.data});
			setPrivacyModalOpen(true);
		})
	}
	// end: 개인정보처리방침

    if (!categories) return (<Loading />);

	return (
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '0' : '40px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} display="flex" paddingX={2}>
                <Card style={{ flex: 1 }}>
                    <CardContent>
                        <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="h4" style={{ fontWeight: 'bold', marginRight: '6px' }}>Inquiry By Email </Typography>
                        </div>
                        <Divider />

                        <Formik
                            initialValues={{
                                category: '',
                                name: me?.nickname || '',
                                email: me?.email || '',
                                mobile: '',
                                comment: '',
                                privacy: false,
                            }}
                            validationSchema={yup.object({
                                category: yup.string().required('Required.'),
                                name: yup.string().required('Required.'),
                                email: yup.string().required('Required.').email('Invalid Email Type.'),
                                mobile: yup.number().typeError('Please enter numbers.'),
                                comment: yup.string().required('Required.'),
                                privacy: yup.boolean().oneOf([true], 'Required to agree'),
                            })}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                API.post('/closed/inquiry', values)
                                .then(({error}) => {
                                    setSubmitting(false);
                                    if (error) {
                                        alert('에러가 발생했습니다.');
                                        console.error(error);
                                        return;
                                    }
                                    resetForm();
                                    setInquirySendedDialogOpen(true);
                                });
                            }}
                        >
                            {({ values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                                <form onSubmit={handleSubmit}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '24px', width: '100%' }}>
                                        {categories && <>
                                            <div className={classes.section} style={{ marginTop: 0 }}>
                                                <Typography variant="subtitle1" className={classes.label}>Category *</Typography>
                                                <TextField
                                                    className={classes.section}
                                                    select
                                                    variant="outlined"
                                                    size="small"
                                                    required={true}
                                                    onChange={handleChange('category')}
                                                    onBlur={handleBlur('category')}
                                                    helperText={touched.category && errors.category || ' '}
                                                    error={!!(touched.category && errors.category)}
                                                    value={values.category}
                                                >
                                                    {Object.entries(categories).map(([key, name]) => (
                                                        <MenuItem key={key} value={key}>
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>
                                        </>}

                                        <div className={classes.section}>
                                            <Typography variant="subtitle1" className={classes.label}>Name(Nickname) *</Typography>
                                            <TextField 
                                                className={classes.section}
                                                type="text"
                                                variant="outlined"
                                                size="small"
                                                required={true}
                                                onChange={handleChange('name')}
                                                onBlur={handleBlur('name')}
                                                helperText={touched.name && errors.name || ' '}
                                                error={!!(touched.name && errors.name)}
                                                value={values.name}
                                            />
                                        </div>
                                        
                                        <div className={classes.section}>
                                            <Typography variant="subtitle1" className={classes.label}>Email *</Typography>
                                            <TextField 
                                                className={classes.section}
                                                type="email"
                                                variant="outlined"
                                                size="small"
                                                required={true}
                                                onChange={handleChange('email')}
                                                onBlur={handleBlur('email')}
                                                helperText={touched.email && errors.email || 'We will respond to the email address you entered.'}
                                                error={!!(touched.email && errors.email)}
                                                value={values.email}
                                            />
                                        </div>
                                        
                                        <div className={classes.section}>
                                            <Typography variant="subtitle1" className={classes.label}>Contact</Typography>
                                            <TextField 
                                                className={classes.section}
                                                type="tel"
                                                variant="outlined"
                                                size="small"
                                                onChange={handleChange('mobile')}
                                                onBlur={handleBlur('mobile')}
                                                helperText={touched.mobile && errors.mobile || "Please enter your contact information without '-'."}
                                                error={!!(touched.mobile && errors.mobile)}
                                                value={values.mobile}
                                            />
                                        </div>
                                        
                                        <div className={classes.section}>
                                            <Typography variant="subtitle1" className={classes.label}>Inquiry *</Typography>
                                            <TextField 
                                                className={classes.section}
                                                multiline
                                                variant="outlined"
                                                size="small"
                                                rows={6}
                                                required={true}
                                                onChange={handleChange('comment')}
                                                onBlur={handleBlur('comment')}
                                                helperText={touched.comment && errors.comment || ' '}
                                                error={!!(touched.comment && errors.comment)}
                                                value={values.comment}
                                            />
                                        </div>

                                        <Typography variant="subtitle2" style={{ marginBottom: theme.spacing(2) }}><i>Questions marked with * are required fields.</i></Typography>

                                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.privacy} onChange={(event) => { setFieldValue('privacy', event.target.checked); setFieldTouched('privacy', true); }} />}
                                                label="[Required] MNX15 consent to collection and use of personal information."
                                            />
                                            <Typography onClick={handlePrivacyClick} style={{ cursor: 'pointer', color: colors.textSecondary, textDecorationLine: 'underline' }}>Read more</Typography>
                                        </Box>
                                        <Typography variant='body2' style={{ color: colors.error }}>{touched.privacy && errors.privacy}</Typography>
                                        <CircularButton variant="contained" color="primary" size="large" loading={isSubmitting} type="submit" style={{ marginTop: theme.spacing(2) }} buttonStyle={{ color: 'white' }}>Submit</CircularButton>
                                    </div>

                                    <Dialog
                                        open={privacyModalOpen}
                                        onClose={() => { setPrivacyModalOpen(false); }}
                                        scroll={'paper'}
                                    >
                                        <DialogContent>
                                            <DialogContentText tabIndex={-1}>
                                                <div dangerouslySetInnerHTML={privacy} />
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => { setPrivacyModalOpen(false); }}>Close</Button>
                                            <Button color="primary" onClick={() => { setFieldValue('privacy', true); setPrivacyModalOpen(false); }}>Agree</Button>
                                        </DialogActions>
                                    </Dialog>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </Box>

            <Dialog onClose={() => setInquirySendedDialogOpen(false)} open={inquirySendedDialogOpen}>
                <DialogTitle>{'Inquiry completed.\nWe will respond within 24 hours on business days. ^^'}</DialogTitle>
                <div style={{ textAlign: 'center', marginBottom: '16px' }}>
                    <Button color="primary" variant="contained" size="small" style={{ color: 'white' }} onClick={() => setInquirySendedDialogOpen(false)}>Confirm</Button>
                </div>
            </Dialog>
        </div>
	);
}

export default Inquiry;