import { useEffect } from "react";


const useKakaoInit = () =>{
    useEffect(() => {
        setTimeout(() => {
            if (window.Kakao && !window.Kakao.isInitialized()) {
                window.Kakao.init('3f30f280035ff608491e22187a637532');      // javascript key
            }    
        }, 1000);
    }, []);
}

export default useKakaoInit;