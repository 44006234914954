import React, { useState, useEffect, useContext, useMemo, useCallback, useRef, Fragment } from 'react';
import { Container, Box, Grid, Typography, makeStyles, useTheme, useMediaQuery, Button, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import API from 'api';
import colors from 'appcolors';
import Loding from 'components/Loading';

const useStyles = makeStyles((theme) => ({

}));

const Gallery = ({ fileInfos }) => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const [ fileInfo, setFileInfo ] = useState();

    useEffect(() => {
        if (fileInfos.length > 0) {
            setFileInfo(fileInfos[0]);
        }
    }, [ fileInfos ]);

    const handleThumbnailOver = (fileInfo) => () => {
        setFileInfo(fileInfo);
    }

	return (
		<div style={{ display: 'flex', flexDirection: isHandset ? 'column-reverse' : 'row' }}>
			{<div style={{ width: '56px', display: 'flex', flexDirection: isHandset ? 'row' : 'column', marginRight: '8px' }}>
                {fileInfos.map(item => 
                    <img 
                        key={item.id} 
                        src={item.thumbURL || item.downloadURL}
                        style={{ width: '100%', marginBottom: '4px', borderWidth: '2px', borderStyle: 'solid', borderColor: item.id === fileInfo?.id ? colors.primary : 'white', cursor: 'pointer' }} 
                        alt="thumb" 
                        onMouseOver={handleThumbnailOver(item)} 
                    />
                )}
            </div>}
            <div style={{ flex: 1 }}>
                <img src={fileInfo ? fileInfo.downloadURL : null} style={{ width: '100%' }} alt="shoes" />
            </div>
            
		</div>
	);
}

export default Gallery;