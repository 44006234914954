import React, { useState, useEffect, useContext} from 'react';
import { useTheme, useMediaQuery, TableContainer, Paper, TableHead, TableRow, TableBody, Table, Typography } from '@material-ui/core';
import Loading from 'components/Loading';
import axios from 'axios';
import { AppContext } from 'contexts/app.context';
import { datetimePipe } from 'pipes';
import StyledTableCell from 'components/StyledTableCell';
import StyledTableRow from 'components/StyledTableRow';


const DeliveryTracker = ({ carrierId, invoiceNumber }) => {
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { showSnackbar } = useContext(AppContext);

    const [ data, setData ] = useState();
    const [ error, setError ] = useState();
    useEffect(() => {
        axios({
            method: 'get',
            url: `https://apis.tracker.delivery/carriers/${carrierId}/tracks/${invoiceNumber}`,
            // url: `https://apis.tracker.delivery/carriers/kr.hanjin/tracks/450116315515`,
        })
        .then((result) => {
            const message = result.data?.message;
            const progresses = result.data?.progresses;

            if (message) return showSnackbar(message);

            const info = [];
            for(let item of progresses) {
                const time = item.time;
                const status = item.status?.text;
                const location = item.location?.name;
                const description = item.description;
                info.push({ time, status, location, description });
            }
            setData(info);
        })
        .catch(error => {
            console.log(error);
            setError(error);
            showSnackbar('배송조회에 실패했습니다.');
        })
    }, [ invoiceNumber ]);

    if (!data) {
        if (!error) return (<Loading />);
        return (<Typography>배송추적에 실패했습니다.</Typography>);
    } 

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{'시각'}</StyledTableCell>
                            <StyledTableCell>{'상태'}</StyledTableCell>
                            <StyledTableCell>{'위치'}</StyledTableCell>
                            <StyledTableCell>{'설명'}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row">{datetimePipe(row.time)}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">{row.status}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">{row.location}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">{row.description}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
		</div>
	);
}

export default DeliveryTracker;