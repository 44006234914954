import React, { useContext, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'contexts/app.context';
import { claimStatePipe } from 'pipes';


const ClaimItem = ({ claim }) => {
    const { showAlert } = useContext(AppContext);
    const history = useHistory();

    const image = useMemo(() => {
        return claim.bucket.stuff.item.fileInfos[0]?.downloadURL;
    }, [ claim ]);

    const name = useMemo(() => {
        return claim.bucket.stuff.item.name;
    }, [ claim ]);

    const handleModify = () => {
        history.push(`/mypage/claim/form/${claim.bucket.id}?claimId=${claim.id}`);
    }

    return (
        <Box border={'1px solid #dddddd'} display="flex" marginBottom={'8px'} >
            <img src={image} alt={claim.bucket.stuff.item.name} style={{ height: '180px' }} />
            <Box display={'flex'} flexDirection='column' marginX={2} paddingY={1} flex={1}>
                <Typography variant='h6'>{name}</Typography>
                
                <Typography variant='body2' style={{ marginTop: '16px', flex: 1 }}>{claim.reason}</Typography>

                <Box style={{ marginTop: '8px'}}>
                    <Typography variant='body1'>상태: <span style={{ fontWeight: 'bold'}}>{claimStatePipe(claim)}</span></Typography>
                </Box>

                {claim.reply && <Typography variant='body2'>{claim.reply}</Typography>}
            </Box>
        </Box>
    );
}

export default ClaimItem;