import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Box, Typography, Card, CardContent, makeStyles, useTheme, useMediaQuery, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'appcolors';
import { AppContext } from 'contexts/app.context';
import DaumPostcodeModal from 'components/DaumPostcodeModal';
import Button from 'components/Button';
import TextField from 'components/TextField';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    guideText: {
        color: colors.textSecondary,
    },
    label: {
        color: colors.textSecondary, 
        margin: '16px 0 8px 0',
    },
    input: {
        width: '100%',
        // padding: 0,
    },
    subButton: {
        marginTop: '16px',
        fontSize: '14px',
    },
}));

const AddressFormPage = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { showSnackbar, showDialog } = useContext(AppContext);

    const { search } = useLocation();
    const intend = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('intend');
    }, [search]);

    const { addressId } = useParams();
    const [ address, setAddress ] = useState();
    useEffect(() => {
        if (addressId) {
            API.get('/address/address/' + addressId)
            .then(setAddress);
        }
        
    }, [ addressId ]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: address?.name || '',
            mobile: address?.mobile || '',
            zipcode: address?.zipcode || '',
            address: address?.address || '',
            addressDetail: address?.addressDetail || '',
            memo: address?.memo || '',
            isDefault: address?.isDefault || false,
        },
        validationSchema: yup.object({
            name: yup.string().required('필수입력입니다.'),
            mobile: yup.string().required('필수입력입니다.').max(12, '최대 12자입니다.'),
            zipcode: yup.string().required('필수입력입니다.'),
            address: yup.string().required('필수입력입니다.'),
            addressDetail: yup.string().required('필수입력입니다.'),
            memo: yup.string().max(100, '최대 100자까지 입력가능합니다.'),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (addressId) {
                API.put('/address/address', {
                    ...values,
                    id: addressId,
                })
                .then(() => {
                    showSnackbar('배송지를 저장했습니다.');
                    history.goBack();
                })
                .finally(() => { setSubmitting(false) });
            }
            else {
                API.post('/address/address', values)
                .then(() => {
                    resetForm();
                    showSnackbar('배송지를 저장했습니다.');

                    if (intend === 'order') {
                        history.push('/order/form');
                    }
                    else {
                        history.push('/mypage/myaddresses'); // 왜인지 모르겠지만 여기서만 history.goBack(); 이 안먹힘
                    }
                })
                .finally(() => { setSubmitting(false) });
            }
        },
    })
    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

    const [ postcodeModalOpen, setPostcodeModalOpen ] = useState(false);

    const handleRemoveClick = () => {
        const data = {
            title: '배송지 삭제',
            message: '정말 삭제하시겠습니까?',
            first: {
                text: '네',
                onClick: () => {
                    API.delete('/address/address/' + addressId)
                    .then(() => { 
                        showSnackbar('삭제했습니다.');
                        history.goBack();
                    });
                }
            },
            second: {
                text: '아니오',
            }
        }
        showDialog(data);
    }

	return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>{addressId ? '배송지 수정' : '배송지 추가'}</Typography>
                        <Divider />

                        <form onSubmit={handleSubmit}>
                            <Typography variant="subtitle2" className={classes.label}>받는사람 성함</Typography>
                            <TextField
                                variant="outlined"
                                className={classes.input}
                                size="small"
                                type="text"
                                required={true}
                                onChange={handleChange('name')}
                                onBlur={handleBlur('name')}
                                error={!!(touched.name && errors.name)}
                                helperText={(touched.name && errors.name) || ' '}
                                value={values.name}
                            />

                            <Typography variant="subtitle2" className={classes.label}>휴대폰 번호</Typography>
                            <TextField
                                variant="outlined"
                                className={classes.input}
                                size="small"
                                required={true}
                                placeholder="'-' 없이 숫자만 입력하세요."
                                onChange={handleChange('mobile')}
                                onBlur={handleBlur('mobile')}
                                error={!!(touched.mobile && errors.mobile)}
                                helperText={(touched.mobile && errors.mobile) || ' '}
                                value={values.mobile}
                            />

                            <Typography variant="subtitle2" className={classes.label}>주소</Typography>
                            <Box display={'flex'}>
                                <TextField
                                    variant="outlined"
                                    className={classes.input}
                                    style={{ marginRight: '8px' }}
                                    placeholder='검색버튼을 클릭하세요.'
                                    size="small"
                                    required={true}
                                    disabled={true}
                                    onBlur={handleBlur('address')}
                                    error={!!(touched.address && errors.address)}
                                    helperText={(touched.address && errors.address) || ' '}
                                    value={values.address}
                                />
                                <Button style={{ alignSelf: 'flex-start', height: '36px'}} variant="outlined" size="small" color="primary" onClick={() => { setPostcodeModalOpen(true); }}>검색</Button>
                                
                                <DaumPostcodeModal open={postcodeModalOpen} setOpen={setPostcodeModalOpen} onComplete={data => {
                                    setPostcodeModalOpen(false);
                                    console.log(data);
                                    const zipcode = data.zonecode;
                                    const address = data.roadAddress || data.jibunAddress;
                                    setFieldValue('zipcode', zipcode);
                                    setFieldValue('address', address);
                                }} />
                            </Box>

                            <Typography variant="subtitle2" className={classes.label}>상세주소</Typography>
                            <TextField
                                variant="outlined"
                                className={classes.input}
                                size="small"
                                required={true}
                                onChange={handleChange('addressDetail')}
                                onBlur={handleBlur('addressDetail')}
                                error={!!(touched.addressDetail && errors.addressDetail)}
                                helperText={(touched.addressDetail && errors.addressDetail) || ' '}
                                value={values.addressDetail}
                            />

                            <Typography variant="subtitle2" className={classes.label}>배송메모</Typography>
                            <TextField
                                variant="outlined"
                                className={classes.input}
                                size="small"
                                onChange={handleChange('memo')}
                                onBlur={handleBlur('memo')}
                                error={!!(touched.memo && errors.memo)}
                                helperText={(touched.memo && errors.memo) || ' '}
                                value={values.memo}
                            />

                            <Box marginTop={2}>
                                <FormControlLabel
                                    control={<Checkbox checked={values.isDefault} onChange={handleChange('isDefault')} />}
                                    label="기본배송지로 저장"
                                />
                            </Box>
                            
                            <Box marginTop={2}>
                                <Button type="submit" variant="contained" color="primary" style={{ color: 'white' }}>저장</Button>
                                {addressId && <Button type="button" variant="contained" style={{ marginLeft: '6px', backgroundColor: colors.error, color: 'white' }} onClick={handleRemoveClick}>삭제</Button>}
                            </Box>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </div>
	);
}

export default AddressFormPage;