import { createStyles, makeStyles } from '@material-ui/core';

import colors from 'appcolors';

const useStyles = makeStyles((theme) => createStyles({
	'@global': {
		body: {
			height: '100%',
			backgroundColor: 'colors.background',
		},
		'.pageRoot': {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(7),
		},
		'.oneline-ellipsis': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		'.absolute-center': {
			position: 'absolute',
			left: '50%',
    		transform: 'translate(-50%, 0)',
		},
		// '.MuiButton-root': {
		// 	fontSize: '1rem',
		// 	color: 'white',
		// },
	}
}));

const GlobalStyles = () => {
	useStyles();

	return null;
};

export default GlobalStyles;
