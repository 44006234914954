import React, { useContext } from 'react';
import { Typography, Box } from '@material-ui/core';
import { AuthContext } from 'contexts/auth.context';
import useOrderFormStyles from './useOrderFormStyles';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useHistory } from 'react-router-dom';
import colors from 'appcolors';


const PurchaserInfo = () => {
	const classes = useOrderFormStyles();
    const history = useHistory();

    const { me } = useContext(AuthContext)

    const onEditClick = () => {
        history.push('/mypage/myinfo');
    }

	return (
		<>
			{me && <>
                <div className={classes.section}>
                    <Box display={'flex'} alignItems={'center'} marginBottom={1} onClick={onEditClick} style={{ cursor: 'pointer', alignSelf: 'flex-start' }}>
                        <Typography variant="subtitle1" style={{ marginBottom: 0 }}>구매자정보</Typography>
                        <ArrowRightIcon />
                    </Box>
                    
                    <div className={classes.table}>
                        <div className={classes.row}>
                            <div className={classes.label}>
                                <Typography variant="body1" className={classes.labelText}>닉네임</Typography>
                            </div>
                            <div className={classes.content}>
                                <Typography variant="body1" style={{ color: me.nickname ? colors.textPrimary : colors.textHint }}>{me.nickname || '[미지정]'}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
		</>
	);
}

export default PurchaserInfo;