import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, Link as RouteLink } from 'react-router-dom';
import { AppBar, Button, Container, Drawer, IconButton, InputAdornment, List, ListItem, ListItemText, makeStyles, Slide, TextField, Toolbar, Typography, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import colors from 'appcolors';
import { AuthContext } from 'contexts/auth.context';
import { AppContext } from 'contexts/app.context';
import { BagContext } from 'contexts/bag.context';
import Footer from 'views/home/Footer';
import MainGuide from 'components/MainGuide';


function HideOnScroll(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({ target: window ? window() : undefined });
  
	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
}));

function MainLayout({ children }) {
	const classes = useStyles();
	const history = useHistory();
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { me, setLoginRedirectURL } = useContext(AuthContext);
    const { footContainer, takeupFooter } = useContext(AppContext)
    const { stuff } = useContext(BagContext);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    // 검색
    const [ keyword, setKeyword ] = useState();
    const [ searchExpanded, setSearchExpanded ] = useState(false);
    const handleSearchIconClick = () => {
        setSearchExpanded(val => !val);
    }
    const handleSearch = () => {
        history.push(`/item?keyword=${keyword}`);
    }
    // end: 검색

    const handleRouteClick = (route, isRoot) => () => {
        setDrawerOpen(false);
        if (isRoot) {
            window.location.href = `${process.env.REACT_APP_HOST}${route}`;
        }
        else {
            history.push(route);            
        }
    }

    const location = useLocation();
    const handleLoginClick = () => {
        setLoginRedirectURL(location.pathname);
        let route;
        if (!me) {
            route = '/auth/signin';
        }
        else {
            if (me.state === 'pending') {
                route = '/mypage/myinfo';
            }
            else {
                route = isHandset ? '/mypage' : '/mypage/myorders';
            }
        }
        
        history.push(route);
        setDrawerOpen(false);
    }

    // 사용자가 pending 상태일 경우 route 제한
    useEffect(() => {
        if (me?.state === 'pending') {
            const pendingAvailables = [ 
                '/auth/user-pending',
                '/inquiry-home',
                '/ticket',
                '/ticket/write',
                '/inquiry',
                '/mypage/myinfo',
            ];
            if (pendingAvailables.indexOf(history.location.pathname) ===  -1) {
                history.push('/auth/user-pending');
            }
        }
    }, [ me, history.location ]);

    const list = (
        <div
            className={classes.list}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
        >
            <a href="/" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0 2px 0' }}>
                <img src={`${process.env.REACT_APP_HOST}/images/logo_black_219_55.png`} alt="Logo" style={{ width: '110px', height: '27px' }} />
            </a>
            <List component={'nav'}>
                <ListItem button onClick={handleRouteClick('/items?category=golf', true)} style={{ borderBottom: '1px solid #f0f0f0', borderTop: '1px solid #f0f0f0' }}><ListItemText primary={'GOLF'} /></ListItem>
                <ListItem button onClick={handleRouteClick('/items?category=height', true)} style={{ borderBottom: '1px solid #f0f0f0', borderTop: '1px solid #f0f0f0' }}><ListItemText primary={'ELEVATOR'} /></ListItem>
                <ListItem button onClick={handleRouteClick('/inquiry-home')} style={{ borderBottom: '1px solid #f0f0f0'}}><ListItemText primary={'CONTACT'} /></ListItem>
                {stuff?.length > 0 && <ListItem button onClick={handleRouteClick('/order/form')} style={{ borderBottom: '1px solid #f0f0f0'}}><ListItemText primary={'BAG'} /></ListItem>}
                <ListItem button onClick={handleLoginClick} style={{ borderBottom: '1px solid #f0f0f0'}}><ListItemText primary={me ? 'MYPAGE' : 'LOGIN'} /></ListItem>
            </List>
        </div>
    );
    
    {/* -px: 이유는 모르겠지만 body 자체에 이미 8 margin 이 붙어있음 */}
	return (
		<div style={{ margin: '-8px', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginBottom: takeupFooter }}>
                <HideOnScroll>
                    <AppBar position="fixed">
                        <Toolbar style={{ backgroundColor: 'white' }}>
                            {isHandset ? <div style={{ display: 'flex', width: '100%' }}>
                                {/* drawer */}
                                <IconButton onClick={toggleDrawer(true)}><MenuIcon style={{ color: colors.textPrimary }} /></IconButton>
                                <Drawer anchor={'left'} open={drawerOpen} onClose={toggleDrawer(false)}>
                                    {list}
                                </Drawer>

                                {/* search */}
                                {!searchExpanded ? <IconButton onClick={handleSearchIconClick} style={{ marginLeft: 'auto' }}><SearchIcon style={{ color: colors.textPrimary }} /></IconButton> 
                                : <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
                                    <TextField
                                        autoFocus
                                        variant='outlined'
                                        size='small'
                                        color='white'
                                        style={{ caretColor: 'white' }}
                                        InputProps={{
                                            style: { color: 'white' },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon style={{ color: 'white' }} onClick={() => { setSearchExpanded(false); }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={keyword}
                                        onChange={event => setKeyword(event.target.value)}
                                        onBlur={() => { setSearchExpanded(false); }}
                                        onKeyPress={(ev) => {
                                            if (ev.key === 'Enter') {
                                                ev.preventDefault();
                                                handleSearch();
                                            }
                                        }}
                                    />
                                </div>}

                                {!searchExpanded && <div style={{ position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%,-50%)' }}>
                                    <Button component={RouteLink} style={{ color: colors.primary, fontWeight: 'bold' }} onClick={handleRouteClick('/', true)}>
                                        <img src={`${process.env.REACT_APP_HOST}/images/logo_black_219_55.png`} alt="Logo" style={{ width: '110px', height: '27px' }} />
                                    </Button>
                                </div>}
                            </div> : <Container maxWidth="md">
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button component={'a'} style={{ color: colors.primaryLight, fontWeight: 'bold' }} href='/'>
                                        <img src={`${process.env.REACT_APP_HOST}/images/logo_black_219_55.png`} alt="Logo" style={{ width: '110px', height: '27px' }} />
                                    </Button>
                                    <Button component={RouteLink} onClick={handleRouteClick('/items?category=golf', true)}>GOLF</Button>
                                    <Button component={RouteLink} onClick={handleRouteClick('/items?category=height', true)}>ELEVATOR</Button>
                                    {stuff?.length > 0 ? <Button component={RouteLink} to={'/order/form'}>BAG</Button>
                                    : <Button component={RouteLink} to={'/inquiry-home'}>CONTACT</Button>}
                                    <Button onClick={handleLoginClick}>{me ? 'MYPAGE' : 'LOGIN'}</Button>
                                    {!searchExpanded ? <IconButton onClick={handleSearchIconClick}><SearchIcon style={{ color: colors.textPrimary }} /></IconButton>
                                    : <div style={{ minWidth: '240px', display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
                                        <TextField
                                            autoFocus
                                            variant='outlined'
                                            size='small'
                                            color='white'
                                            style={{ caretColor: 'white' }}
                                            InputProps={{
                                                style: { color: 'white' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon style={{ color: 'white' }} onClick={() => { setSearchExpanded(false); }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={keyword}
                                            onChange={event => setKeyword(event.target.value)}
                                            onBlur={() => { setSearchExpanded(false); }}
                                            onKeyPress={(ev) => {
                                                if (ev.key === 'Enter') {
                                                    ev.preventDefault();
                                                    setSearchExpanded(false);
                                                    handleSearch();
                                                }
                                            }}
                                        />
                                    </div>}
                                </div>
                            </Container>}
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Toolbar />
                
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
                    <MainGuide />
                    {children}
                </div>
                
                <Footer />
            </div>
            
            <div style={{ backgroundColor: 'white', position: 'fixed', bottom: 0, width: '100%' }} ref={footContainer} />
		</div>
	);
}

export default MainLayout;