import React, { useContext } from 'react';

import globals from 'appglobals';
import { AppContext } from 'contexts/app.context';


const TestPage4 = () => {
    const { showSnackbar, showAlert, showDialog } = useContext(AppContext);

    const handleSnackbar = () => {
        showSnackbar('snackbar');
    }

    const handleAlert = () => {
        showAlert('alert');
    }

    const handleDialog = () => {
        const data = {
            title: 'title01',
            message: 'Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.',
            first: {
                text: 'first',
                onClick: () => {
                    console.log('first');
                }
            },
            second: {
                text: 'second',
                onClick: () => {
                    console.log('second');
                }
            }
        }
        showDialog(data);
    }

	return (
        <div>
            <button onClick={handleSnackbar}>snackbar</button>
            <button onClick={handleAlert}>alert</button>
            <button onClick={handleDialog}>dialog</button>
        </div>
	);
}

export default TestPage4;