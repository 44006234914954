import React, { useContext } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Button, Card, CardContent, Divider, Link as MuiLink} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import colors from 'appcolors';
import { AuthContext } from 'contexts/auth.context';
import MypageMenu from 'views/mypage/MypageMenu';


const useStyles = makeStyles((theme) => ({
    emphasize: {
        color: colors.primary, fontWeight: 'bold'
    }
}));

const InquiryHome = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { me } = useContext(AuthContext);

    const handleTicketClick = () => {
        if (!me) history.push('/auth/signin');
        else history.push('/inquiry');
    }

	return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
            <MypageMenu densed={true} />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'90%'} display="flex" paddingX={2} onClick={() => { history.push('/ticket'); }} style={{ cursor: 'pointer' }}>
                    <Card style={{ width: '100%' }}>
                        <CardContent>
                            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>1:1 문의</Typography>
                                <Button color='primary' onClick={handleTicketClick}>바로가기</Button>
                            </div>
                            
                            <Divider style={{ marginBottom: '4px' }} />
                            <Typography><span className={classes.emphasize}>로그인이 필요</span>하며 메모형식으로 연속문의가 가능합니다.</Typography>
                            <Typography>문의내용 및 답변 히스토리를 볼 수 있습니다.</Typography>
                            <Typography><span className={classes.emphasize}>이미지 또는 파일</span>을 첨부할 수 있습니다.</Typography>
                        </CardContent>
                    </Card>
                </Box>

                <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'90%'} display="flex" paddingX={2} onClick={() => { history.push('/inquiry'); }} style={{ cursor: 'pointer', marginTop: '40px', justifyContent: 'center' }}>
                    <Card style={{ width: '100%'}}>
                        <CardContent>
                            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>이메일 문의</Typography>
                                <Button color='primary' onClick={handleTicketClick}>바로가기</Button>
                            </div>
                            
                            <Divider style={{ marginBottom: '4px' }} />
                            <Typography>문의 후 답변은 <span className={classes.emphasize}>이메일</span>로 받습니다.</Typography>
                            <Typography>로그인이 필요하지 않습니다.</Typography>
                        </CardContent>
                    </Card>
                </Box>

                <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'90%'} display="flex" paddingX={2} style={{ marginTop: '40px', justifyContent: 'center' }}>
                    <Card style={{ width: '100%'}}>
                        <CardContent>
                            <MuiLink href='http://pf.kakao.com/_xiVpdK/chat' target='_blank'>
                                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                                    <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>카카오 채널 문의</Typography>
                                </div>
                                
                                <Divider style={{ marginBottom: '4px' }} />
                                <Typography>{'카카오채널을 통해 문의합니다.'}</Typography>
                            </MuiLink>
                        </CardContent>
                    </Card>
                </Box>
            </div>
        </div>
	);
}

export default InquiryHome;