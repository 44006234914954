import { useEffect, useState } from "react";
import axios from "axios";

import useApi from "hooks/useApi";
import API from "api";


export default function useGetMyBuckets(page, keyword) {
    const [ rows, setRows ] = useState([]);
    const [ count, setCount ] = useState(0);
    const [ rowsInPage, setRowsInPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);

    useEffect(() => {
        setRows([]);
    }, [ keyword ]);

    useApi(() => {
        let cancel;
        setLoading(true);
        const params = { 
            keyword, 
            page,
        };
        API.get('/bucket/my-buckets', { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setCount(data.totalCount);
            setRowsInPage(data.rowsInPage);
            setRows(data.rows);
            setMayMore(data.rows.length > 0);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ keyword, page ]);

    return { loading, rows, mayMore, count, rowsInPage };
}
