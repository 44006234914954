import React, { useContext } from 'react';
import axios from 'axios';
import API from '../../api';
import { MemoryContext } from 'contexts/memory.context';



const TestPage = () => {

    const { logDB } = useContext(MemoryContext);

    const handleClick = () => {
        API.get('/user/myinfo')
        .then(myinfo => {
            console.log(myinfo);
        });
    }

    const handleAxiosClick = () => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_HOST}/hook/iamport`,
            data: {
                firstName: 'Fred',
                lastName: 'Flintstone'
            }
        })
        .then((data) => {
            console.log(data)
        })
    }

    const handleLogDB = () => {
        const data = {
            division: 'info',
            code: 'tracking',
            message: 'path1',
        };
        logDB(data);
    }

	return (
		<div>
			<button onClick={handleClick}>button</button>
            <button onClick={handleAxiosClick}>button2</button>
            <button onClick={handleLogDB}>logDB</button>
		</div>
	);
}

export default TestPage;