import React, { useState, useEffect } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Card, CardContent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import API from 'api';
import colors from 'appcolors';
import MypageMenu from '../MypageMenu';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import ClaimItem from './ClaimItem';


const useStyles = makeStyles((theme) => ({

}));

const MyClaimPage = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const [ claims, setClaims ] = useState();
    useEffect(() => {
        API.get('/claim/my-claims')
        .then(setClaims);
    }, []);

	return (
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <MypageMenu densed={true} />
            
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '16px' }}>취소∙반품∙교환 현황</Typography>

                        {claims ? <>
                            {claims.length > 0 ? <>
                                {claims.map((claim) => <ClaimItem key={claim.id} claim={claim} />)}
                            </> : <Nodata></Nodata>}
                        </>: <Loading />}

                    </CardContent>
                </Card>
            </Box>
        </div>
	);
}

export default MyClaimPage;