import React, { useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Card, CardContent } from '@material-ui/core';
import colors from 'appcolors';
import MypageMenu from '../MypageMenu';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import useGetMyBuckets from './useGetMyBuckets';
import BucketItem from './BucketItem';



const MyOrdersPage = () => {
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(0);
    const { loading, rows, mayMore, count, rowsInPage } = useGetMyBuckets(page, keyword);

	return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <MypageMenu densed={true} />
            
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingBottom={'8px'} ml={isHandset ? 0 : 2}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '16px' }}>주문목록</Typography>

                        {loading ? <Loading /> : <>
                            {rows.length > 0 ? <>
                                {rows.map((row) => <BucketItem key={row.id} bucket={row} />)}

                            </> : <Nodata></Nodata>}
                        </>}

                    </CardContent>
                </Card>
            </Box>
        </div>
	);
}

export default MyOrdersPage;