import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Card, CardContent, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import API from 'api';
import colors from 'appcolors';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import Button from 'components/Button';
import AddressItem from './AddressItem';
import MypageMenu from '../MypageMenu';
import { useLocation } from 'react-router-dom';


const MyAddressPage = () => {
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const [ addresses, setAddresses ] = useState();
    useEffect(() => {
        API.get('/address/addresses')
        .then(setAddresses)
    }, []);

    const { search } = useLocation();

    if (!addresses) return <Loading />

	return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <MypageMenu densed={true} />
            
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>배송지 관리</Typography>
                        <Divider />

                        <Box marginTop={2}>
                            {addresses.length === 0 ? <Nodata><Typography variant='body2' style={{ color: colors.textSecondary }}>설정된 배송지정보가 없습니다.</Typography></Nodata> : <>
                                {addresses.map(address => <Box key={address.id} marginBottom={1}>
                                    <AddressItem address={address} />
                                </Box>)}
                            </>}
                        </Box>
                        <Button variant='outlined' color='primary' size='small' style={{ marginTop: '16px', borderColor: colors.primary, color: colors.primary }} onClick={() => { history.push({pathname: '/mypage/address-form', search }) }}>배송지 추가</Button>
                    </CardContent>
                </Card>
            </Box>
        </div>
	);
}

export default MyAddressPage;