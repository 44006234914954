import React, { useState, useEffect } from 'react';
import { Container, Grid, makeStyles, useTheme, useMediaQuery, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Card, CardContent, ButtonGroup, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import Button from 'components/Button';
import colors from 'appcolors';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';



const itemCategories = [
    { key: 'golf', name: '골프화' },
    { key: 'height', name: '키높이' },
    { key: 'sneakers', name: '운동화' },
]

const useStyles = makeStyles((theme) => ({
    label: { color: colors.textSecondary }
}));

const size_options = [ 210, 215, 220, 225, 230, 235, 240, 245, 250, 255, 260, 265, 270, 280, 285, 290 ]
export default function Filter({ params, setParams, withSize = false }) {
	const classes = useStyles();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const [ filterOpen, setFilterOpen ] = useState(true);
    useEffect(() => {
        setFilterOpen(!isHandset);
    }, [ isHandset ]);

    const handleFilterButtonClick = () => {
        setFilterOpen(val => !val);
    }

    const [ keyword, setKeyword ] = useState('');
    const handleSearchClick = () => {
        setParams({
            ...params,
            keyword
        });
    }
    const handleKeywordInputKeydown = (event) => {
		if(event.key === 'Enter') {
			event.preventDefault();
			handleSearchClick();
		}
	}

	return (
        <Container maxWidth="md" style={{ padding: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ButtonGroup color={'primary'} style={{ backgroundColor: 'white' }}>
                    {itemCategories.map(category => <Button 
                        key={category.key}
                        variant={params.category === category.key ? 'contained' : 'outlined'} 
                        style={{ color: params.category === category.key ? 'white' : colors.primary }} 
                        disableElevation={params.category === category.key}
                        onClick={() => { setParams({ ...params, category: category.key }) }}
                    >{category.name}</Button>)}
                </ButtonGroup>

                {isHandset && <div style={{ marginLeft: '16px' }}>
                    <Button onClick={handleFilterButtonClick} variant='contained' size="small" color="primary" style={{ color: 'white' }} startIcon={<FilterListOutlinedIcon style={{ color: 'white' }} />}>검색옵션</Button>
                </div>}
            </div>

            {filterOpen && <Card style={{ marginTop: '8px'}}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" className={classes.label}>스파이크 유무</FormLabel>
                                <RadioGroup name="spike" value={params.spike} onChange={e => setParams({ ...params, spike: e.target.value})}>
                                    <FormControlLabel value="" control={<Radio checked={!params?.spike} />} label="모두" />
                                    <FormControlLabel value="spike" control={<Radio />} label="스파이크" />
                                    <FormControlLabel value="spikeless" control={<Radio />} label="스파이크 없음" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={6} md={3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" className={classes.label}>보아 시스템</FormLabel>
                                <RadioGroup name="lace" value={params.lace} onChange={e => setParams({ ...params, lace: e.target.value})}>
                                    <FormControlLabel value="" control={<Radio checked={!params?.lace} />} label="모두" />
                                    <FormControlLabel value="lace" control={<Radio checked={params?.lace === 'lace'} />} label="레이스" />
                                    <FormControlLabel value="dial" control={<Radio checked={params?.lace === 'dial'} />} label="다이얼" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" className={classes.label}>성별착용</FormLabel>
                                <RadioGroup name="gender" value={params.gender} onChange={e => setParams({ ...params, gender: e.target.value})}>
                                    <FormControlLabel value="" control={<Radio checked={!params?.gender} />} label="모두" />
                                    <FormControlLabel value="male" control={<Radio checked={params?.gender === 'male'} />} label="남성전용" />
                                    <FormControlLabel value="female" control={<Radio checked={params?.gender === 'female'} />} label="여성전용" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%' }}>
                                <FormLabel className={classes.label}>키워드</FormLabel>
                                <TextField 
                                    variant="outlined" 
                                    placeholder="상품 이름 또는 코드 입력"
                                    style={{ marginTop: '8px', width: '100%' }}
                                    size="small"
                                    onKeyDown={handleKeywordInputKeydown}
                                    value={keyword}
                                    onChange={e => setKeyword(e.target.value)}
                                />
                                
                                {withSize && <TextField
                                    select
                                    name={'proxyId'}
                                    style={{ marginTop: '4px', width: '100%' }}
                                    size="small"
                                    variant="outlined"
                                    onChange={e => {
                                        let tobe = e.target.value;
                                        if (tobe === -1) tobe = null;
                                        setParams({
                                            ...params,
                                            optionName: tobe
                                        })
                                    }}
                                    value={params.optionName || -1}
                                >
                                    <MenuItem value={-1}>사이즈 선택</MenuItem>
                                    {size_options.map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                                </TextField>}

                                <div style={{ flex: 1 }} />
                                <Button variant="contained" color="primary" style={{ alignSelf: 'flex-end', marginTop: '16px', color: 'white' }} onClick={handleSearchClick} >검색</Button>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>}
        </Container>
	);
}