import React, { useState, useEffect, useContext, useMemo, useRef, useCallback } from 'react';
import { Container, Grid, Typography, useTheme, useMediaQuery, CardContent, Card, CardActionArea, CardMedia, Portal } from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import LocalMallIcon from '@material-ui/icons/LocalMallOutlined';
import Button from 'components/Button';
import colors from 'appcolors';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import useItemSearch from './useItemSearch';
import { number_format_pipe, item_category_pipe } from 'pipes';
import { BagContext } from 'contexts/bag.context';
import { AppContext } from 'contexts/app.context';


const ItemSearch = ({ params }) => {
    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

	const [ page, setPage ] = useState(0);

    const { 
        rows,
        loading,
        mayMore,
    } = useItemSearch(params, page);

    useEffect(() => {
        setPage(0);
    }, [ params ]);

    // 하단 쇼핑백 내역 노출
    const { footContainer, setTakeupFooter } = useContext(AppContext);
    const { stuff } = useContext(BagContext);
    useEffect(() => {
        if (stuff?.length > 0) {
            setTakeupFooter('120px');
            return () => { setTakeupFooter(0); }
        }
    }, [ stuff ]);
    const bagPrice = useMemo(() => {
        if (stuff) {
            let sum = 0;
            stuff.forEach(item => sum += item.price * item.count);
            return sum;
        }
    }, [ stuff ]);
    const handleGoOrderForm = () => {
        history.push('/order/form');
    }
    // end

    // pagination
    const observer = useRef();
    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && mayMore) {
                // console.log('visible');
                setPage(val => val + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [ loading, mayMore ]);
    // end: pagination

    const itemMainImage = (fileInfos) => {
        if (fileInfos?.length > 0) {
            const main = fileInfos.find(el => !!el.main);
            if (main) return main?.downloadURL;
            else return fileInfos[0].downloadURL;
        }
    } 

    const categoryBackgroundColor = (category) => {
        switch(category) {
            case 'height': return '#3f51b5aa';
            case 'sneakers': return '#f44336aa';
            default: return colors.primary + 'cc';
        }
    }

	return (
        <>
            <Container style={{ paddingBottom: '24px'}}>
                {rows.length > 0 ? <>
                    <Grid container style={{ marginTop: isHandset ? '16px' : '40px' }} spacing={2}>
                        {rows.map((item, index) => <Grid item key={item.id} xs={6} sm={4} md={3}>
                            <Card component={RouterLink} to={'/item/' + item.code} style={{ textDecoration: 'none' }} ref={(index + 1 < rows.length) ? null : lastElementRef}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        alt={item.name}
                                        style={{ width: '100%', aspectRatio: '1 / 1' }}     // image shifting 방지
                                        image={itemMainImage(item.fileInfos)}
                                    />
                                    <CardContent style={{ backgroundColor: 'white' }}>
                                        <Typography gutterBottom variant="h6" component="h2" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</Typography>
                                        {item.normalPrice ? <>
                                            <Typography variant="h5" style={{ color: '#ae0000', textDecorationLine: 'line-through' }}>{number_format_pipe(item.normalPrice) + '원'}</Typography>
                                            <Typography variant="h6"><span style={{ color: colors.textSecondary}}>판매가</span> {number_format_pipe(item.price) + '원'}</Typography>
                                        </> : <>
                                            <Typography variant="h5" style={{ color: '#ae0000' }}>{number_format_pipe(item.price) + '원'}</Typography>
                                            <Typography variant="h6">&nbsp;</Typography>
                                        </>}
                                    </CardContent>
                                    <div style={{ position: 'absolute', top: 0, left: 0, padding: '2px 8px', backgroundColor: categoryBackgroundColor(item.category) }}>
                                        <Typography variant='body2' style={{ color: 'white' }}>{item_category_pipe(item.category)}</Typography>
                                    </div>
                                </CardActionArea>
                            </Card>
                        </Grid>)}
                    </Grid>
                </> : <Nodata style={{ marginTop: '24px' }}>조건을 만족하는 상품이 없습니다.</Nodata>}
                {loading && <Loading />}
            </Container>

            {/* 쇼핑백 고정 바 */}
            {stuff?.length > 0 && <Portal container={footContainer.current}>
                <div style={{ backgroundColor: 'rgb(248,248,248)', borderTop: '1px solid #dddddd', maxHeight: '120px', padding: '16px 0' }}>
                    <Container style={{ height: '100%', display: 'flex', flexDirection: isHandset ? 'column' : 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LocalMallIcon fontSize='small' style={{ color: colors.textPrimary }} />
                            <Typography variant={isHandset ? 'body2' : 'h6'} style={{ marginLeft: '8px' }}>쇼핑백에 상품이 있습니다.</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', alignSelf: isHandset ? 'flex-end' : 'center' }}>
                            <Typography variant="h3" style={{ color: colors.textNeutral, fontWeight: 'bold', marginRight: '16px' }}>{number_format_pipe(bagPrice)} 원</Typography>
                            <Button color="primary" variant="contained" size={isHandset ? 'medium' : 'large'} style={{ color: 'white' }} onClick={handleGoOrderForm}>보러가기</Button>
                        </div>
                    </Container>
                </div>
            </Portal>}
        </>
	);
}

export default ItemSearch;