import React, { useState, useMemo } from 'react';
import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import colors from 'appcolors';
import useQuery from 'hooks/useQuery';
import Guide from 'components/Guide';
import EmailAuthentication from './EmailAuthentication';
import MobileAuthentication from './MobileAuthentication';


const useStyles = makeStyles((theme) => ({

}));

const AuthPendingPage = () => {
	const theme = useTheme();

    const query = useQuery(); 
    const fromSignup = useMemo(() => {
        if (query) {
            return query.get('fromSignup') === 'Y';
        }
    }, [ query ]);

    const [ pageState, setPageState ] = useState('choice');

	return (
		<div style={{ flex: 1, display: 'flex', padding: '40px 16px', justifyContent: 'center' }}>
            <Box width={{ xs: '100%', sm: '500px', md: '600px' }} display="flex" flexDirection="column">
                {fromSignup ? <>
                    <Typography variant="h6" style={{ color: colors.gray600, marginBottom: '5px' }}>JOIN</Typography>
                    <Typography variant="h2" style={{ color: colors.textNeutral, fontWeight: 'bold' }}>회원가입</Typography>
                </> : <>
                    <Typography variant="h2" style={{ color: colors.textNeutral, fontWeight: 'bold' }}>사용자 인증</Typography>
                    {pageState === 'choice' && <Guide style={{ marginTop: '24px' }}>인증이 되지 않은 계정입니다. 인증을 완료해 주세요.</Guide>}
                </>}
                
                {pageState === 'choice' && <Typography variant="h6" style={{ marginTop: '40px', color: colors.textSecondary }}>다음의 인증방법 중 하나를 선택해 주세요.</Typography>}

                <Box sx={{ mt: 3 }}>
                    {pageState === 'choice' && <Box sx={{ display: 'flex' }}>
                        <Box onClick={() => { setPageState('mobile') }} sx={{ mr: 1, cursor: 'pointer', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', py: 2, flex: 1, bgcolor: colors.primaryDark }}><Typography variant="body1" style={{ color: 'white' }}>핸드폰번호로 인증하기</Typography></Box>
                        <Box onClick={() => { setPageState('email') }} sx={{ cursor: 'pointer', borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', py: 2, flex: 1, bgcolor: colors.secondaryDark }}><Typography variant="body1" style={{ color: 'white' }}>이메일로 인증하기</Typography></Box>
                    </Box>}

                    {pageState === 'mobile' && <MobileAuthentication setMethodState={setPageState} />}

                    {pageState === 'email' && <EmailAuthentication setMethodState={setPageState} />}
                </Box>
                
            </Box>
		</div>
	);
}

export default AuthPendingPage;