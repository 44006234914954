import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';

let theme = createTheme({
    palette,
    typography,
});

theme = responsiveFontSizes(theme);

export default theme;