import { useEffect, useState } from "react";
import axios from "axios";
import API from "api";


export default function useSearchProxy(params, page) {
    const [ rows, setRows ] = useState([]);
    const [ totalCount, setTotalCount ] = useState(0);
    const [ rowsInPage, setRowsInPage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);

    useEffect(() => {
        setRows([]);
        setMayMore(true);
    }, [ params ]);

    useEffect(() => {
        let cancel;
        setLoading(true);
        const data = { 
            keyword: params?.keyword, 
            category: params?.category,
            spike: params?.spike,
            lace: params?.lace,
            gender: params?.gender,
            plusEvent: true,
            optionName: params?.optionName,
            page,
        };
        API.get('/item/search-proxy', { params: data, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setTotalCount(data.totalCount);
            setRowsInPage(data.rowsInPage);
            setRows(prevs => prevs.concat(data.rows));
            setMayMore(data.rows.length > 0);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.info('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });

        return () => cancel();
    }, [ params, page ]);

    return { loading, rows, mayMore, totalCount, rowsInPage };
}
