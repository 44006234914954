import React from 'react';




const BillKeyTest = () => {

	return (
        <div>
            <button>빌키 가져오기 테스트</button>
        </div>
	);
}

export default BillKeyTest;