import React, { createContext, useState } from 'react';
import API from 'api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
	// const [ token, setToken ] = useState(null);
	const [ me, setMe ] = useState(null);
	
	// token 방식에서, 쿠키 방식으로 변경해서 사용안함
	// const setAuthInfo = async ({ token, user }) => {
	// 	try {
	// 		localStorage.setItem('token', token);
	// 		setToken(token);
	// 		setMe(user);
	// 	}
	// 	catch(error) {
	// 		console.error(error);
	// 		clearAuthInfo();
	// 	}
	// }

	const clearAuthInfo = async () => {
		try {
			setMe(null);
		}
		catch(error) {
			console.error(error);
		}
	};

	// call after myinfo changed
	const fetchMyinfo = async () => {
		try {
			const user = await API.get('/auth/myinfo');
			setMe(user);
		}
		catch(error) {
			console.error(error);
			clearAuthInfo();
		}
	}

	// login page control
	const [ loginRedirectURL, setLoginRedirectURL ] = useState('/');
	const getLoginRedirectURL = () => {
		if (!loginRedirectURL) return '/';
		const exceptions = [
			'/auth/signin',
			'/auth/signup',
			'/auth/find-email',
			'/auth/find-password',
		];
		if (exceptions.indexOf(loginRedirectURL) > -1) return '/';
		return loginRedirectURL;
	}

	const history = useHistory();
	const gotoLoginRedirectURL = () => {
		const url = getLoginRedirectURL();
		
		if (url === '/') window.location.href = process.env.REACT_APP_HOST;
		else history.push(url);
	}

	return (
		<AuthContext.Provider
			value={{    
				me,
				clearAuthInfo,
				fetchMyinfo,

				setLoginRedirectURL,
				getLoginRedirectURL,
				gotoLoginRedirectURL,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export {
	AuthContext,
	AuthContextProvider
};