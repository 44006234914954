import React, { useState, useContext } from 'react';
import { Container, Typography, useTheme, useMediaQuery, Link, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import API from 'api';
import colors from 'appcolors';
import { AuthContext } from 'contexts/auth.context';
import Ticket from './Ticket';
import useApi from 'hooks/useApi';
import GuideView from 'components/Guide';
import Loading from 'components/Loading';


const TicketHomePage = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { me } = useContext(AuthContext);

    const [tickets, setTickets] = useState();
    const [loading, setLoading] = useState(false);

    useApi(() => {
        if (me) {
            loadData(); 
        }
    }, [ me ]);

    const loadData = () => {
        setLoading(true);
        API.get('/ticket/my-tickets')
        .then(setTickets)
        .finally(() => {
            setLoading(false);
        });
    }

    const [ categories, setCategories ] = useState();
    useApi(() => {
        API.get('/closed/inquiry-categories')
        .then(setCategories);
    }, []);

    if (!categories) return null;

	return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray }}>
            <Container style={{ paddingTop: isMobile ? '12px' : '24px', paddingBottom: isMobile ? '24px' : '40px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', marginBottom: '24px', borderBottom: '1px solid #bdbdbd', paddingBottom: '8px' }}>
                    <Typography variant="h4" style={{ fontWeight: 'bold' }}>1:1 문의</Typography>
                    <Button type="button" variant="contained" color="primary" disableElevation component={RouterLink} to={'/ticket/write'} style={{ borderRadius: 0, marginLeft: '8px', fontWeight: 'bold', color: 'white' }}>문의하기</Button>
                </div>

                {!me && <GuideView>1:1문의는 로그인이 필요합니다. 로그인하지 않고 문의하려면 <Link component={RouterLink} to="/inquiry">이메일 문의</Link> 하세요.</GuideView>}

                {!loading ? <>
                    {tickets && <>
                        {tickets.length > 0 ? <>
                            {tickets.map(item => <Ticket key={item.id} ticket={item} loadData={loadData} categories={categories} />)}
                        </> : <GuideView>"문의하기" 버튼을 클릭하세요.</GuideView>}
                    </>}
                </> : <>
                    <Loading />
                </>}
            </Container>
        </div>
    );
}

export default TicketHomePage;