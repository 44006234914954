
import moment from 'moment';

const datetimePipe = (dateText) => {
    if (!dateText) return '-';
    const date = new Date(dateText);
    return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일 ${date.getHours()}시 ${date.getMinutes()}분`;
}

const sexPipe = (me) => {
    return me.sex === 'man' ? '남자' : '여자';
};

const number_format_pipe = (x) => {
    if (!x) return x;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const item_category_pipe = (category) => {
    if (category === 'golf') return '골프화';
    else if (category === 'height') return '키높이화';
    else if (category === 'sneakers') return '운동화';
    else return '-';
}

const spike_pipe = (spike) => {
    if (spike === 'spike') return '스파이크';
    else return '없음';
}

const lace_pipe = (lace) => {
    if (lace === 'lace') return '레이스';
    else if (lace === 'dial') return '다이얼';
    else return '-';
}

const item_gender_pipe = (gender) => {
    if (gender === 'man') return '남성';
    else if (gender === 'woman') return '여성';
    else return '남여공용';
}

const mobile_pipe = (mobile) => {
    if (!mobile) return '-';
    if (mobile.length === 11) return `${mobile.slice(0, 3)}-${mobile.slice(3, 7)}-${mobile.slice(7, 11)}`;
    return mobile;
}

const stuff_summary_pipe = (stuff) => {
    if (!stuff || stuff.length === 0) return '-';
    
    const span = stuff[0].item.name;
    if (stuff.length === 1) return span;
    else return `${span} 외 ${stuff.length - 1}종`;
}

const bucket_state_pipe = (state) => {
    switch(state) {
        case 'waiting': return '주문확인전';
        case 'ready': return '상품준비중';
        case 'sended': return '배송중';
        case 'received': return '배송완료';
        case 'done': return '구매완료';
        case 'canceled': return '취소완료';
        case 'returned': return '반품완료';
        case 'replaced': return '교환완료';
        case 'claiming': return '클레임 진행중';
        default: return '-';
    }
}

const bucket_image_pipe = (bucket) => {
    if (bucket?.stuff?.item?.fileInfos?.length > 0) {
        return bucket.stuff.item.fileInfos[0].downloadURL;
    }
    return null;
}

const bucket_name_pipe = (bucket) => {
    return bucket?.stuff?.item?.name;
}

const proxy_options_pipe = (proxy) => {
    const options = proxy?.proxyOptions;
    if (options?.length > 0) {
        return options.map(el => el.option.name).join(', ');
    }
    else return '-';
}

const proxy_options_event_pipe = (proxy) => {
    return proxy_options_pipe(proxy) + (proxy.plusEventPrice ? ` (1+1 이벤트, 가격: ${number_format_pipe(proxy.plusEventPrice)}원)` : '');
}

const claimStatePipe = (claim) => {
    if (claim.division === 'return') {
        switch (claim.state) {
            case 'reported': return '반품요청중';
            case 'rejected': return '거절처리됨';
            case 'retrieving': return '수거진행중';
            case 'done': return '반품완료';
            default: return '-';
        }
    }
    else {
        switch (claim.state) {
            case 'reported': return '교환요청중';
            case 'rejected': return '거절처리됨';
            case 'retrieving': return '수거진행중';
            case 'resending': return '재배송준비중';
            case 'done': return '교환완료';
            default: return '-';
        }
    }
}

const address_pipe = (destination) => {
    if (!destination) return '-';
    return `[${destination.zipcode}] ${destination.address}, ${destination.addressDetail}`;
}

const coupon_info_pipe = (coupon) => {
    if (!coupon) return coupon;

    let name;
    if (coupon.code === 'day15') {
        name = '멤버십 Day15';
    }
    else if (coupon.code === 'manager_generate') {
        name = '매니저 쿠폰';
    }
    else if (coupon.code === 'thankyou') {
        name = '땡큐 쿠폰';
    }
    
    const coupon_a = { ...coupon };
    coupon_a['name'] = name;
    return coupon_a;
}

const coupon_available_period_pipe = (coupon) => {
    if (!coupon) return '-';
    if (!coupon.endDate) return '무제한';
    else {
        if (coupon.startDate) {
            return `${coupon.startDate} ~ ${coupon.endDate}`;
        }
        else {
            return `${coupon.endDate} 까지`;
        }
    }
}

const coupon_state_pipe = (coupon) => {
    if (!coupon) return 'unavailable';
    if (coupon.usedAt) return 'used';
    if (!coupon.endDate) return 'available';
    if (moment().subtract(1, 'days').isAfter(moment(coupon.endDate))) return 'expired';
    else return 'available';
}

const platform_pipe = (platform) => {
    if (platform === 'coupang') return '쿠팡';
    else if(platform === 'naver') return '네이버';
    else return '다른 플랫폼';
}


export {
    datetimePipe,
    sexPipe,
    number_format_pipe,
    item_category_pipe,
    spike_pipe,
    lace_pipe,
    item_gender_pipe,
    mobile_pipe,
    stuff_summary_pipe,
    bucket_state_pipe,
    bucket_image_pipe,
    bucket_name_pipe,
    proxy_options_pipe,
    proxy_options_event_pipe,
    claimStatePipe,
    address_pipe,
    coupon_info_pipe,
    coupon_available_period_pipe,
    coupon_state_pipe,
    platform_pipe,
}