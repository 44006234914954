import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { Box, Button, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from 'api';
import colors from 'appcolors';


const Cafe24SigninPage = () => {
    const { fetchMyinfo, gotoLoginRedirectURL } = useContext(AuthContext);

	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const [ state, setState ] = useState('login');  // login, need_auth

    const handleAuthenticateClick = (memberId) => () => {
        history.push(`/auth/cafe24-signup?memberId=${memberId}`);
    }

    const passwordRef = useRef();

	return (
		<div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: isHandset ? 'stretch' : 'center', justifyContent: 'center', paddingBottom: '40px', }}>
			<Formik
				initialValues={{
					memberId: '',
					password: '',
				}}
				validationSchema={Yup.object({
					memberId: Yup.string().required('필수입력입니다.'),
					password: Yup.string().required('필수입력입니다.'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					const params = values;
					API.get('/auth/cafe24-signin', { params })
					.then((data) => {
                        if (data.successCode === 'need_cafe24_mobile_auth') {
                            // cafe24 사이트에 가입한 적은 있지만 여기에서는 가입안한 경우
                            setState('need_auth');
                        }
                        else {
                            fetchMyinfo();
                            gotoLoginRedirectURL();
                        }
					})
					.finally(() => { setSubmitting(false); });
				}}
			>
				{({ values, errors, dirty, touched, isSubmitting, isValid, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit} style={{ padding: '40px 16px', display: 'flex', justifyContent: 'center' }} autoComplete="off">
						<Box width={{ xs: '88%', sm: '400px' }} display="flex" flexDirection="column" >
                            {state === 'login' && <>
                                <Typography variant="h6" style={{ color: colors.gray600, marginBottom: '5px' }}>LOGIN</Typography>
                                <Typography variant="h2" style={{ color: colors.textNeutral, fontWeight: 'bold' }}>다른 MNX15 사이트에서 가입한 아이디와 비밀번호를 입력해주세요.</Typography>
                                
                                <TextField
                                    label={'아이디'}
                                    variant="outlined"
                                    type="text"
                                    size='small'
                                    style={{ marginTop: '24px' }}
                                    autoFocus
                                    required={true}
                                    onChange={handleChange('memberId')}
                                    onBlur={handleBlur('memberId')}
                                    helperText={(touched.memberId && errors.memberId) || '아이디를 입력하세요.'}
                                    error={!!(touched.memberId && errors.memberId)}
                                    value={values.memberId}
                                />
                                
                                <TextField
                                    inputRef={passwordRef}
                                    label={'비밀번호'}
                                    variant="outlined"
                                    type="password"
                                    size='small'
                                    style={{ marginTop: '24px' }}
                                    autoComplete="new-password"     // chrome 에서 autocomplete 를 막기위함.
                                    required={true}
                                    onChange={handleChange('password')}
                                    onBlur={handleBlur('password')}
                                    helperText={(touched.password && errors.password) || '비밀번호를 입력하세요.'}
                                    error={!!(touched.password && errors.password)}
                                    value={values.password}
                                />
                
                                <Box display={'flex'} flexDirection={{xs: 'column-reverse', sm: 'row'}} alignItems={{ xs: 'center', sm: 'flex-end' }} justifyContent={'space-between'} marginTop={'24px'} >
                                    <Button variant="contained" type="submit" color="primary" disabled={!dirty || !isValid || isSubmitting} style={{ color: 'white', alignSelf: isHandset ? 'stretch' : 'default' }}>로그인</Button>
                                </Box>
                            </>}

                            {state === 'need_auth' && <Box style={{ marginTop: '36px' }}>
                                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>{'입력하신 아이디의 회원이 있음을 확인했습니다.\n아이디 연동을 위해서 인증과정이 필요합니다. 진행하시겠습니까?'}</Typography>
                                <Button variant='contained' color="primary" style={{ color: 'white', marginTop: '24px' }} onClick={handleAuthenticateClick(values.memberId)}>인증진행하기</Button>
                            </Box>}
						</Box>
					</form>
				)}
			</Formik>
		</div>
	);
}

export default Cafe24SigninPage;