import React, { useState, useEffect, useContext, useMemo, useCallback, useRef, Fragment } from 'react';
import { Container, Box, Grid, Typography, makeStyles, useTheme, useMediaQuery, Button, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import colors from 'appcolors';


const useStyles = makeStyles((theme) => ({
	container: { padding: '24px 0' },
	title: { color: colors.textSecondary, marginBottom: '16px' },
}));

const PayInfo = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

	return (
		<div className={classes.container}>
			<Typography variant="body1" className={classes.title}>결제정보</Typography>
            <Typography style={{ whiteSpace: 'pre-line' }}>{'고액결제의 경우 안전을 위해 카드사에서 확인전화를 드릴 수도 있습니다. 확인과정에서 도난 카드의 사용이나 타인 명의의 주문등 정상적인 주문이 아니라고 판단될 경우 임의로 주문을 보류 또는 취소할 수 있습니다.\n무통장 입금은 상품 구매 대금은 PC뱅킹, 인터넷뱅킹, 텔레뱅킹 혹은 가까운 은행에서 직접 입금하시면 됩니다.\n주문시 입력한 입금자명과 실제입금자의 성명이 반드시 일치하여야 하며, 7일 이내로 입금을 하셔야 하며 입금되지 않은 주문은 자동취소 됩니다.'}</Typography>
		</div>
	);
}

export default PayInfo;