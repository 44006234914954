import React from 'react';
import Box from '@material-ui/core/Box';
import { Button, Checkbox, FormControl, InputLabel, makeStyles, MenuItem, Select, Typography, styled } from '@material-ui/core';
import CustomButton from 'components/Button';





const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 500,
  },
}));


const ViewTestPage = () => {
  const classes = useStyles();
  
  return (
    <>
      <div className={classes.root}>
        <Typography component="div" variant="body1">
          <Box color="primary.dark">primary.dark</Box>
          <Box color="primary.main">primary.main</Box>
          <Box color="primary.light">primary.light</Box>
          <Box color="secondary.dark">secondary.dark</Box>
          <Box color="secondary.main">secondary.main</Box>
          <Box color="secondary.light">secondary.light</Box>
          <Box color="error.main">error.main</Box>
          <Box color="warning.main">warning.main</Box>
          <Box color="info.main">info.main</Box>
          <Box color="success.main">success.main</Box>
          <Box color="text.primary">text.primary</Box>
          <Box color="text.secondary">text.secondary</Box>
          <Box color="text.disabled">text.disabled</Box>
        </Typography>

        <br></br>
        <div className={classes.container}>
          <Typography variant="h1" gutterBottom>
            h1. Heading
          </Typography>
          <Typography variant="h2" gutterBottom>
            h2. Heading
          </Typography>
          <Typography variant="h3" gutterBottom>
            h3. Heading
          </Typography>
          <Typography variant="h4" gutterBottom>
            h4. Heading
          </Typography>
          <Typography variant="h5" gutterBottom>
            h5. Heading
          </Typography>
          <Typography variant="h6" gutterBottom>
            h6. Heading
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
          </Typography>
          <Typography variant="body1" gutterBottom>
            body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
            dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="body2" gutterBottom>
            body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
            unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
            dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            button text
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            caption text
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            overline text
          </Typography>
        </div>
        <div>
          <Button variant="contained">Default</Button>
          <Button variant="contained" color="primary" style={{ borderRadius: 0 }} disableElevation>
            Primary
          </Button>
          <Button variant="contained" color="secondary">
            Secondary
          </Button>
          <Button variant="contained" disabled>
            Disabled
          </Button>
          <Button variant="contained" color="primary" href="#contained-buttons">
            Link
          </Button>
          <CustomButton variant="contained" color="primary" disableElevation>custom button</CustomButton>
        </div>
        <div>
        <div>
          <Checkbox
            checked={true}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Checkbox
            defaultChecked
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
          <Checkbox disabled inputProps={{ 'aria-label': 'disabled checkbox' }} />
          <Checkbox disabled checked inputProps={{ 'aria-label': 'disabled checked checkbox' }} />
          <Checkbox
            defaultChecked
            indeterminate
            inputProps={{ 'aria-label': 'indeterminate checkbox' }}
          />
          <Checkbox
            defaultChecked
            color="default"
            inputProps={{ 'aria-label': 'checkbox with default color' }}
          />
          <Checkbox
            defaultChecked
            size="small"
            inputProps={{ 'aria-label': 'checkbox with small size' }}
          />

          <FormControl className={classes.formControl} style={{ width: '100px'}}>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              variant="outlined"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={age}
              // onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>
        </div>
      </div>
    </>
  );
}

export default ViewTestPage