import React, { useState, useContext, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link as routeLink } from 'react-router-dom';
import DaumPostcodeModal from 'components/DaumPostcodeModal';
import Button from 'components/Button';
import TextField from 'components/TextField';
import API from 'api';
import colors from 'appcolors';
import useOrderFormStyles from './useOrderFormStyles';
import OrderFormContext from './order-form.context';
import { AuthContext } from 'contexts/auth.context';
import MyAddressesModal from './MyAddressesModal';
import useApi from 'hooks/useApi';



const validateFieldNames = [ 'name', 'address', 'addressDetail', 'mobile', 'email' ];
const ReceiverInfo = () => {
	const classes = useOrderFormStyles();

    const { me } = useContext(AuthContext);

    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue, getFieldProps } = useContext(OrderFormContext);

    // daum post code
    const [ postcodeModalOpen, setPostcodeModalOpen ] = useState(false);

    // 내가 저장한 배송지
    const [ myAddresses, setMyAddresses ] = useState();
    useApi(() => {
        if (me) {
            API.get('/address/addresses')
            .then((addresses) => {
                setMyAddresses(addresses);
                if (addresses.length > 0) onAddressSelected(addresses[0]);
            });
        }
    }, [ me ]);

    const [ addressModalOpen, setAddressModalOpen ] = useState(false);
    const onEditClick = () => {
        if (!me) return;
        setAddressModalOpen(true);
    }
    const onAddressSelected = (address) => {
        setAddressModalOpen(false);

        const fileds = [ 'name', 'zipcode', 'address', 'addressDetail', 'mobile', 'memo' ];
        for (let field of fileds) {
            setFieldValue(field, address[field]);
        }
    }
    // end

    const errorText = useMemo(() => {
        for (let el of validateFieldNames) {
            if (touched[el] && errors[el]) return errors[el];
        }
    }, [ errors, touched ]);

	return (
		<div className={classes.section}>
            <Box display={'flex'} alignItems={'center'} marginBottom={1}>
                <Typography variant="subtitle1">받는사람정보</Typography>
                {me && <Button variant='outlined' color="primary" size="small" style={{ marginLeft: '16px', fontSize: 12, color: colors.primary }} onClick={onEditClick}>배송지선택</Button>}
                {me && <Box marginLeft={'auto'}>
                    <Typography style={{ color: colors.primary, fontSize: 14, textDecorationLine: 'underline' }} component={routeLink} to={'/mypage/myaddresses?intend=order'}>배송지관리</Typography>
                </Box>}
            </Box>
            
            <div className={classes.inputRow}>
                <div className={classes.label}>
                    <Typography variant="body1" className={classes.labelText}>이름</Typography>
                </div>
                <TextField
                    variant="outlined"
                    className={classes.inputContent}
                    required={true}
                    size="small"
                    {...getFieldProps('name')}
                />
            </div>
            <div className={classes.inputRow}>
                <div className={classes.label}>
                    <Typography variant="body1" className={classes.labelText}>배송주소</Typography>
                </div>
                <TextField
                    variant="outlined"
                    className={classes.inputContent}
                    placeholder="검색버튼을 누르세요."
                    required={true}
                    size="small"
                    disabled={true}
                    value={values.address}
                />
                <Button variant="outlined" size="small" color="primary" onClick={() => { setPostcodeModalOpen(true); }}>검색</Button>
                <DaumPostcodeModal open={postcodeModalOpen} setOpen={setPostcodeModalOpen} onComplete={data => {
                    setPostcodeModalOpen(false);
                    // console.log(data);
                    const zipcode = data.zonecode;
                    const address = data.roadAddress || data.jibunAddress;
                    setFieldValue('zipcode', zipcode);
                    setFieldValue('address', address);
                }} />
            </div>
            <div className={classes.inputRow}>
                <div className={classes.label}>
                    <Typography variant="body1" className={classes.labelText}>상세주소</Typography>
                </div>
                <TextField
                    variant="outlined"
                    className={classes.inputContent}
                    required={true}
                    size="small"
                    autoComplete='none'
                    {...getFieldProps('addressDetail')}
                />
            </div>
            <div className={classes.inputRow}>
                <div className={classes.label}>
                    <Typography variant="body1" className={classes.labelText}>연락처</Typography>
                </div>
                <TextField
                    variant="outlined"
                    className={classes.inputContent}
                    required={true}
                    size="small"
                    {...getFieldProps('mobile')}
                />
            </div>
            <div className={classes.inputRow}>
                <div className={classes.label}>
                    <Typography variant="body1" className={classes.labelText}>이메일</Typography>
                </div>
                <TextField
                    variant="outlined"
                    className={classes.inputContent}
                    required={!me}
                    size="small"
                    {...getFieldProps('email')}
                />
            </div>
            <div className={classes.inputRow}>
                <div className={classes.label}>
                    <Typography variant="body1" className={classes.labelText}>배송 요청사항</Typography>
                </div>
                <TextField
                    multiline
                    variant="outlined"
                    className={classes.inputContent}
                    placeholder="요청사항을 입력하세요."
                    required={true}
                    size="small"
                    rows={4}
                    {...getFieldProps('memo')}
                />
            </div>
            {errorText && <Typography variant='body1' style={{ color: colors.error }}>{errorText}</Typography>}

            {/* address modal */}
            <MyAddressesModal addressModalOpen={addressModalOpen} setAddressModalOpen={setAddressModalOpen} addresses={myAddresses} onAddressSelected={onAddressSelected} />
        </div>
	);
}

export default ReceiverInfo;