import { Typography } from '@material-ui/core';
import React from 'react';
import colors from '../appcolors';


export default function Guide({ children, style }) {
    return (
        <div style={{ borderLeft: '6px solid #4caf50', padding: '0.7rem 1rem', color: colors.primaryDark, backgroundColor: colors.primaryTint, ...style }}>
            <Typography variant="body2" style={{ color: '#777777', whiteSpace: 'pre-line' }}>{children}</Typography>
        </div>
    );
}

