import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import colors from 'appcolors';
import Nodata from 'components/Nodata';
import CouponItem from 'views/mypage/mycoupons/CouponItem';


const CouponSelectionModal = ({ open, setOpen, myCoupons, onCouponSelected }) => {
    const handleCouponClick = (coupon) => {
        onCouponSelected(coupon);
        setOpen(false);
    }

	return (
		<Dialog
            open={open}
            onClose={() => { setOpen(false); }}
        >
            <DialogTitle>쿠폰 선택</DialogTitle>
            <DialogContent style={{ minWidth: '240px' }}>
                {myCoupons?.length > 0 ? (myCoupons.map((coupon) => <div key={coupon.id} style={{ cursor: 'pointer' }} onClick={() => { handleCouponClick(coupon) }}><CouponItem coupon={coupon} /></div>)
                ) : <Nodata style={{ whiteSpace: 'pre-line' }}>{'사용가능한 쿠폰이 없습니다.'}</Nodata>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(false);
                }} style={{ color: colors.textSecondary }}>{'닫기'}</Button>
            </DialogActions>
        </Dialog>
	);
}

export default CouponSelectionModal;