import React, { useState, useContext } from 'react';
import { Box, Typography, Button, Divider, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import Guide from 'components/Guide';


const EmailAuthentication = ({ setMethodState, handleComplete }) => {
	const history = useHistory();

    const { showAlert } = useContext(AppContext);
    const { me, fetchMyinfo } = useContext(AuthContext);

    const [ pageState, setPageState ] = useState('ready');      // ready, sended

    const handleEmailAuth = () => {
        API.get('/auth/send-email-authentication-email')
        .then(() => {
            setPageState('sended');
            showAlert(`${me.email}로 인증 이메일을 전송했습니다.\n이메일을 확인해주세요. `)
        });
    }

    const handleReload = () => {
        fetchMyinfo()
        .then(() => { 
            if (handleComplete) {
                handleComplete();
            }
            else {
                window.location.href = '/';
            }
        });
    }

    if (!me) return null;

	return (
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>이메일 인증하기</Typography>
            </div>
            <Divider style={{ marginBottom: '16px' }} />

            {pageState === 'ready' ? <>
                <Guide>아래 "인증확인 메일 발송" 버튼을 클릭하면 <span style={{ fontWeight: 'bold' }}>{me.email}</span> 로 인증확인 메일이 발송됩니다. 받게될 메일을 확인하면 인증이 완료됩니다.</Guide>
                <div style={{ marginTop: '8px' }}><Button size="large" variant='contained' color="primary" disableElevation style={{ borderRadius: 0, color: 'white' }} onClick={handleEmailAuth}>인증확인 메일 발송</Button></div>
            </> : <>
                <Guide><span>{`이메일(${me.email})`}</span>을 확인해주세요. 인증을 완료확인하셨으면 아래 "새로고침"을 클릭해주세요.</Guide>
                <Button onClick={handleReload} variant='contained' color='primary' disableElevation style={{ borderRadius: 0, color: 'white', marginTop: '24px' }}>새로고침</Button>
            </>}

            {setMethodState && <Link style={{ marginTop: '40px', cursor: 'pointer' }} variant='body2' onClick={() => { setMethodState('mobile'); }}>이메일로 인증하지 않고 핸드폰번호로 인증하기</Link>}
        </Box>
	);
}

export default EmailAuthentication;