import React from 'react';
import { Typography } from '@material-ui/core';
import colors from 'appcolors';
import useOrderFormStyles from 'views/order/form/useOrderFormStyles';
import { address_pipe, datetimePipe, mobile_pipe, number_format_pipe } from 'pipes';


const OrderInfo = ({ bucket }) => {
	const classes = useOrderFormStyles();

	return (
		<>
            <div className={classes.section} style={{ paddingBottom: '8px' }}>
                {/* 테스트목적의 버킷은 주문이 없을 수도 있다. */}
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>주문정보</Typography>
                
                <div className={classes.table}>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>주문번호</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{bucket.order?.merchantId}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>주문(결제)일시</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{datetimePipe(bucket.order?.doneAt)}</Typography>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.section}>
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>결제정보</Typography>

                <div className={classes.table}>
                <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>상품가격</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{number_format_pipe(bucket.order?.price)} 원 
                                {bucket.order?.discount && <>
                                    {<span style={{ fontSize: '.9em', marginLeft: '4px', color: colors.textSecondary }}>{`(${bucket.order.discount.rate}% 할인)`}</span>}
                                </>}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>배송비</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{number_format_pipe(bucket.order?.deliveryPrice)} 원</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>카드승인번호</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{bucket.order?.applyNum}</Typography>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.section}>
                <Typography variant="subtitle1" style={{ marginBottom: 0 }}>받는사람정보</Typography>

                <div className={classes.table}>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>이름</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{bucket.destination.name}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>주소</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{address_pipe(bucket.destination)}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>연락처</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{mobile_pipe(bucket.destination.mobile)}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>이메일</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{bucket.destination.email || '-'}</Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <div className={classes.label}>
                            <Typography variant="body1" className={classes.labelText}>배송메모</Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="body1">{bucket.destination.memo || '-'}</Typography>
                        </div>
                    </div>
                </div>
            </div>
		</>
	);
}

export default OrderInfo;