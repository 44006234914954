import React from 'react';
import { Box, Typography, Button, } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { mobile_pipe } from 'pipes';
import colors from 'appcolors';


const AddressItem = ({ address, intend, onSelected }) => {
	const history = useHistory();

    const onModifyClick = () => {
        history.push('/mypage/address-form/' + address.id);
    }

	return (
		<Box border={'1px solid #bdbdbd'} padding={1} borderRadius={'4px'}>
            <Box display={'flex'} alignItems={'center'}>
			    <Typography variant='body1' style={{ marginRight: '8px', fontWeight: 'bold' }}>{address.name}</Typography>{address.isDefault && <Typography variant='body2' style={{ padding: '2px 6px', backgroundColor: colors.secondaryDark, color: 'white', borderRadius: '4px' }}>기본배송지</Typography>}
            </Box>
            <Typography variant='body1'>{`${address.address}, ${address.addressDetail}`}</Typography>
            <Typography variant='body1'>{mobile_pipe(address.mobile)}</Typography>
            
            <Box style={{ marginTop: '8px'}}>
                {intend === 'select' && <Button style={{ marginRight: '6px' }} variant='outlined' size='small' color="primary" onClick={() => { onSelected(address) }}>선택</Button>}
                <Button variant='outlined' size='small' onClick={onModifyClick}>수정</Button>
            </Box>
		</Box>
	);
}

export default AddressItem;