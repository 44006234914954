import React from 'react';

import globals from 'appglobals';
import API from 'api';


const TestPage3 = () => {

    const handle401 = () => {
        API.get('/auth/myinfo')
        .then(() => {})
        .catch(error => {
            // console.log('path2');
        })
    }

    const handle403 = () => {
        API.get('/admin/user/2')
        .then(console.log)
        .catch(error => {
            // console.log('path2');
        })
    }

    const handle404 = () => {
        API.get('/no-page')
        .then(() => {})
        .catch(error => {
            // console.log('path2');
        })
    }

    const handle500 = () => {
    }

    const handleError = () => {
    }

    const handleTimeout = () => {

    }

    const handleYup = () => {
        const body = {
            name: 'jihoon',
        };
        API.post('/test/yup-01', body)
        .then(console.log)
    }

	return (
        <div>
            <button onClick={handle401}>button_401</button>
            <button onClick={handle403}>button_403</button>
            <button onClick={handle404}>button_404</button>
            <button onClick={handle500}>button_500</button>
            <button onClick={handleError}>button_error</button>
            <button onClick={handleTimeout}>button_timeout</button>
            <button onClick={handleYup}>button_yup</button>
        </div>
	);
}

export default TestPage3;