import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Card, CardContent, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import API from 'api';
import colors from 'appcolors';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import Button from 'components/Button';
import TextField from 'components/TextField';
import { bucket_image_pipe, bucket_name_pipe, datetimePipe } from 'pipes';
import { AppContext } from 'contexts/app.context';


const useStyles = makeStyles((theme) => ({

}));

const ClaimFormPage = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { showSnackbar } = useContext(AppContext);

    // params
    const { bucketId } = useParams();
    const { search } = useLocation();
    const division = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('division');
    }, [search]);
    // end

    const [ bucket, setBucket ] = useState();
    useEffect(() => {
        if (bucketId) {
            API.get('/bucket/bucket/' + bucketId)
            .then(setBucket);
        }
    }, [ bucketId ]);

    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues: {
            bucketId: bucket?.id,
            division: division,
            reason: '',
        },
        validationSchema: yup.object().shape({
            bucketId: yup.string().required(),
            division: yup.string().required(),
            reason: yup.string().required().min(2).max(500),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            API.post('/claim/claim-bucket', values)
            .then(() => {
                showSnackbar('요청했습니다. 현황을 내페이지에서 확인가능합니다.');
                history.goBack();
            });
        },
    });

	return (
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>{division === 'return' ? '반품' : '교환요청'}</Typography>
                        <Divider />

                        {bucket ? <form onSubmit={handleSubmit}>
                            {/* 상품 & 별점 */}
                            <Box display={'flex'} alignItems={'center'} borderTop={'1px solid #dddddd'} borderBottom={'1px solid #dddddd'} paddingY={2}>
                                <img src={bucket_image_pipe(bucket)} style={{ height: '120px' }} alt={bucket_name_pipe(bucket)}></img>
                                <Box display={'flex'} ml={2} flexDirection={'column'}>
                                    <Typography variant='h6' style={{ fontWeight: 'bold', marginBottom: '8px' }}>{bucket_name_pipe(bucket)}, <span style={{ fontWeight: 'normal'}}>{bucket.stuff.count}개</span></Typography>
                                    <Typography>구매일시: {datetimePipe(bucket.createdAt)}</Typography>
                                </Box>
                            </Box>

                            {/* 내용 */}
                            <Box display={'flex'} paddingTop={2} borderBottom={'1px solid #bdbdbd'}>
                                <Typography variant='body1'>{division === 'return' ? '반품사유' : '교환사유 및 요청사항'}</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    multiline
                                    rows={4}
                                    placeholder={'500자 이내로 적어주세요.'}
                                    style={{ flex: 1, marginLeft: '16px' }}
                                    onChange={handleChange('reason')}
                                    onBlur={handleBlur('reason')}
                                    helperText={(touched.reason && errors.reason) || ' '}
                                    error={!!(touched.reason && errors.reason)}
                                    value={values.reason}
                                />
                            </Box>
                            
                            <Box display={'flex'} marginTop={2}>
                                <Button type={'submit'} variant='contained' color="primary" style={{ marginRight: '8px', color: 'white' }}>요청하기</Button>
                                <Button type={'button'} onClick={() => { history.goBack(); }} variant='contained'>취소하기</Button>
                            </Box>
                        </form> : <Loading />}
                    </CardContent>
                </Card>
            </Box>
        </div>
	);
}

export default ClaimFormPage;