import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Container, useTheme, useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import colors from 'appcolors';
import Filter from './Filter';
import ItemSearch from './ItemSearch';
import { MemoryContext } from 'contexts/memory.context';


export default function ItemSearchPage () {
    const { commonInfo } = useContext(MemoryContext);

    const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    // query params
    const [ params, setParams ] = useState({ category: 'golf' });
    const { search } = useLocation();
    
    const intend = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('intend');
    }, [search]);
    const keyword = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('keyword');
    }, [search]);
    const gender = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('gender');
    }, [search]);
    useEffect(() => {
		if (!intend && !keyword && !gender) return;

        let result = {};
        if (intend) result['category'] = intend;
        if (keyword) result['keyword'] = keyword;
        if (gender) result['gender'] = gender;
        setParams(result);
	}, [ intend, keyword, gender ]);
    // end: query params
    
    const upperBanner = useMemo(() => {
        if (commonInfo) {
            return commonInfo.columns.find(el => el.code === 'banner');
        }
    }, [ commonInfo ]);

    if (!upperBanner) return null;  // 이미지 시프팅 피하기 위함

	return (
        <div style={{ backgroundColor: colors.background }}>
            <div style={{ textAlign: 'center', backgroundColor: 'white' }}>
                <a href={upperBanner.url || '#'}>
                    {isHandset ? 
                        <img src={upperBanner.mobileImage?.downloadURL} style={{ maxWidth: '100%' }}></img> :
                        <img src={upperBanner.image?.downloadURL} style={{ maxWidth: '100%' }}></img>
                    }

                    {/* <img src={'http://localhost:3000/images/2023_fassion_01.jpeg'} style={{ maxWidth: '100%' }}></img> */}
                </a>
            </div>
            

            <Container>
                <div style={{ padding: '0 0 24px 0', display: 'flex', justifyContent: isHandset ? 'space-between' : 'center' }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: isHandset ? 'flex-start' : 'center' }}>
                        <Typography variant="h3">발이 편한 신발을 경험하세요.</Typography>
                    </div> */}
                </div>
            </Container>

            <div style={{ display: 'flex' }}>
                <Filter params={params} setParams={setParams} />
            </div>
            
            <ItemSearch params={params} />
        </div>
	);
}