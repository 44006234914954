import React, { useState, useEffect, useRef } from 'react';



const TestPage2 = () => {
	
    const [ count, setCount ] = useState(0);
    const savedCallback = useRef();

    const callback = () => {
        setCount(count + 1);
    }

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        // function tick() {
        //     savedCallback.current();
        // }
      
        let id = setInterval(() => {savedCallback.current()}, 1000);
        return () => clearInterval(id);
    }, []);


	return (
        <h1>{count}</h1>
	);
}

export default TestPage2;