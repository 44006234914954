import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Card, CardContent, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import API from 'api';
import colors from 'appcolors';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import Button from 'components/Button';
import TextField from 'components/TextField';
import { bucket_image_pipe, bucket_name_pipe } from 'pipes';
import { Rating } from '@material-ui/lab';
import { AppContext } from 'contexts/app.context';


const ReviewFormPage = () => {
	const history = useHistory();
	const theme = useTheme();
	const isHandset = useMediaQuery(theme.breakpoints.down('xs'));

    const { showSnackbar } = useContext(AppContext);

    // params
    const { bucketId } = useParams();
    const { search } = useLocation();
    const reviewId = useMemo(() => {
        const query = new URLSearchParams(search);
        return query.get('reviewId');
    }, [search]);
    // end

    const [ bucket, setBucket ] = useState();
    useEffect(() => {
        if (bucketId) {
            API.get('/bucket/bucket/' + bucketId)
            .then(setBucket);
        }
    }, [ bucketId ]);

    const [ review, setReview ] = useState();
    useEffect(() => {
        if (reviewId) {
            API.get('/review/review/' + reviewId)
            .then(setReview);
        }
    }, [ reviewId ]);

    const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
        enableReinitialize: true,
        initialValues: {
            category: 'bucket',
            refId: bucket?.id,
            content: review?.content || '',
            stars: review?.stars || 0,
        },
        validationSchema: yup.object().shape({
            category: yup.string().required(),
            refId: yup.string().required(),
            content: yup.string().required('필수입력란입니다.').min(2, "최소 두자 이상입력해주세요.").max(300, "최대 300자까지 입력가능합니다."),
            stars: yup.number().required().min(1).max(5),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (review) {
                values.id = review.id;
                API.put('/review/review', values)
                .then(() => {
                    showSnackbar('리뷰를 수정했습니다.');
                    history.goBack();
                });
            }
            else {
                API.post('/review/review', values)
                .then(() => {
                    showSnackbar('리뷰를 등록했습니다.');
                    history.goBack();
                });
            }
        },
    });

	return (
		<div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isHandset ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" flexDirection="column" paddingX={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>리뷰작성</Typography>
                        <Divider />

                        {bucket ? <form onSubmit={handleSubmit}>
                            {/* 상품 & 별점 */}
                            <Box display={'flex'} alignItems={'center'} borderTop={'1px solid #dddddd'} borderBottom={'1px solid #dddddd'} paddingY={2}>
                                <img src={bucket_image_pipe(bucket)} style={{ height: '120px' }} alt={bucket_name_pipe(bucket)}></img>
                                <Box display={'flex'} ml={2} flexDirection={'column'}>
                                    <Typography variant='h6' style={{ fontWeight: 'bold', marginBottom: '8px' }}>{bucket_name_pipe(bucket)}</Typography>
                                    
                                    {/* 별점 */}
                                    <Rating
                                        name={'stars'}
                                        value={values.stars}
                                        onChange={(event, newValue) => {
                                            setFieldValue('stars', newValue);
                                        }}
                                    />
                                    <Typography style={{ color: 'red' }}>{touched.stars && errors.stars}</Typography>
                                </Box>
                            </Box>

                            {/* 리뷰내용 */}
                            <Box display={'flex'} flexDirection={isHandset ? 'column' : 'row'} paddingTop={2} borderBottom={'1px solid #bdbdbd'}>
                                <Typography variant='body1'>리뷰내용</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    multiline
                                    rows={4}
                                    style={{ flex: 1, marginLeft: isHandset ? 0 : '16px' }}
                                    onChange={handleChange('content')}
                                    onBlur={handleBlur('content')}
                                    helperText={(touched.content && errors.content) || ' '}
                                    error={!!(touched.content && errors.content)}
                                    value={values.content}
                                />
                            </Box>
                            
                            <Box display={'flex'} marginTop={2}>
                                <Button type={'submit'} variant='contained' color="primary" style={{ marginRight: '8px', color: 'white' }}>작성완료</Button>
                                <Button type={'button'} onClick={() => { history.goBack(); }} variant='contained'>취소하기</Button>
                            </Box>
                        </form> : <Loading />}
                    </CardContent>
                </Card>
            </Box>
        </div>
	);
}

export default ReviewFormPage;