import { useContext, useEffect, useState } from "react";
import axios from "axios";
import API from "api";
import { AuthContext } from "contexts/auth.context";


export default function useGetMyCoupons(onlyValid, trigger) {
    const [ rows, setRows ] = useState();
    const [ loading, setLoading ] = useState(false);

    const { me } = useContext(AuthContext);

    useEffect(() => {
        setRows([]);
    }, [ me, onlyValid, trigger ]);

    useEffect(() => {
        if (!me) return;

        let cancel;
        setLoading(true);
        const params = { onlyValid };
        API.get('/coupon/my-coupons', { params, cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(data => {
            setRows(data);
        })
        .catch(e => {
            if (axios.isCancel(e)) {
                console.warn('api canceled');
                return;
            }
        })
        .finally(() => { setLoading(false); });
        
        return () => cancel();
    }, [ me, onlyValid, trigger ]);

    return { loading, rows };
}
