import React, { useContext, useState } from 'react';
import { Box, Typography, makeStyles, useTheme, useMediaQuery, Card, CardContent, MenuItem, Button } from '@material-ui/core';
import { useHistory, Link as RouteLink } from 'react-router-dom';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import colors from 'appcolors';
import Divider from 'components/Divider';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loading from 'components/Loading';
import useApi from 'hooks/useApi';
import TextField from 'components/TextField';


const useStyles = makeStyles((theme) => ({
    form: {
        padding: '8px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    label: {
        color: colors.textSecondary, 
        margin: '16px 0 8px 0',
    },
    input: {
        alignSelf: 'stretch',
        // padding: 0,
    },
    subButton: {
        marginTop: '16px',
        fontSize: '14px',
    },
}));

const TicketWritePage = () => {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { showSnackbar } = useContext(AppContext);


    const [ categories, setCategories ] = useState();
    useApi(() => {
        API.get('/closed/inquiry-categories')
        .then(setCategories);
    }, []);


    const handleSubmit = async (values, { setSubmitting }) => {
        const data = new FormData();
        data.append('category', values.category);
        data.append('content', values.content);
        for (let file of values.files) {
            data.append('files', file);
        }

        await API.post('/ticket/ticket', data);
        showSnackbar('제출했습니다.');
        history.push('/ticket');
    }

    if (!categories) return <Loading />

	return (
        <div style={{ flex: 1, backgroundColor: colors.pageGray, padding: isMobile ? '24px 0' : '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box width={{ xs: '400px', sm: '500px', md: '600px' }} maxWidth={'100%'} display="flex" paddingX={2}>
                <Card style={{ flex: 1 }}>
                    <CardContent>
                        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '8px' }}>1:1 문의 - 작성</Typography>
                        <Divider />

                        <Formik
                            initialValues={{
                                category: '',
                                content: '',
                                files: [], 
                            }}
                            validationSchema={Yup.object({
                                category: Yup.string().required('필수입력입니다.'),
                                content: Yup.string().required('필수입력입니다.'),
                            })}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, dirty, touched, isSubmitting, isValid, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                                <form className={classes.form} style={{ marginTop: theme.spacing(2) }} onSubmit={handleSubmit}>
                                    {/* 화제 */}
                                    <Typography variant="subtitle2" className={classes.label}>화제 *</Typography>
                                    <TextField
                                        select
                                        variant="outlined"
                                        className={classes.input}
                                        required={true}
                                        size="small"
                                        onChange={handleChange('category')}
                                        onBlur={handleBlur('category')}
                                        helperText={(touched.category && errors.category) || ' '}
                                        error={!!(touched.category && errors.category)}
                                        value={values.category}
                                    >
                                        {Object.entries(categories).map(([key, value]) => <MenuItem key={key} value={key}>{value}</MenuItem>)}
                                    </TextField>
        
                                    {/* 문의내용 */}
                                    <Typography variant="subtitle2" className={classes.label}>문의내용 *</Typography>
                                    <TextField
                                        variant="outlined"
                                        className={classes.input}
                                        multiline={true}
                                        rows={4}
                                        required={true}
                                        size="small"
                                        onChange={handleChange('content')}
                                        onBlur={handleBlur('content')}
                                        helperText={(touched.content && errors.content) || ' '}
                                        error={!!(touched.content && errors.content)}
                                        value={values.content}
                                    />

                                    {/* 사진 */}
                                    <Typography variant="subtitle2" className={classes.label}>첨부파일</Typography>
                                    <div style={{ border: '1px solid #dddddd', padding: '4px' }}>
                                        <input type="file" multiple onChange={event => { setFieldValue('files', event.target.files); }} />
                                    </div>

                                    {/* 제출 버튼 */}
                                    <div style={{ marginTop: '24px' }}>
                                        <Button variant="contained" type="submit" color="primary" disableElevation style={{ borderRadius: 0, color: 'white' }} disabled={!dirty || !isValid || isSubmitting}>제출</Button>
                                        <Button variant="contained" component={RouteLink} type="button" disableElevation style={{ borderRadius: 0, marginLeft: '4px' }} to="/ticket">목록</Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </Box>
        </div>
	);
}

export default TicketWritePage;