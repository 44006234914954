import React, { useContext } from 'react';
import { makeStyles, Typography, Link as MuiLink } from "@material-ui/core";
import { MemoryContext } from 'contexts/memory.context';
import { AuthContext } from 'contexts/auth.context';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
	guideView: {
        zIndex: 50,
		position: 'fixed',
		right: 0,
		[theme.breakpoints.down('xs')]: {
			top: 56
		},
		[theme.breakpoints.up('sm')]: {
			top: 64
		},
		backgroundColor: 'black',
		opacity: 0.6,
		padding: 12,
	},
    text: {
        fontSize: 12,
        color: 'white',
        fontWeight: 'bold'
    }
}));

function MainGuide() {
	const classes = useStyles();
	const history = useHistory();

    const { availableCoupons, urlCoupon, guideClose, setGuideClose } = useContext(MemoryContext);
	const { me } = useContext(AuthContext);

	if (guideClose) return null;

	const exceptionUrls = [ 
		'/auth/user-pending',
		'/inquiry-home',
		'/ticket',
		'/ticket/write',
		'/inquiry',
		'/mypage/myinfo',
	];
	if (exceptionUrls.indexOf(history.location.pathname) > -1) return null;

	// URL쿠폰이 있는 경우
	if (urlCoupon) {
		return (
			<div className={classes.guideView}>
				<Typography className={classes.text}>{`주문하실 때 ${urlCoupon.discountRate}% 할인 쿠폰을 사용하실 수 있습니다.`}</Typography>
				<Typography onClick={() => { setGuideClose(true); }} style={{ display: 'inline', cursor: 'pointer', marginTop: '8px', color: 'white', textDecoration: 'underline' }}>닫기</Typography>
			</div>
		)
	}

	// 로그인 안했을 경우
	// if (!me) {
	// 	const exceptions = [ 
	// 		'/auth/signin',
	// 		'/auth/signup',
	// 		'/auth/cafe24-signin',
	// 		'/auth/cafe24-signup',
	// 		'/membership/join',
	// 		'/membership',
	// 	];
	// 	if (exceptions.indexOf(history.location.pathname) === -1) {
	// 		return (
	// 			<div className={classes.guideView}>
	// 				<Typography className={classes.text}>로그인이 되어 있지 않습니다.</Typography>
	// 				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
	// 					<MuiLink onClick={() => { history.push('/auth/signin'); }} component={Typography} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>바로가기</MuiLink>
	// 					<Typography onClick={() => { setGuideClose(true); }} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>닫기</Typography>
	// 				</div>
	// 			</div>
	// 		)
	// 	}
	// }
	
	// 사용가능한 쿠폰이 있는 경우
	if (availableCoupons?.length > 0) {
		return (
			<div className={classes.guideView}>
				<Typography className={classes.text}>주문하실때 할인 쿠폰을 사용하실 수 있습니다.</Typography>
				<Typography onClick={() => { setGuideClose(true); }} style={{ display: 'inline', cursor: 'pointer', marginTop: '8px', color: 'white', textDecoration: 'underline' }}>닫기</Typography>
			</div>
		)
	}

	return null;
}

export default MainGuide;